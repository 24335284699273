import { useState, useEffect } from 'react'
import { RoomStateEvent } from '@breakoutlearning/firebase-repository/models/RoomStateEvent'
import type { StaticModelCollection } from '@breakoutlearning/firebase-repository/types'
import { useRepository } from 'hooks/auth'
import type { RoomStateEventsCubit } from '@breakoutlearning/firebase-repository/cubits/RoomStateEventsCubit'

export const useEvents = ({
  eventIds,
  roomId,
  hideEvents,
  fetchRoomStateEvents,
}: {
  eventIds: string[]
  roomId: string
  hideEvents: boolean
  fetchRoomStateEvents: RoomStateEventsCubit['fetchRoomStateEvents']
}): [boolean, StaticModelCollection<RoomStateEvent>] => {
  const [loading, setLoading] = useState(false)
  const repository = useRepository()
  const [collection, setCollection] = useState(() =>
    RoomStateEvent.emptyCollection(repository)
  )

  useEffect(() => {
    if (eventIds.length === 0 || hideEvents) {
      setCollection(RoomStateEvent.emptyCollection(repository))
      return
    }

    // Flag to track if this specific effect run is still active
    // Will be set to false if the effect re-runs or component unmounts
    let isActive = true
    setLoading(true)

    fetchRoomStateEvents(eventIds)
      .then((events) => {
        // Only update state if this effect run is still the active one
        // Prevents stale data from older fetches overwriting newer data
        if (isActive) {
          const newCollection = RoomStateEvent.emptyCollection(repository)
          newCollection.replaceModels(events)
          setCollection(newCollection)
        }
      })
      .finally(() => {
        if (isActive) {
          setLoading(false)
        }
      })

    // Cleanup runs before:
    // 1. Every re-run of this effect
    // 2. Component unmount
    return () => {
      isActive = false
    }
  }, [repository, eventIds, roomId, hideEvents, fetchRoomStateEvents])

  return [loading, collection]
}

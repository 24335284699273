import { CircleXFillIcon } from 'components/icons/CircleXFill'
import { Trans, useTranslation } from 'react-i18next'
import { useCallback, useMemo, useState } from 'react'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { InlineDialog } from 'components/dialogs/Dialog'
import { BreakoutAsyncButton } from 'components/design-system/BreakoutButton'

export const CancelExperienceConfirmationDialog = ({
  onConfirm,
  open,
}: {
  onConfirm: () => Promise<void>
  open: boolean
}) => {
  const { t } = useTranslation()
  const tScoped = useCallback(
    (key: string) => t(`instructor_assignment.cancel_experience_dialog.${key}`),
    [t]
  )

  const [inputValue, setInputValue] = useState('')
  const textToMatch = useMemo(() => tScoped('cancel_experience'), [tScoped])
  const disableButton = inputValue !== textToMatch

  return (
    <InlineDialog
      size="lg"
      innerClassName="flex items-center justify-center w-full lg:min-w-[800px]"
      open={open}
    >
      <DialogCloseButton />
      <div className="flex max-w-[400px] flex-col items-center justify-center gap-3 text-center">
        <CircleXFillIcon size={50} className="fill-core-error stroke-none" />
        <h2 className="text-headline-large">{tScoped('title')}</h2>
        <p className="text-body-medium">{tScoped('subtitle')}</p>
        <div className="text-body-medium">
          <Trans
            i18nKey="instructor_assignment.cancel_experience_dialog.proceed_prompt" // optional -> fallbacks to defaults if not provided
            values={{ string: textToMatch }}
            components={{ bold: <strong className="font-bold" /> }}
          />
        </div>
        <BreakoutTextInput
          kind="secondary"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="text-none w-full"
          placeholder={tScoped('placeholder')}
        />
        {/* disable if input text does not match textToMatch*/}
        <BreakoutAsyncButton
          size="large"
          kind="error"
          onClick={onConfirm}
          disabled={disableButton}
          fullWidth
        >
          {tScoped('confirm')}
        </BreakoutAsyncButton>
      </div>
    </InlineDialog>
  )
}

import { StudentLibraryCubit } from '@breakoutlearning/firebase-repository/cubits/StudentLibraryCubit'
import { PageTitle } from 'components/PageTitle'
import { Spinner } from 'components/Spinner'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import { observer } from 'mobx-react-lite'
import {
  Actions,
  Contents,
  Header,
  Headline,
  MainPane,
} from 'pages/layout/TwoColumn'
import { useTranslation } from 'react-i18next'
import { AssignmentTable, PriorityActionHeader } from './components'
import { ShowCompletedButton } from 'components/ShowCompletedButton'
import { LibraryObjectList } from './LibraryObjectList'
import { useBreakoutUser } from 'hooks/profile'

export const StudentLibraryPage = observer(function StudentLibraryPage() {
  const repository = useRepository()
  const flags = repository.featureFlags
  if (!flags.isLoaded) return null
  const useNewVersion = flags.data.studentLibraryUseNew

  if (useNewVersion) {
    return <StudentLibraryPageNew />
  } else {
    return <StudentLibraryPageOld />
  }
})

const StudentLibraryPageNew = observer(function StudentLibraryPage() {
  const { t } = useTranslation()
  const repository = useRepository()
  const user = useBreakoutUser()
  const cubit = useCubitBuilder(() => {
    return new StudentLibraryCubit(repository)
  }, [repository])

  const isReady = cubit.isLoaded && user.purchasesLoaded

  return (
    <MainPane testId="student-library">
      <PageTitle title={t('library.page_title')} />
      <Header>
        <Headline>{t('library.headline')}</Headline>
      </Header>
      <Contents className="flex h-full flex-col overflow-y-hidden">
        {isReady && cubit.libraryObjectForHeader && (
          <PriorityActionHeader
            libraryObject={cubit.libraryObjectForHeader.libraryObject}
            actionState={cubit.libraryObjectForHeader.actionState}
          />
        )}
        {isReady && <AssignmentTable cubit={cubit} />}
        {!isReady && (
          <div className="flex h-full flex-row items-center justify-center">
            <Spinner />
          </div>
        )}
      </Contents>
    </MainPane>
  )
})

const LibraryActions = observer(function LibraryActions({
  cubit,
}: {
  cubit: StudentLibraryCubit
}) {
  return (
    <Actions>
      <ShowCompletedButton
        showCompleted={cubit.showCompleted}
        onClick={() => cubit.toggleShowCompleted()}
      />
    </Actions>
  )
})

function StudentLibraryPageOld() {
  const { t } = useTranslation()
  const repository = useRepository()
  const cubit = useCubitBuilder(() => {
    return new StudentLibraryCubit(repository)
  }, [repository])

  return (
    <MainPane testId="student-library">
      <PageTitle title={t('library.page_title')} />
      <Header>
        <Headline>{t('library.headline')}</Headline>
        <LibraryActions cubit={cubit} />
      </Header>
      <Contents className="h-full md:overflow-auto">
        <LibraryObjectList cubit={cubit} />
      </Contents>
    </MainPane>
  )
}

export const GroupIcon = ({
  color = 'currentColor',
  size = 24,
  strokeWidth = 2,
  ...otherProps
}: {
  size?: number | string
} & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 19 17"
      fill="none"
      stroke={color}
      strokeWidth={strokeWidth}
      {...otherProps}
    >
      <path d="M16.1641 1H2.83073C1.91025 1 1.16406 1.74619 1.16406 2.66667V14.3333C1.16406 15.2538 1.91025 16 2.83073 16H16.1641C17.0845 16 17.8307 15.2538 17.8307 14.3333V2.66667C17.8307 1.74619 17.0845 1 16.1641 1Z" />
      <path d="M7 1V16" />
    </svg>
  )
}

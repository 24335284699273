import type { InstructorSlideDeckCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSlideDeckCubit'
import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { AssignmentType } from '@breakoutlearning/firebase-repository/types'
import { zodResolver } from '@hookform/resolvers/zod'
import { Spinner } from 'components/Spinner'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutDateTimeInput } from 'components/design-system/BreakoutDateTimeInput'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { FormLabel } from 'components/design-system/form/FormLabel'
import { Dialog, InlineDialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { PlusIcon } from 'components/icons/Plus'
import { Save } from 'components/icons/Save'
import { useDialogs } from 'hooks/dialogs'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { dateSchema } from 'util/schema-date'
import { z } from 'zod'
import { CreateOrEditSectionDialog } from '../classes/CreateOrEditSectionDialog'
import { InfoIcon } from 'components/icons/Info'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { useRepository } from 'hooks/auth'
import { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import { calculateGroupingDate } from './calculateGroupingDate'

const getSchema = (
  t: ReturnType<typeof useTranslation>['t'],
  minExpiration: DateTime
) =>
  z
    .object({
      assignmentType: z
        .preprocess((val) => {
          return Number(val)
        }, z.nativeEnum(AssignmentType))
        .default(AssignmentType.studentLed),
      sectionId: z.string({ message: t('instructor_library.section_missing') }),
      catalogId: z.string(),
      configureLater: z.boolean().default(false),
      assignedAt: dateSchema({
        //required: t('instructor_assignment.start_date_required'),
      }),
      expiresAt: dateSchema({
        //required: t('instructor_library.deadline_required'),
        min: {
          date: minExpiration,
          message: t('instructor_library.deadline_must_be_future'),
        },
      }),
      groupingType: z.preprocess((val) => {
        return Number(val)
      }, z.nativeEnum(AssignmentGroupingType)),
      groupingSize: z.coerce
        .number()
        .min(2, { message: t('instructor_library.grouping_size_invalid') })
        .max(RoomState.maxAllowedUsers, {
          message: t('instructor_library.grouping_size_invalid'),
        })
        .optional(),
    })
    .superRefine((data, ctx) => {
      if (data.configureLater) return
      if (!data.assignedAt) {
        ctx.addIssue({
          path: ['assignedAt'],
          message: t('instructor_assignment.start_date_required'),
          code: z.ZodIssueCode.custom,
        })
      }
      if (!data.expiresAt) {
        ctx.addIssue({
          path: ['expiresAt'],
          message: t('instructor_library.deadline_required'),
          code: z.ZodIssueCode.custom,
        })
      }
    })

type FormValues = Omit<z.infer<ReturnType<typeof getSchema>>, 'expiresAt'> & {
  expiresAt: DateTime | null
}

export const AssignClassDialog = observer(function AssignClassDialog({
  cubit,
  onCreate,
}: {
  cubit: InstructorSlideDeckCubit
  onCreate?: () => void
}) {
  const store = useRootStore()
  const user = useBreakoutUser()
  const { showDialog } = useDialogs()

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const minScheduleDate = useMemo(() => {
    const now = DateTime.now()

    // set min date to start of tomorrow
    const min = now.plus({ days: 1 }).startOf('day')

    return min
  }, [])

  const { t } = useTranslation()
  const rootStore = useRootStore()

  const queryParams = store.router.queryParams

  // if section id is present in the query params, only show that section
  const sectionIdParam = useMemo(
    () => queryParams?.sectionId?.toString(),
    [queryParams]
  )

  const schema = useMemo(
    () => getSchema(t, minScheduleDate),
    [minScheduleDate, t]
  )

  const { allowDraftAssignments } = useRepository().featureFlags.data

  const {
    control,
    setValue,
    trigger,
    formState: { isSubmitting },
    setError,
    clearErrors,
    handleSubmit,
    getValues,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      configureLater: false,
      catalogId: queryParams?.catalogId?.toString() || undefined,
      assignmentType: AssignmentType.studentLed,
      groupingType: AssignmentGroupingType.manual,
      sectionId: sectionIdParam,
      assignedAt: DateTime.now().set({ millisecond: 0, second: 0 }),
      groupingSize: 4,
    },
  })

  const configureLater = useWatch({ control, name: 'configureLater' })
  const groupingType = useWatch({ control, name: 'groupingType' })
  const expiresAt = useWatch({ control, name: 'expiresAt' })
  const assignedAt = useWatch({ control, name: 'assignedAt' })

  const validate = async () => {
    const data = getValues()
    const assignedAtPlusOneDay = data.assignedAt?.plus({ days: 1 })

    // If configureLater is true, skip validation for assignedAt and expiresAt
    if (!data.configureLater) {
      if (!data.expiresAt) {
        setError('expiresAt', {
          message: t('instructor_library.deadline_required'),
        })
        return false
      }

      if (assignedAtPlusOneDay && data.expiresAt < assignedAtPlusOneDay) {
        setError('expiresAt', {
          message: t(
            'instructor_assignment.deadline_must_be_one_day_after_start_date'
          ),
        })
        return false
      }
    }

    const isValid = await trigger()
    return isValid
  }

  const onSubmit = useCallback(
    async (data: FormValues) => {
      const assignmentId = !data.configureLater
        ? await cubit.createSectionAssignment({
            slideDeckId: cubit.slideDeckId,
            ...data,
            expiresAt: data.expiresAt!, // We have already validated that this is not null
          })
        : await cubit.createDraftSectionAssignment({
            slideDeckId: cubit.slideDeckId,
            ...data,
            expiresAt: data.expiresAt || undefined,
            assignedAt: data.assignedAt || undefined,
          })

      if (assignmentId) {
        store.navigateTo('instructorClassAssignment', {
          id: data.sectionId,
          assignmentId: assignmentId,
        })
      }
      onCreate?.()
    },
    [cubit, onCreate, store]
  )

  return (
    <Dialog
      size="md"
      testId="assign-class-dialog"
      innerClassName="flex flex-col"
    >
      <DialogCloseButton />
      <div className="text-headline-large mb-6">
        {t('instructor_library.experience_settings')}
      </div>
      <form
        className="flex flex-grow flex-col space-y-3"
        onSubmit={(e) => e.preventDefault()}
      >
        {cubit.sections.isLoading && <Spinner className="mx-auto" />}
        {cubit.sections.isLoaded && (
          <Controller
            control={control}
            name="sectionId"
            render={({ field, fieldState }) => (
              <BreakoutSelect
                {...field}
                testId="section-select"
                error={fieldState.error}
                required
                kind="secondary"
                label={t('instructor_library.select_class')}
                labelComponent={
                  <div className="flex w-full flex-row items-center justify-between">
                    <FormLabel
                      label={t('instructor_library.select_class')}
                      name={field.name}
                      required={true}
                    />
                    {!user.isTA && (
                      <label className="text-body-medium w-full px-4 text-on-surface-var">
                        <button
                          className="text-body-medium  w-full whitespace-nowrap text-on-surface-var"
                          data-testid="add-new-section-button"
                          onClick={() => {
                            showDialog(({ remove }) => {
                              return (
                                <CreateOrEditSectionDialog
                                  createSectionInvoiceRequest={
                                    cubit.createSectionInvoiceRequest
                                  }
                                  saveSection={cubit.createSection}
                                  onSave={({ sectionId }) => {
                                    if (!sectionId) return

                                    rootStore.updateQueryParams({ sectionId })

                                    remove()

                                    setValue('sectionId', sectionId)
                                  }}
                                  impersonationInfo={
                                    rootStore.impersonationInfo
                                  }
                                  redeemPromotions={cubit.redeemPromotions}
                                  allowSectionPassPaymentMethod={
                                    cubit.allowSectionPassPaymentMethod
                                  }
                                />
                              )
                            })
                          }}
                        >
                          <div className="flex items-center justify-end">
                            <PlusIcon
                              size={14}
                              className="mr-0.5 stroke-fixed-accent-color"
                              pathClassName="stroke-fixed-accent-color"
                              pathStrokeWidth={3.5}
                            />
                            {t('instructor_library.add_new_class')}
                          </div>
                        </button>
                      </label>
                    )}
                  </div>
                }
                name="sectionId"
                placeholder={
                  !cubit.sections.length
                    ? t('instructor_library.no_classes_found')
                    : t('instructor_library.select_class')
                }
                options={cubit.sortedSectionOptions}
              />
            )}
          />
        )}
        {user?.isCorre && (
          // if user is corre show the assignmentType select
          <Controller
            control={control}
            name="assignmentType"
            render={({ field, fieldState }) => (
              <BreakoutSelect
                {...field}
                error={fieldState.error}
                required
                kind="secondary"
                label={t('instructor_library.assignment_type')}
                name="assignmentType"
                options={[
                  {
                    value: AssignmentType.studentLed,
                    label: t('instructor_library.assignment_type_student_led'),
                  },
                  {
                    value: AssignmentType.instructorLed,
                    label: t(
                      'instructor_library.assignment_type_instructor_led'
                    ),
                  },
                ]}
              />
            )}
          />
        )}
        {allowDraftAssignments && (
          <Controller
            control={control}
            name="configureLater"
            render={({ field }) => (
              <BreakoutSelect
                {...field}
                labelComponent={
                  <BreakoutTooltip content="If you choose to configure the assignment will be saved as a draft, and will not be visible to students until configurations is finished and assignment is published ">
                    <div className="items center flex flex-row">
                      <FormLabel
                        label={'Configure Later?'}
                        name={'configureLater'}
                        required
                      />

                      <InfoIcon size={14} className="text-on-surface-var" />
                    </div>
                  </BreakoutTooltip>
                }
                onChange={(v) => {
                  clearErrors()
                  field.onChange(v)
                }}
                name="configureLater"
                kind="secondary"
                options={[
                  {
                    value: true,
                    label: 'Yes (Save as Draft)',
                  },
                  {
                    value: false,
                    label: 'No (Publish Assignment)',
                  },
                ]}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="assignedAt"
          render={({ field, fieldState }) => (
            <BreakoutDateTimeInput
              {...field}
              error={fieldState.error}
              type="datetime-local"
              label={t('instructor_assignment.start_date')}
              required={!configureLater}
              kind="secondary"
              id="assignment-start-time"
              hideNowButton
              onChange={(newAssignedAt) => {
                field.onChange(newAssignedAt)

                // If the deadline is now before the start date, clear out the deadline.
                if (newAssignedAt && expiresAt && newAssignedAt > expiresAt) {
                  setValue('expiresAt', null)
                }
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="expiresAt"
          render={({ field, fieldState }) => {
            return (
              <BreakoutDateTimeInput
                {...field}
                error={fieldState.error}
                type="datetime-local"
                label={t('instructor_assignment.assignment_deadline')}
                required={!configureLater}
                kind="secondary"
                id="meeting-time"
                hideNowButton
                min={assignedAt?.plus({ days: 1 })}
                max={assignedAt?.plus({ days: 365 })}
                // Set initial view to one day after assignedAt
                initialView={assignedAt?.plus({ days: 1 })}
                value={field.value}
                onChange={field.onChange}
              />
            )
          }}
        />
        <Controller
          control={control}
          name="groupingType"
          render={({ field }) => (
            <BreakoutSelect
              {...field}
              label={t('instructor_library.student_grouping')}
              name="groupingType"
              kind="secondary"
              onChange={(value) => {
                field.onChange(value)
                if (value === AssignmentGroupingType.manual) {
                  setValue('groupingSize', undefined)
                } else {
                  setValue('groupingSize', 4)
                }
              }}
              options={[
                {
                  value: AssignmentGroupingType.manual,
                  label: t('instructor_library.students_self_grouping'),
                },
                {
                  value: AssignmentGroupingType.automaticRandom,
                  label: t('instructor_library.automatic_randomized_grouping'),
                },
              ]}
            />
          )}
        />

        {groupingType === AssignmentGroupingType.automaticRandom && (
          <Controller
            control={control}
            name="groupingSize"
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                {...field}
                kind="secondary"
                error={fieldState.error}
                type="number"
                label={t('instructor_library.desired_group_size')}
                name="groupingSize"
                min="2"
                max={RoomState.maxAllowedUsers}
              />
            )}
          />
        )}

        <div className="mt-4 flex flex-grow flex-col justify-end">
          <BreakoutButton
            size="large"
            type="button"
            className="mt-2"
            onClick={async (e) => {
              e.preventDefault()

              const isValid = await validate()
              if (!isValid) return
              if (configureLater) await handleSubmit(onSubmit)()
              setShowConfirmDialog(true)
            }}
            data-testid="assign-class-button"
            icon={<Save size="18" />}
            fullWidth
            loading={isSubmitting}
          >
            {t('instructor_library.assign_to_class')}
          </BreakoutButton>
          <ConfirmDialog
            showConfirmDialog={showConfirmDialog}
            setShowConfirmDialog={setShowConfirmDialog}
            groupingType={groupingType}
            isSaving={isSubmitting}
            expiresAt={expiresAt?.toString()}
            assignedAt={assignedAt?.toString()}
            onSubmit={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </Dialog>
  )
})

function ConfirmDialog({
  showConfirmDialog,
  setShowConfirmDialog,
  groupingType,
  isSaving,
  expiresAt,
  assignedAt,
  onSubmit,
}: {
  showConfirmDialog: boolean
  groupingType: AssignmentGroupingType
  isSaving: boolean
  expiresAt?: string
  assignedAt?: string
  setShowConfirmDialog: (show: boolean) => void
  onSubmit: () => Promise<void>
}) {
  const { t } = useTranslation()

  const groupingDate = calculateGroupingDate(expiresAt, assignedAt)

  return (
    <InlineDialog
      open={showConfirmDialog}
      onDismiss={() => setShowConfirmDialog(false)}
      size="xs"
      className="!bg-core-tertiary"
      testId="assign-class-confirm-dialog"
    >
      <DialogCloseButton
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setShowConfirmDialog(false)
          return false
        }}
      />
      {groupingType === AssignmentGroupingType.automaticRandom ? (
        <div className="mb-10 mt-5" tabIndex={0} ref={(node) => node?.focus()}>
          <div className="text-headline-large mb-5">
            {t('instructor_library.automatic_grouping')}
          </div>
          <div className="text-body-medium mb-5">
            {t('instructor_library.confirm_auto_grouping1')}
            <br />
            {groupingDate ? (
              <strong>{groupingDate.toFormat('MMM dd, yyyy (hh:mm a)')}</strong>
            ) : null}
          </div>
          <div className="text-body-medium mb-5">
            {t('instructor_library.confirm_auto_grouping2')}
          </div>
          <div>
            <BreakoutButton
              size="large"
              fullWidth
              onClick={onSubmit}
              loading={isSaving}
              data-testid="assign-class-confirm-auto"
            >
              {t('instructor.confirm')}
            </BreakoutButton>
          </div>
        </div>
      ) : (
        <div className="mb-10 mt-5" tabIndex={0} ref={(node) => node?.focus()}>
          <div className="text-headline-large mb-5">
            {t('instructor_library.students_self_grouping')}
          </div>
          <div className="text-body-medium mb-5">
            {t('instructor_library.confirm_self_grouping1')}
          </div>
          <div className="text-body-medium mb-5">
            {t('instructor_library.confirm_self_grouping2')}
          </div>
          <div>
            <BreakoutButton
              size="large"
              fullWidth
              loading={isSaving}
              data-testid="assign-class-confirm"
              onClick={onSubmit}
            >
              {t('instructor.confirm')}
            </BreakoutButton>
          </div>
        </div>
      )}
    </InlineDialog>
  )
}

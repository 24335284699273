import classNames from 'classnames'
import { Spinner } from 'components/Spinner'
import { useMeetingResultsCubit } from 'hooks/cubits/meetingsResults'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import type {
  RubricType,
  SlideRubric,
} from '@breakoutlearning/firebase-repository/models/SlideRubric'
import { SparklesIcon } from 'components/icons/Sparkles'
import { ThreeLineHorizontalIcon } from 'components/icons/ThreeLineHorizontal'
import { getRubricBgColorByScore, getRubricScoreLabel } from 'util/rubric'
import { useMemo, useState } from 'react'
import { useWaitFor } from './use-wait-for'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { ChevronRight } from 'components/icons/ChevronRight'
import { RubricResultMoreDetailsDialog } from './RubricResultMoreDetailsDialog'
import { createPortal } from 'react-dom'
import { useDialogs } from 'hooks/dialogs'
import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
import { JustificationDetails } from '../../../../components/RubricResults/JustificationDetails'
import { useFilteredRubricResultDetails } from 'components/RubricResults/useFilteredRubricResultDetails'
import { Person } from 'components/icons/Person'

export const IndividualEvaluation = observer(function IndividualEvaluation() {
  const { t } = useTranslation()
  const waitedFor3Min = useWaitFor(3)
  const cubit = useMeetingResultsCubit()

  // show loading message
  if (cubit.rubrics.isLoading || cubit.rubricResultsForCurrentUser.isEmpty)
    return (
      <EmptyOrLoading
        state={cubit.aiStatus === 'pending' ? 'loading' : 'empty'}
        showLongLoadingMessage={waitedFor3Min}
      />
    )

  // if we have data show it, regardless of ai status
  return (
    <>
      <h2 className="text-title-large">{t('meeting.individual_evaluation')}</h2>
      <div className="text-body-medium">
        {t('meeting.individual_evaluation_description')}
      </div>
      <div className="mt-4 space-y-4">
        {cubit.rubricResultsForCurrentUser.models.map((rubricResult) => {
          const rubric = cubit.rubrics.models.find(
            (r) => r.id === rubricResult.id
          )

          if (!rubric) {
            return null
          }

          return (
            <RubricResult
              key={rubricResult.id}
              rubric={rubric}
              rubricResult={rubricResult}
            />
          )
        })}
      </div>
    </>
  )
})

const EmptyOrLoading = observer(function EmptyOrLoading({
  state,
  showLongLoadingMessage,
}: {
  state: 'loading' | 'empty'
  showLongLoadingMessage: boolean
}) {
  const { t } = useTranslation()
  const Icon =
    state === 'loading' ? (
      <Spinner size={1.75} />
    ) : (
      <Person className="stroke-fixed-accent-color" size={28} />
    )
  const text =
    state === 'loading'
      ? showLongLoadingMessage
        ? t('meeting.results.results_loading_long')
        : t('meeting.results.results_loading')
      : t('meeting.results.results_empty')

  return (
    <div
      data-testid={`meeting-results-individual-evaluation-${state}`}
      className="flex h-full w-full flex-col items-center justify-center gap-1 text-center"
    >
      {Icon}
      <h2 className="text-title-large">{t('meeting.individual_evaluation')}</h2>
      <strong className="text-body-medium">{text}</strong>
    </div>
  )
})

const RubricResult = observer(function RubricResult({
  rubric,
  rubricResult,
}: {
  rubric: SlideRubric
  rubricResult: RoomStateRubricResult
}) {
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  const cubit = useMeetingResultsCubit()

  const [rubricDataForInlineDialog, setRubricDataForInlineDialog] = useState<{
    rubricResults: RoomStateRubricResult
    rubric: SlideRubric
  }>()

  const details = useMemo(
    () => cubit.rubricResultDetails.models.map((m) => m.dataToJs),
    [cubit.rubricResultDetails.models]
  )

  const rubricResultDetails = useFilteredRubricResultDetails(
    details,
    rubricResult.id,
    cubit.userId
  )

  if (!rubric) {
    return null
  }

  return (
    <>
      <div
        key={rubricResult.id}
        data-testid={`individual-rubric-result-${rubricResult.id}`}
        tabIndex={0}
      >
        <div className="items-center justify-between gap-2 rounded-lg bg-surface px-5 py-4 xl:flex">
          <div>
            <h3 className="text-title-medium text-on-surface">
              {rubric.data.rubric}
            </h3>
            <div className="text-body-small text-on-surface">
              {rubric.data.rubricDescription}
            </div>
          </div>
          <div className="mt-3 flex shrink-0 xl:mt-0">
            <RubricScoreDisplay
              rubricType={rubric.rubricType!}
              score={rubricResult.data.score}
            />
            <BreakoutButton
              className="ml-3 flex items-center justify-center !bg-core-tertiary"
              kind="secondary"
              onClick={() => {
                cubit.logEvent('rubric_result_more_details_clicked', {
                  rubric_id: rubric.id,
                  rubric_score: rubricResult.data.score,
                  rubric_type: rubric.rubricType,
                })
                if (cubit.inDialog) {
                  setRubricDataForInlineDialog({
                    rubricResults: rubricResult,
                    rubric,
                  })
                  return
                }

                showDialog(() => (
                  <RubricResultMoreDetailsDialog
                    cubit={cubit}
                    rubric={rubric}
                    rubricResult={rubricResult}
                    onSubmit={async ({ feedback }) => {
                      await cubit.submitFeedback(rubric.id, feedback)
                      cubit.logEvent('rubric_result_feedback_submitted', {
                        rubric_id: rubric.id,
                        rubric_score: rubricResult.data.score,
                        rubric_type: rubric.rubricType,
                        feedback: feedback,
                      })
                    }}
                    inline={false}
                  />
                ))
              }}
            >
              {t('meeting.more_details')}
              <ChevronRight size={15} className="ml-1" />
            </BreakoutButton>
          </div>
        </div>
        <div>
          <div>
            {rubricResult.data.justification && (
              <Justification
                justification={rubricResult.justificationForRubricType(
                  rubric.data.rubricType,
                  t
                )}
              />
            )}

            {rubricResultDetails.length > 0 && (
              <div className="mt-4 flex">
                <ThreeLineHorizontalIcon
                  size={20}
                  className="mr-1 flex-shrink-0 stroke-fixed-grey"
                />

                <div>
                  <h3 className="text-title-small text-on-surface">
                    {t('meeting.evaluation_points')}
                  </h3>
                  <JustificationDetails
                    roomId={cubit.roomStateId}
                    rubricResultDetails={rubricResultDetails}
                    rubric={rubric}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {rubricDataForInlineDialog && (
        <Portal>
          <RubricResultMoreDetailsDialog
            cubit={cubit}
            rubric={rubricDataForInlineDialog.rubric}
            rubricResult={rubricDataForInlineDialog.rubricResults}
            onSubmit={async () => {}}
            inline={true}
            closeDialog={() => setRubricDataForInlineDialog(undefined)}
          />
        </Portal>
      )}
    </>
  )
})

export const Justification = observer(function Justification({
  justification,
}: {
  justification: string
}) {
  const { t } = useTranslation()

  return (
    <div className="mt-4 flex">
      <SparklesIcon
        size={20}
        className="mr-1 flex-shrink-0 stroke-fixed-accent-color"
      />
      <div>
        <h3 className="text-title-small text-on-surface">
          {t('meeting.evaluation_summary')}
        </h3>
        <div className="text-body-medium text-body-medium mt-1 text-on-surface">
          {justification}
        </div>
      </div>
    </div>
  )
})

export const RubricScoreDisplay = observer(function RubricScoreDisplay({
  score,
  rubricType,
  className,
}: {
  rubricType: RubricType
  score: number
  className?: string
}) {
  const { t } = useTranslation()
  const scoreLabel = getRubricScoreLabel(t, rubricType, score)
  return (
    <div
      className={classNames(
        'text-label-small flex shrink-0 items-center justify-center rounded-xl bg-opacity-30 px-3',
        getRubricBgColorByScore({
          type: rubricType,
          score: score,
        }),
        className
      )}
    >
      {scoreLabel}
    </div>
  )
})

const Portal = ({ children }: { children: React.ReactNode }) => {
  return createPortal(children, document.body)
}

import { NavLink } from './NavLink'
import { Home } from '../../components/icons/Home'
import { PeopleMultiple } from '../../components/icons/PeopleMultiple'
import { PeopleGroup } from '../../components/icons/PeopleGroup'
import { Dashboard } from '../../components/icons/Dashboard'
import { BookOpen } from '../../components/icons/BookOpen'
import { GridIcon } from '../../components/icons/Grid'
import { SignOut } from '../../components/icons/SignOut'
import { useRootStore } from 'hooks/rootStore'
import { useTranslation } from 'react-i18next'
import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import { ThreeLineHorizontalIcon } from '../../components/icons/ThreeLineHorizontal'
import { KeyCapIcon } from '../../components/icons/KeyCap'
import { BreakoutDrawer } from '../../components/design-system/BreakoutDrawer'
import { BookClose } from 'components/icons/BookClose'
import { Environment, environment } from 'config/environment'
import { Forum } from 'components/icons/Forum'
import { useRepository } from 'hooks/auth'
import { BankIcon } from 'components/icons/BankIcon'
import { useDialogs } from 'hooks/dialogs'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { GraduationCapIcon } from 'components/icons/GraduationCap'
import { useCubitBuilder } from 'hooks/cubits'
import { NavigationCubit } from '@breakoutlearning/firebase-repository/cubits/NavigationCubit'
import {
  NavigationCubitProvider,
  useNavigationCubit,
} from 'hooks/cubits/navigation'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { GroupIcon } from 'components/icons/Group'
import { FolderIcon } from 'components/icons/Folder'
import { GearIcon } from 'components/icons/Gear'
import { QuestionIcon } from 'components/icons/Question'
import { ChatDots } from 'components/icons/ChatDots'
import { Shield } from 'components/icons/Shield'
import { LightBulbIcon } from 'components/icons/LightBulb'
import { useSettings } from 'hooks/settings'
import { AnimatePresence, easeOut, m } from 'framer-motion'
import { Search } from 'components/icons/Search'

const AdminNavigation = observer(function AdminNavigation() {
  const { t } = useTranslation()
  const user = useBreakoutUser()

  return (
    <>
      <NavLink
        label={t('navigation.admin_library')}
        to="adminLibrary"
        data-testid="adminLibrary"
        showWhen={user.isAuthor}
        Icon={BookOpen}
      />
      <NavLink
        label={t('navigation.admin_catalogs')}
        to="adminCatalogs"
        data-testid="adminCatalogs"
        showWhen={user.isEditor || user.isCorre}
        Icon={FolderIcon}
      />
      <NavLink
        label={t('navigation.admin_users')}
        to="adminUsers"
        data-testid="adminUsers"
        showWhen={user.isCorre}
        Icon={PeopleGroup}
      />
      <NavLink
        label={t('navigation.admin_dashboard')}
        to="adminDashboard"
        data-testid="adminDashboard"
        showWhen={user.isCorre}
        Icon={Dashboard}
      />
      {user.hasAccessToRoomInspector && (
        <NavLink
          label={t('navigation.admin_inspector')}
          to="adminInspector"
          data-testid="adminInspector"
          showWhen={user.isCorre}
          Icon={Search}
        />
      )}
      <NavLink
        label={t('navigation.admin_demos')}
        to="adminDemos"
        data-testid="adminDemos"
        showWhen={user.isCorre}
        Icon={Forum}
      />
      <hr className="my-2 bg-border-grey" />
      <NavLink
        label={t('navigation.instructor_classes')}
        to="instructorClasses"
        activeOn="instructorClass, instructorClasses, instructorClassAssignment"
        data-testid="instructorClasses"
        Icon={GraduationCapIcon}
      />
      <NavLink
        label={t('navigation.instructor_library')}
        to="instructorLibrary"
        activeOn="instructorLibrary, instructorSlideDeck"
        data-testid="instructorLibrary"
        Icon={GridIcon}
      />
      <NavLink
        label={t('navigation.instructor_assistants')}
        to="instructorAssistants"
        data-testid="instructorAssistants"
        Icon={PeopleMultiple}
      />
      <NavLink
        label={t('navigation.student_view')}
        to="home"
        activeOn="home, assignment, assignmentWithRoom"
        data-testid="home"
        Icon={BookClose}
      />
    </>
  )
})

function FacultyNavigation() {
  const { t } = useTranslation()
  const user = useBreakoutUser()

  return (
    <>
      <NavLink
        label={t('navigation.home')}
        to="home"
        activeOn="home, instructorClass, instructorClasses, instructorClassAssignment"
        data-testid="home"
        Icon={Home}
      />
      <NavLink
        label={t('navigation.instructor_library')}
        to="instructorLibrary"
        activeOn="instructorLibrary, instructorSlideDeck"
        data-testid="instructorLibrary"
        Icon={GridIcon}
      />
      <NavLink
        label={t('navigation.student_view')}
        to="instructorStudentView"
        activeOn="instructorStudentView, assignment, assignmentWithRoom"
        data-testid="instructorStudentView"
        Icon={BookClose}
      />
      <NavLink
        label={t('navigation.instructor_assistants')}
        to="instructorAssistants"
        showWhen={user.isInstructor}
        activeOn="instructorAssistants"
        data-testid="instructorAssistants"
        Icon={PeopleMultiple}
      />
    </>
  )
}

function StudentNavigation() {
  const { t } = useTranslation()

  return (
    <>
      <NavLink
        label={t('navigation.home')}
        to="home"
        activeOn="home, assignment , assignmentWithRoom"
        Icon={Home}
      />
    </>
  )
}

function MobileNavigationDrawer() {
  const { t } = useTranslation()
  const { helpHovered } = useNavigationCubit()

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="my-5 text-center">
        <img
          src="/assets/images/logo.png"
          tabIndex={0}
          width={48}
          className="inline"
          alt={t('breakout_learning')}
        />
      </div>
      <div className="h-full w-full overflow-y-auto text-left text-sm">
        {helpHovered && <HelpMenu />}
        {!helpHovered && (
          <>
            <NavigationLinksForRole />
            <hr />
            <NavigationLinksStatic />
          </>
        )}
      </div>
    </div>
  )
}

const MobileTopNavigation = observer(function MobileTopNavigation() {
  const { t } = useTranslation()
  const { animationsEnabled } = useSettings()

  const cubit = useCubitBuilder(
    () => new NavigationCubit({ isMobile: true, animationsEnabled }),
    [animationsEnabled],
    {
      preventInitialize: true,
    }
  )

  return (
    <NavigationCubitProvider value={cubit}>
      <div
        className={`
      flex
      flex-row
      items-center
      justify-between
      border-b
      border-light-grey-text
      py-3
      md:hidden
  `}
      >
        <div className="w-[25%] flex-none text-left">
          <span className="mx-5"> </span>
          {/* empty for now, necessary for logo to be in the middle */}
        </div>
        <div className="mx-5 flex-1 text-center">
          <img
            src="/assets/images/logo.png"
            width={48}
            className="inline"
            alt={t('breakout_learning')}
            tabIndex={0}
          />
        </div>
        <div className="w-[25%] flex-none text-right">
          <button
            aria-hidden
            aria-label="Open Navigation Drawer"
            onClick={() => cubit.open()}
          >
            <ThreeLineHorizontalIcon className="mx-5 inline" />
          </button>
          <BreakoutDrawer
            open={cubit.isOpen}
            onClose={() => cubit.close()}
            className=""
            placement="right"
          >
            {cubit.mobileDrawerMounted && <MobileNavigationDrawer />}
          </BreakoutDrawer>
        </div>
      </div>
    </NavigationCubitProvider>
  )
})

const BrowserNavigation = observer(function Navigation() {
  const { animationsEnabled } = useSettings()

  const cubit = useCubitBuilder(
    () => new NavigationCubit({ isMobile: false, animationsEnabled }),
    [animationsEnabled],
    {
      preventInitialize: true,
    }
  )

  const [isHovered, setIsHovered] = useState(false)

  const closedOrOpenKey = cubit.isClosed ? 'closed' : 'open'

  const fadeInOut = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: {
      duration: animationsEnabled ? cubit.animationDuration / 1000 : 0,
      ease: easeOut,
    },
  }

  return (
    <NavigationCubitProvider value={cubit}>
      <m.div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="navigation"
        className="relative box-border hidden bg-surface md:mb-0 md:flex md:h-full md:w-[4%] md:flex-shrink-0 md:flex-grow-0"
        style={{
          minWidth: cubit.isClosed ? '75px' : '190px',
        }}
        animate={{
          minWidth: cubit.isClosed ? '75px' : '190px',
        }}
        transition={{
          duration: animationsEnabled ? cubit.animationDuration / 1000 : 0,
        }}
      >
        <AnimatePresence>
          <m.div
            key={closedOrOpenKey}
            {...fadeInOut}
            className={classNames(
              'absolute bottom-0 left-0 right-0 top-0 flex h-full w-full flex-col items-center justify-start gap-9 py-9 text-center',
              { 'pl-1 pr-3': cubit.isOpen, 'px-[15.5px]': cubit.isClosed }
            )}
          >
            <BrowserNavigationTop isHovered={isHovered} />
            <div
              key={`navigation_links_for_role_${closedOrOpenKey}`}
              className="isolate w-full gap-1 md:ml-0 md:flex md:h-full md:flex-col md:overflow-y-auto"
            >
              <NavigationLinksForRole />
            </div>
            <div
              key={`navigation_links_static_${closedOrOpenKey}`}
              className="flex w-full flex-grow flex-row justify-end gap-1 md:flex-col"
            >
              <hr className="mb-2 bg-border-grey" />
              <NavigationLinksStatic />
            </div>
          </m.div>
        </AnimatePresence>
      </m.div>
    </NavigationCubitProvider>
  )
})

const HelpMenu = observer(function HelpMenu() {
  const { t } = useTranslation()
  const [isHovered, setIsHovered] = useState(false)
  const cubit = useNavigationCubit()
  const { isMobile, helpHovered } = cubit
  const { animationsEnabled } = useSettings()
  const { isInstructor } = useBreakoutUser()

  if (!isHovered && !helpHovered) return null

  return (
    <div
      onMouseEnter={!isMobile ? () => setIsHovered(true) : undefined}
      onMouseLeave={!isMobile ? () => setIsHovered(false) : undefined}
      className={classNames({
        'absolute left-full top-1/2 z-10 -translate-y-1/2 pl-5': !isMobile,
        'animate-fade-in': animationsEnabled && !isMobile,
        'animate-toast-enter': animationsEnabled && isMobile,
      })}
    >
      <div
        className={classNames('rounded-xl', {
          'max-w-[175px] border border-outline-variant bg-surface p-2':
            !isMobile,
          'pl-4': isMobile,
        })}
      >
        <NavLink
          alwaysShowLabel
          href={
            !isMobile
              ? 'https://www.breakoutlearning.com/contact-us'
              : undefined
          }
          onClick={
            isMobile
              ? () => {
                  window.open(
                    'https://www.breakoutlearning.com/contact-us',
                    '_blank'
                  )
                }
              : undefined
          }
          target="_blank"
          to={null}
          label={t('navigation.help_menu.message_support')}
          Icon={ChatDots}
        />
        <NavLink
          alwaysShowLabel
          href={
            !isMobile
              ? 'https://support.breakoutlearning.com/help-center/'
              : undefined
          }
          onClick={
            isMobile
              ? () => {
                  window.open(
                    'https://support.breakoutlearning.com/help-center/',
                    '_blank'
                  )
                }
              : undefined
          }
          target="_blank"
          to={null}
          label={t('navigation.help_menu.help_center')}
          Icon={BookOpen}
        />
        <NavLink
          alwaysShowLabel
          href={
            !isMobile
              ? 'https://www.breakoutlearning.com/legal/privacy-policy'
              : undefined
          }
          onClick={
            isMobile
              ? () => {
                  window.open(
                    'https://www.breakoutlearning.com/legal/privacy-policy',
                    '_blank'
                  )
                }
              : undefined
          }
          target="_blank"
          to={null}
          label={t('navigation.help_menu.privacy_policy')}
          Icon={Shield}
        />
        <NavLink
          alwaysShowLabel
          to={'onboarding'}
          label={t('navigation.help_menu.see_how_it_works')}
          Icon={LightBulbIcon}
          showWhen={!isInstructor}
        />
      </div>
    </div>
  )
})

const NavigationLinksForRole = observer(function NavigationLinksForRole() {
  const breakoutUser = useBreakoutUser()
  const { showOrganizationsUI } = useRepository().featureFlags.data
  const { t } = useTranslation()

  return (
    <>
      {breakoutUser.isInternal && <AdminNavigation />}
      {breakoutUser.isFaculty && <FacultyNavigation />}
      {breakoutUser.isStudent && <StudentNavigation />}
      <NavLink
        label={t('navigation.organizations')}
        to="organizations"
        data-testid="organizations"
        showWhen={
          showOrganizationsUI &&
          (breakoutUser.isCorre || breakoutUser.organizationAdmins.length > 0)
        }
        Icon={BankIcon}
      />
    </>
  )
})

const NavigationLinksStatic = observer(function NavigationLinksStatic() {
  const { isAdmin } = useBreakoutUser()
  const { t } = useTranslation()
  const { showDialog } = useDialogs()
  const rootStore = useRootStore()
  const cubit = useNavigationCubit()

  return (
    <>
      {environment() !== Environment.production && isAdmin && (
        <NavLink
          label={t('internal.design_system')}
          to="designSystem"
          Icon={KeyCapIcon}
        />
      )}
      <div
        onMouseEnter={
          !cubit.isMobile ? () => cubit.setHelpHovered(true) : undefined
        }
        onMouseLeave={
          !cubit.isMobile ? () => cubit.setHelpHovered(false) : undefined
        }
        className={classNames('relative flex [&>*]:grow', {
          'flex-col': cubit.isMobile,
        })}
      >
        <NavLink
          to={null}
          label={t('navigation.help')}
          Icon={QuestionIcon}
          onClick={
            cubit.isMobile
              ? (e) => {
                  e.stopPropagation()
                  cubit.setHelpHovered(!cubit.helpHovered)
                }
              : undefined
          }
        />
        <HelpMenu />
      </div>
      <NavLink to="profile" label={t('navigation.profile')} Icon={GearIcon} />
      <NavLink
        label={t('navigation.logout')}
        to="logout"
        data-testid="logOut"
        Icon={SignOut}
        navigateOverride={() =>
          showDialog(() => (
            <ConfirmationDialog
              text={t('navigation.logout_confirmation')}
              subtitle={t('navigation.logout_confirmation_subtitle')}
              buttonText={t('navigation.logout_confirmation_button')}
              onConfirm={async () => {
                rootStore.navigateTo('logout')
              }}
            />
          ))
        }
      />
    </>
  )
})

const BrowserNavigationTop = observer(function BrowserNavigationTop({
  isHovered,
}: {
  isHovered: boolean
}) {
  const { animationsEnabled } = useSettings()
  const cubit = useNavigationCubit()
  const {
    isOpen,
    isMobile,
    isOpenAnimationInProgress,
    isCloseAnimationInProgress,
  } = cubit
  const { t } = useTranslation()

  // if isOpen changes in desktop view with animations enabled
  // do NOT re-render the component w/ or w/o label
  // if this updates during the component lifecycle it is on the way out
  // and a new one will render in its place
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isOpenOnMount = useMemo(() => isOpen, [])
  const isOpenToUse = animationsEnabled && !isMobile ? isOpenOnMount : isOpen

  return (
    <div
      className={classNames(
        'h-[51px] min-h-[51px] w-full text-center md:ml-0',
        {
          'flex flex-row justify-between pl-1.5': isOpenToUse,
          inline: !isOpenToUse,
        }
      )}
    >
      {!isOpenToUse && (
        <img
          src="/assets/images/logo.png"
          width={44}
          // if we are in process of opening, don't show the logo
          // as they must have triggered hover state to toggle the button
          // this prevents odd visual artifacts when hover toggles on/off during animation
          className={classNames({
            hidden:
              (isHovered || isOpenAnimationInProgress) &&
              !isCloseAnimationInProgress,
          })}
          data-testid="logo"
          tabIndex={0}
          alt={t('breakout_learning_logo')}
        />
      )}
      {isOpenToUse && (
        <img
          src="/assets/images/logo_large.png"
          width={100}
          data-testid="logo"
          tabIndex={0}
          alt={t('breakout_learning_logo')}
          className="object-contain"
        />
      )}
      <div
        className={classNames(
          'flex min-h-[50px] w-full items-center justify-center [&>*]:grow',
          {
            invisible:
              (!isHovered && !isOpenToUse) || isCloseAnimationInProgress,
            'max-w-[30px]': isOpenToUse,
          }
        )}
      >
        <NavLink
          to={null}
          label=""
          data-testid="open-close-browser-navigation"
          Icon={GroupIcon}
          navigateOverride={() => cubit.toggleCollapsed()}
          iconStrokeWidth={1.25}
          disableHoverState={isOpenToUse}
        />
      </div>
    </div>
  )
})

export function Navigation() {
  return (
    <>
      <MobileTopNavigation />
      <BrowserNavigation />
    </>
  )
}

import type { InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
import { type SectionPassCoupon } from '@breakoutlearning/firebase-repository/models/SectionPassCoupon'
import {
  BreakoutAsyncButton,
  BreakoutButton,
} from 'components/design-system/BreakoutButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { DownloadIcon } from 'components/icons/Download'
import { Spinner } from 'components/Spinner'
import { observer } from 'mobx-react-lite'
import { useMemo, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { downloadSectionPassCouponsAsCSV } from 'util/csv'
import { generateCouponCodes } from 'util/generateCouponCodes'

export const SectionPassCouponsDialog = observer(
  function SectionPassCouponsDialog({
    cubit,
  }: {
    cubit: InstructorSectionCubit
  }) {
    const { isLoading, models: coupons } = cubit.sectionPassCoupons
    const ref = useRef<HTMLInputElement>(null)

    const [formError, setFormError] = useState<string>()

    const [consumedCoupons, unconsumedCoupons, existingCouponCodes] =
      useMemo(() => {
        const consumed: SectionPassCoupon[] = []
        const unconsumed: SectionPassCoupon[] = []
        const existingCouponCodes = coupons.map((c) => {
          if (c.consumed) consumed.push(c)
          else unconsumed.push(c)
          return c.id
        })
        return [consumed, unconsumed, existingCouponCodes]
      }, [coupons])

    return (
      <Dialog size="sm" innerClassName="flex flex-col gap-6 h-full">
        <DialogCloseButton />
        <h2 className="text-headline-large text-center">
          Section Pass Coupons
        </h2>
        {isLoading && (
          <div className="flex h-full w-full flex-grow items-center justify-center">
            <Spinner />
          </div>
        )}
        {!isLoading && (
          <>
            <div className="flex flex-grow flex-col justify-center gap-2">
              <BreakoutTextInput
                kind="secondary"
                ref={ref}
                label="# of coupons to generate"
                min={1}
                max={500}
                defaultValue={1}
                type="number"
                error={
                  formError ? { message: formError, type: 'custom' } : undefined
                }
              />
              <BreakoutAsyncButton
                size="large"
                fullWidth
                onClick={async () => {
                  try {
                    if (!ref.current) throw 'unknown issue'
                    const numberToGenerate = Number.parseInt(ref.current.value)
                    if (isNaN(numberToGenerate))
                      return setFormError('number must be valid integer')
                    if (numberToGenerate === 0) return
                    if (numberToGenerate < 0)
                      return setFormError('number must be greater than 0')
                    if (numberToGenerate > 500)
                      return setFormError('number must be no greater than 500')
                    const couponCodes = generateCouponCodes({
                      count: numberToGenerate,
                      existingCodes: new Set(existingCouponCodes),
                    })
                    setFormError(undefined)
                    await cubit.generateCoupons(Array.from(couponCodes))
                    toast.success('Coupons Generated')
                  } catch (e) {
                    toast.error(
                      `Error generating coupons: ${e instanceof Error ? e.message : e}`
                    )
                  }
                }}
              >
                Generate Coupons
              </BreakoutAsyncButton>
            </div>
            <div className="flex flex-row gap-2">
              <BreakoutButton
                size="small"
                kind="secondary"
                icon={<DownloadIcon size={16} />}
                disabled={!unconsumedCoupons.length}
                onClick={() => {
                  downloadSectionPassCouponsAsCSV({
                    sectionName: cubit.section.data.sectionName,
                    className: cubit.section.data.className,
                    consumed: false,
                    sectionPassCoupons: unconsumedCoupons,
                  })
                }}
              >
                Active Coupons {`(${unconsumedCoupons.length})`}
              </BreakoutButton>
              <BreakoutButton
                size="small"
                kind="secondary"
                icon={<DownloadIcon size={16} />}
                disabled={!consumedCoupons.length}
                onClick={() => {
                  downloadSectionPassCouponsAsCSV({
                    sectionName: cubit.section.data.sectionName,
                    className: cubit.section.data.className,
                    consumed: true,
                    sectionPassCoupons: consumedCoupons,
                  })
                }}
              >
                Consumed Coupons {`(${consumedCoupons.length})`}
              </BreakoutButton>
            </div>
          </>
        )}
      </Dialog>
    )
  }
)

import type { SuperAdminPrivilege } from '@breakoutlearning/firebase-repository/types'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import type { ReactNode } from 'react'
import { useEffect } from 'react'

export const SuperAdminRoleGuard = observer(function SuperAdminRoleGuard({
  privilege,
  children,
}: {
  privilege: SuperAdminPrivilege
  children: ReactNode
}) {
  const { navigateTo } = useRootStore()
  const user = useBreakoutUser()
  const isSuperAdmin = user.isSuperAdmin
  const isAllowed = isSuperAdmin && user.superAdmin.hasPrivilege(privilege)
  const isLoaded = user.superAdmin.isLoaded

  useEffect(() => {
    if (isLoaded && !isAllowed) {
      // The user is not authorized to view this page, redirect them to the home page.
      navigateTo('home')
    }
  }, [navigateTo, isAllowed, isLoaded])

  if (!isSuperAdmin) return null
  if (!isAllowed) return null

  return children
})

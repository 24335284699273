import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const ConfigurationComplete = observer(function ConfigurationComplete() {
  const { t } = useTranslation()
  const assignmentCubit = useInstructorAssignmentCubit()
  const slideDeck = assignmentCubit.slideDeck
  const rootStore = useRootStore()

  const onClick = useCallback(() => {
    // in Canvas mode, we will redirect to the canvas redirect url
    // TODO

    // in regular mode, we will redirect to the assignment page
    rootStore.navigateToInstructorAssignmentPage(
      assignmentCubit.sectionId,
      assignmentCubit.assignmentId,
      { tab: 'materials' }
    )
  }, [rootStore, assignmentCubit])

  return (
    <div className="assignment flex min-h-screen w-full flex-col items-start justify-center">
      <div className="ml-[5vw] max-w-[400px]">
        <div className="mb-5">
          <h2 className="text-headline-small">
            {t('lti.configuration.assignment_ready')}
          </h2>
          <h1 className="text-headline-large">
            {t('lti.configuration.your_assignment_is_ready')}
          </h1>
          <div className="text-body-large">
            {t('lti.configuration.assignment_ready_description')}
          </div>
        </div>
        <div className="mb-3 flex flex-row items-center gap-3 rounded-2xl bg-core-secondary px-2 py-3">
          <div className="pl-3">
            <img
              className="max-h-5 max-w-5"
              src={slideDeck.data.slideDeckImageURL}
              alt={slideDeck.data.slideDeckName}
            />
          </div>
          <div>
            <div className="text-body-medium text-on-surface-var">
              {slideDeck.data.slideDeckName}
            </div>
            <div className="text-label-large">
              {slideDeck.data.slideDeckTeaser}
            </div>
          </div>
        </div>
        <div>
          <BreakoutButton
            kind="accent"
            type="submit"
            size="medium"
            className="bg-fixed-accent-color"
            onClick={onClick}
          >
            {t('lti.configuration.go_to_assignment')}
          </BreakoutButton>
        </div>
      </div>
    </div>
  )
})

import { LibraryObjectState } from '@breakoutlearning/firebase-repository/types'
import { useSettings } from 'hooks/settings'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { CalendarIcon } from './icons/Calendar'
import { XSmallIcon } from './icons/XSmall'
import type { TFunction } from 'i18next'
import type { ValidLibraryObject } from '@breakoutlearning/firebase-repository/stores/ValidLibraryObject'
import { DoubleCheckIcon } from './icons/DoubleCheck'

function getLibraryObjectBadgeText(
  libraryObject: ValidLibraryObject,
  t: TFunction<'translation', undefined>
) {
  switch (libraryObject.libraryObjectState) {
    case LibraryObjectState.completed:
      return t('student_assignment.library_state_completed')
    case LibraryObjectState.expired:
      return t('student_assignment.library_state_expired')
    case LibraryObjectState.invited:
      return t('student_assignment.library_state_invited')
    case LibraryObjectState.live:
      return t('student_assignment.library_state_live')
    case LibraryObjectState.scheduled:
      return t('student_assignment.library_state_scheduled')
    case LibraryObjectState.mustSchedule:
      return t('student_assignment.library_state_schedule_now')
    case LibraryObjectState.enrolled:
    case LibraryObjectState.pending:
      return t('student_assignment.library_state_pending')
    case LibraryObjectState.readyToStart:
      return t('student_assignment.library_state_ready_to_start')
    case LibraryObjectState.suspended:
      return t('student_assignment.library_state_suspended')
    case LibraryObjectState.canceled:
      return t('student_assignment.library_state_canceled')
    default:
      return ''
  }
}

function getLibraryObjectBadgeIcon(
  libraryObject: ValidLibraryObject,
  animationsEnabled: boolean
) {
  const animation = animationsEnabled ? 'pulse-animation' : ''
  switch (libraryObject.libraryObjectState) {
    case LibraryObjectState.readyToStart:
    case LibraryObjectState.live:
      return (
        <span
          className={`${animation} mr-1 inline-block h-[8px] w-[8px] rounded-full bg-breakout-green`}
        ></span>
      )
    case LibraryObjectState.enrolled:
    case LibraryObjectState.pending:
    case LibraryObjectState.mustSchedule:
      return (
        <span className="mr-1 inline-block h-[8px] w-[8px] rounded-full bg-breakout-orange"></span>
      )
    case LibraryObjectState.invited:
      return (
        <span className="mr-1 inline-block h-[8px] w-[8px] rounded-full bg-dark-grey"></span>
      )
    case LibraryObjectState.scheduled:
      return <CalendarIcon size={14} className="mr-1.5 inline-block" />
    case LibraryObjectState.canceled:
      return (
        <span className="mr-1 inline-block h-[8px] w-[8px] rounded-full bg-core-error"></span>
      )

    case LibraryObjectState.expired:
      return (
        <XSmallIcon
          size={14}
          className="mr-1.5 inline-block text-breakout-red"
        />
      )
    case LibraryObjectState.completed:
      return (
        <DoubleCheckIcon
          size={16}
          className="mr-1.5 inline-block text-breakout-dark-green"
        />
      )
    case LibraryObjectState.suspended:
      return (
        <span
          className={`mr-1 inline-block h-[8px] w-[8px] rounded-full bg-fixed-accent-color`}
        ></span>
      )

    default:
      return (
        <span className="mr-1 inline-block h-[8px] w-[8px] rounded-full bg-breakout-green"></span>
      )
  }
}

export const LibraryObjectBadge = observer(function LibraryObjectBadge({
  libraryObject,
  className = 'py-3 px-8 rounded-xl inline-block text-label-large bg-core-secondary whitespace-nowrap',
}: {
  libraryObject: ValidLibraryObject
  className?: string
}) {
  const { t } = useTranslation()
  const { animationsEnabled } = useSettings()
  return (
    <span className={className}>
      {getLibraryObjectBadgeIcon(libraryObject, animationsEnabled)}
      {getLibraryObjectBadgeText(libraryObject, t)}
    </span>
  )
})

import type { FirestoreRoomStateRubricResultDetail } from '@breakoutlearning/firebase-repository/types'
import { useMemo } from 'react'

export const useFilteredRubricResultDetails = (
  details: FirestoreRoomStateRubricResultDetail[],
  rubricId: string,
  userId: string
) => {
  return useMemo(() => {
    return details
      .filter((r) => r.rubricId === rubricId)
      .filter((r) => r.userId === userId)
      .sort((a, b) => {
        const timeA = a instanceof Date ? a.eventTime.getTime() : 0
        const timeB = b.eventTime instanceof Date ? b.eventTime.getTime() : 0
        return timeA - timeB
      })
  }, [details, rubricId, userId])
}

import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import classNames from 'classnames'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { observer } from 'mobx-react-lite'
import type { SyntheticEvent } from 'react'
import { useCallback, useMemo, useState } from 'react'

export const BreakoutUserAvatar = observer(function BreakoutUserAvatar({
  radius,
  user,
  'aria-hidden': ariaHidden = false,
  radiusPercentageBase = 'height',
  className,
}: {
  radius: number | string
  radiusPercentageBase?: 'width' | 'height'
  user: PublicUser
  'aria-hidden'?: boolean
  className?: string
}) {
  const [tooltipInitialized, setTooltipInitialized] = useState(false)
  let content: React.ReactNode | null = null
  const innerRadiusValue = useMemo(() => {
    // when percentage is passed, we want the inner element to take up the full space
    if (typeof radius === 'string') return '100%'
    return radius * 2
  }, [radius])

  const initializeTooltip = useCallback(() => setTooltipInitialized(true), [])
  const onImageError = useCallback(
    (e: SyntheticEvent<HTMLImageElement, Event>) => {
      e.currentTarget.classList.add('hidden')
    },
    []
  )

  const radiusStyle = useMemo(() => {
    // When string is passed, we're assuming it's a percentage.
    if (typeof radius === 'string') {
      return {
        height: radiusPercentageBase === 'height' ? `${radius}` : undefined,
        width: radiusPercentageBase === 'width' ? `${radius}` : undefined,
        aspectRatio: '1 / 1',
      }
    }
    return {
      width: radius * 2,
      height: radius * 2,
    }
  }, [radius, radiusPercentageBase])
  const userColorStyle = useMemo(() => {
    return {
      backgroundColor: user.color,
    }
  }, [user])

  const stylesMerged = useMemo(() => {
    return { ...radiusStyle, ...userColorStyle }
  }, [radiusStyle, userColorStyle])

  if (user.hasData) {
    if (user.data.imageUrl) {
      content = (
        <div
          aria-hidden={ariaHidden}
          className={classNames(
            'relative overflow-hidden rounded-full border-1 border-on-surface-disabled @container',
            className
          )}
          onMouseEnter={initializeTooltip}
          title={user.data.firstName + ' ' + user.data.lastName}
          style={stylesMerged}
        >
          <strong
            className="absolute inset-0 flex w-full items-center justify-center font-bold text-core-on-primary"
            style={{
              lineHeight: '100cqi',
              fontSize: '40cqi',
            }}
          >
            {user.initials}
          </strong>
          <img
            src={user.data.imageUrl!}
            alt={user.data.firstName + ' ' + user.data.lastName}
            width={innerRadiusValue}
            height={innerRadiusValue}
            className="absolute inset-0 rounded-full object-cover"
            style={userColorStyle}
            onError={onImageError}
          />
        </div>
      )
    } else {
      content = (
        <div
          className={classNames(
            'relative rounded-full border-1 border-on-surface-disabled',
            className
          )}
          onMouseEnter={initializeTooltip}
          title={user.data.firstName + ' ' + user.data.lastName}
          style={stylesMerged}
        >
          <strong className="text-label-medium absolute inset-0 flex w-full items-center justify-center font-bold text-core-on-primary">
            {user.initials}
          </strong>
        </div>
      )
    }
  } else {
    content = (
      <div
        className="relative rounded-full border-1 border-on-surface-disabled"
        style={radiusStyle}
        onMouseEnter={initializeTooltip}
      >
        <strong className="text-label-medium absolute inset-0 flex w-full items-center justify-center font-bold text-core-on-primary">
          ...
        </strong>
      </div>
    )
  }

  if (tooltipInitialized) {
    return <BreakoutTooltip content={user.fullName}>{content}</BreakoutTooltip>
  }
  return content
})

type AvatarStackProps = React.HTMLAttributes<HTMLDivElement> & {
  radius: number
  users: PublicUser[]
  spacing?: number
}

export function BreakoutUserAvatarStack({
  radius,
  users,
  spacing = -4,
  className,
  ...otherProps
}: AvatarStackProps) {
  const style = useMemo(() => {
    return {
      height: radius * 2,
    }
  }, [radius])
  return (
    <div
      className={classNames('flex flex-row justify-center', className, {
        'space-x-8': spacing === 8,
        'space-x-7': spacing === 7,
        'space-x-6': spacing === 6,
        'space-x-5': spacing === 5,
        'space-x-4': spacing === 4,
        'space-x-3': spacing === 3,
        'space-x-2': spacing === 2,
        'space-x-1': spacing === 1,
        '-space-x-0': spacing === 0,
        '-space-x-1': spacing === -1,
        '-space-x-2': spacing === -2,
        '-space-x-3': spacing === -3,
        '-space-x-4': spacing === -4,
        '-space-x-5': spacing === -5,
        '-space-x-6': spacing === -6,
        '-space-x-7': spacing === -7,
        '-space-x-8': spacing === -8,
      })}
      style={style}
      {...otherProps}
    >
      {users.map((user) => (
        <BreakoutUserAvatar key={user.id} radius={radius} user={user} />
      ))}
    </div>
  )
}

export const generateCouponCodes = ({
  count,
  codeLength = 12,
  maxCollisionsBeforeError = 1000,
  existingCodes,
}: {
  count: number
  existingCodes: Set<string>
  codeLength?: number
  maxCollisionsBeforeError?: number
}) => {
  const codes = new Set<string>()
  let retries = 0
  while (codes.size < count) {
    if (retries >= maxCollisionsBeforeError)
      throw new Error(
        `Failed to generate coupon codes. ${codes.size}/${count} generated before max collision threshold (${maxCollisionsBeforeError})`
      )

    const code = Array.from(
      { length: codeLength },
      () =>
        Math.random() < 26 / 36 // equal chance of each character
          ? String.fromCharCode(65 + Math.floor(Math.random() * 26)) // Uppercase letters
          : String.fromCharCode(48 + Math.floor(Math.random() * 10)) // Numbers
    ).join('')

    if (codes.has(code) || existingCodes.has(code)) {
      retries++
      continue
    }

    // reset collisions and continue
    retries = 0
    codes.add(code)
  }
  return codes
}

import { InstructorSlideDeckCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSlideDeckCubit'
import {
  BreakoutButton,
  BreakoutButtonLink,
} from 'components/design-system/BreakoutButton'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { PlusIcon } from 'components/icons/Plus'
import { ImpersonationInfo } from 'components/ImpersonationInfo'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import {
  MainPane,
  Header,
  Headline,
  Actions,
  Contents,
} from 'pages/layout/TwoColumn'
import { useTranslation, Trans } from 'react-i18next'
import { AssignClassDialog } from './AssignClassDialog'
import { observer } from 'mobx-react-lite'
import {
  InstructorSlideDeckCubitProvider,
  useInstructorSlideDeckCubit,
} from 'hooks/cubits/instructorSlideDeck'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { LinkIcon } from 'components/icons/Link'
import { GoogleStorageImage } from 'components/GoogleStorageImage'
import {
  SlideDeckMaterialWorkType,
  SlideDeckReferenceType,
} from '@breakoutlearning/firebase-repository/types'
import { CourseMaterialButton } from 'components/slide-deck-material-components/CourseMaterialListItem'
import { ShowExperienceDialog } from 'pages/student/assignment/dialogs/ShowExperienceDialog'
import { Spinner } from 'components/Spinner'
import { RevampedSlideDeckTrailerThumbnail } from '../shared/RevampedSlideDeckCard'
import { useBreakoutUser } from 'hooks/profile'
import { useCanvasMode } from 'hooks/use-canvas-mode'
import { CreateOrEditSectionDialog } from '../classes/CreateOrEditSectionDialog'
import { EyeOpen } from 'components/icons/EyeOpen'
import type { SlideQuestion } from '@breakoutlearning/firebase-repository/models/SlideQuestion'
import { ClockIcon } from 'components/icons/Clock'
import { Money } from 'components/icons/Money'
import { Dialog } from 'components/dialogs/Dialog'

export const InstructorSlideDeckPage = observer(
  function InstructorSlideDeckPage({ slideDeckId }: { slideDeckId: string }) {
    const { t } = useTranslation()
    const rootStore = useRootStore()
    const user = useBreakoutUser()
    const instructorUserId = rootStore.impersonationInfo?.userId
    const repository = useRepository()
    const paramCatalogId = rootStore.router.queryParams?.catalogId
    const catalogId =
      typeof paramCatalogId === 'string' ? paramCatalogId : undefined

    const cubit = useCubitBuilder(
      () =>
        new InstructorSlideDeckCubit(repository, slideDeckId, {
          instructorUserId,
          catalogId,
        }),
      [repository, slideDeckId, catalogId]
    )
    const { showDialog } = useDialogs()
    const queryParams = rootStore.router.queryParams
    const { canvasMode } = useCanvasMode()

    return (
      <MainPane>
        <Header>
          <Headline
            className="text-display-medium font-display min-w-[35%] cursor-pointer truncate"
            aria-label={[
              cubit.slideDeck.data.slideDeckName,
              t('instructor_library.back_to_library_page'),
            ].join(', ')}
            onClick={() => {
              if (
                typeof queryParams?.teachingPlanId === 'string' &&
                typeof queryParams?.catalogId === 'string'
              ) {
                rootStore.navigateToTeachingPlanPage(
                  queryParams?.catalogId,
                  queryParams?.teachingPlanId,
                  {
                    ...queryParams,
                    sectionId: queryParams?.sectionId,
                  }
                )
                return
              }
              rootStore.navigateTo('instructorLibrary', undefined, {
                ...queryParams,
                sectionId: queryParams?.sectionId,
                catalogId: queryParams?.catalogId,
              })
            }}
          >
            <div className="flex flex-row items-center">
              <div className="pr-1">
                <ChevronLeft size={24} />
              </div>
              {cubit.slideDeck.data.slideDeckName}
              <ImpersonationInfo />
            </div>
          </Headline>
          <Actions className="!gap-5">
            {canvasMode && (
              <BreakoutButton
                data-testid="add-to-class-button"
                kind="primary"
                size="large"
                icon={<PlusIcon size={14} />}
                onClick={() => {
                  if (typeof queryParams?.redirectTo === 'string') {
                    const slideDeckId = cubit.slideDeckId
                    const slideDeckName = cubit.slideDeck.data.slideDeckName
                    // replace the placeholders in the redirect URL
                    let replacedUrl = queryParams.redirectTo
                      .replace('SLIDE_DECK_ID', slideDeckId)
                      .replace(
                        'SLIDE_DECK_NAME',
                        encodeURIComponent(slideDeckName)
                      )
                    if (catalogId) {
                      replacedUrl = replacedUrl.replace('CATALOG_ID', catalogId)
                    }
                    showDialog(() => (
                      <CanvasRedirectDialog href={replacedUrl} />
                    ))
                  }
                }}
              >
                {t('instructor_library.add_to_canvas')}
              </BreakoutButton>
            )}
            <BreakoutButton
              size="large"
              kind="secondary"
              icon={<EyeOpen size={14} />}
              onClick={() =>
                showDialog(() => (
                  <ShowExperienceDialog slideDeckId={slideDeckId} />
                ))
              }
            >
              {t('instructor_library.preview_experience')}
            </BreakoutButton>
            {/*
                only show the assign class dialog if catalogId is set and the deck is not archived
                also, wait for sections to load before showing the dialog so that we know which dialog
                to show
             */}
            {!canvasMode &&
              !!queryParams?.catalogId &&
              !cubit.slideDeck.isArchived &&
              cubit.sections.isLoaded && (
                <BreakoutButton
                  data-testid="add-to-class-button"
                  kind="primary"
                  size="large"
                  icon={<PlusIcon size={14} />}
                  onClick={() => {
                    const showAssignClassDialog = () => {
                      showDialog(({ remove }) => (
                        <AssignClassDialog cubit={cubit} onCreate={remove} />
                      ))
                    }
                    // For TA's, we never show add to class dialog
                    if (user.isTA) {
                      showAssignClassDialog()
                      return
                    }
                    if (cubit.sections.models.length === 0) {
                      return showDialog(({ remove }) => (
                        <CreateOrEditSectionDialog
                          createSectionInvoiceRequest={
                            cubit.createSectionInvoiceRequest
                          }
                          saveSection={cubit.createSection}
                          onSave={({ sectionId }) => {
                            if (!sectionId) return

                            rootStore.updateQueryParams({ sectionId })

                            remove()
                            showAssignClassDialog()
                          }}
                          impersonationInfo={rootStore.impersonationInfo}
                          redeemPromotions={cubit.redeemPromotions}
                          allowSectionPassPaymentMethod={
                            cubit.allowSectionPassPaymentMethod
                          }
                        />
                      ))
                    }

                    showAssignClassDialog()
                  }}
                >
                  {t('instructor_library.add_to_class')}
                </BreakoutButton>
              )}
          </Actions>
        </Header>

        <Contents className="h-full md:overflow-hidden">
          <InstructorSlideDeckCubitProvider value={cubit}>
            <SlideDeckContent />
          </InstructorSlideDeckCubitProvider>
        </Contents>
      </MainPane>
    )
  }
)

export const SlideDeckContent = observer(function SlideDeckContent({
  showPreviewBtn = false,
}: {
  showPreviewBtn?: boolean
}) {
  const cubit = useInstructorSlideDeckCubit()
  const { t } = useTranslation()
  const { showDialog } = useDialogs()

  const materials = cubit.materials.models
  const slideDeck = cubit.slideDeck

  const instructorMaterials = useMemo(() => {
    return materials.filter(
      (material) =>
        material.data.viewableByInstructor && !material.data.viewableByStudent
    )
  }, [materials])

  const preWorkMaterials = useMemo(() => {
    return materials.filter(
      (material) =>
        material.data.viewableByStudent &&
        material.data.materialWorkType === SlideDeckMaterialWorkType.preWork
    )
  }, [materials])

  const otherMaterials = useMemo(() => {
    return materials.filter(
      (material) =>
        material.data.viewableByStudent &&
        material.data.materialWorkType !== SlideDeckMaterialWorkType.preWork
    )
  }, [materials])

  const preQuizQuestions = useMemo(() => {
    return cubit.questionsSorted.filter(
      (question) => question.data.slideId === 'pre-meeting-quiz'
    )
  }, [cubit.questionsSorted])

  if (
    !cubit.materials.isLoaded ||
    !cubit.slideDeck.isLoaded ||
    !cubit.authors.isLoaded
  ) {
    return (
      <div className="mt-20 flex w-full justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="h-full overflow-hidden">
      <div className={classNames('h-full overflow-y-auto pr-1')}>
        <div>
          <RevampedSlideDeckTrailerThumbnail
            slideDeckMaterials={materials}
            squareThumbnail={false}
            hideEmptyThumbnail={true}
          />
        </div>
        <div tabIndex={0}>
          <div className="mt-3 flex items-center justify-between">
            <div className="flex items-center">
              {slideDeck.data.slideDeckImageURL && (
                <img
                  src={slideDeck.data.slideDeckImageURL}
                  className="max-h-[65px] max-w-[65px] p-1"
                  aria-hidden
                  alt={slideDeck.data.slideDeckName}
                />
              )}
              <div className="ml-2">
                <h1 className="text-headline-large text-on-surface">
                  {slideDeck.data.slideDeckName}
                </h1>
                <p className="text-label-large text-on-surface-var">
                  {slideDeck.data.slideDeckTeaser}
                </p>
              </div>
            </div>
            <div className="mr-2 flex items-center gap-2">
              {cubit.estimatedExperienceDurationInMinutes > 0 && (
                <div className="flex h-[52px] items-center justify-between gap-2 rounded-xl border border-outline-variant px-4">
                  <div>
                    <ClockIcon size={15} />
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="text-title-medium">
                      {cubit.estimatedExperienceDurationInMinutes}
                    </div>
                    <div className="text-body-medium text-on-surface-var">
                      {t('instructor_library.minutes')}
                    </div>
                  </div>
                </div>
              )}
              <div className="text-title-medium flex h-[52px] items-center justify-between gap-2 rounded-xl border border-outline-variant px-4">
                <Money size={12} />
                <Trans
                  i18nKey={
                    slideDeck.priceInDollars === '0'
                      ? 'instructor_library.price_zero'
                      : 'instructor_library.price'
                  }
                  values={{ price: slideDeck.priceInDollars }}
                  components={{
                    light: (
                      <div
                        className={classNames(
                          'text-body-medium',
                          slideDeck.priceInDollars === '0'
                            ? 'text-on-surface'
                            : 'text-on-surface-var'
                        )}
                      />
                    ),
                  }}
                />
              </div>
              {showPreviewBtn && (
                <BreakoutButton
                  size="large"
                  kind="secondary"
                  className="mr-1"
                  icon={<EyeOpen size={15} />}
                  onClick={() => {
                    showDialog(() => (
                      <ShowExperienceDialog slideDeckId={cubit.slideDeckId} />
                    ))
                  }}
                >
                  {t('instructor_library.preview_experience')}
                </BreakoutButton>
              )}
            </div>
          </div>

          {cubit.references.models.length > 0 &&
            cubit.references.models.map((reference) => (
              <div
                key={reference.id}
                className="mt-3 flex items-center rounded-2xl bg-surface px-4 py-3"
              >
                <div
                  className={classNames(
                    'flex items-center divide-x-2',
                    reference.data.referenceType ===
                      SlideDeckReferenceType.alternativeTo
                      ? 'divide-fixed-accent-color'
                      : 'divide-on-surface-disabled'
                  )}
                >
                  <h2 className="text-label-large mr-3 w-[100px] text-on-surface">
                    {reference.data.referenceType ===
                    SlideDeckReferenceType.alternativeTo
                      ? t('instructor_library.alternative_to')
                      : t('instructor_library.based_on')}
                  </h2>
                  <div className="pl-3">
                    <p className="text-title-medium  italic text-on-surface">
                      {reference.data.referenceTitle}
                    </p>
                    <p className="text-body-large text-on-surface-var">
                      {reference.data.referenceAuthor},{' '}
                      {reference.data.referencePublisher},{' '}
                      {reference.getFormattedPublishedAt()}
                    </p>
                  </div>
                </div>
                <div className="flex flex-grow items-center justify-end">
                  <LinkIcon
                    size={16}
                    className="cursor-pointer"
                    onClick={() => {
                      window.open(reference.data.referenceURL, '_blank')
                    }}
                  />
                </div>
              </div>
            ))}

          <p className="text-body-large mt-3 text-on-surface-var">
            {slideDeck.data.slideDeckDescription}
          </p>

          {cubit.authors.models.length > 0 && (
            <div className="mt-3">
              <h3 className="text-title-large text-on-surface">
                {t('instructor_library.authors')}
              </h3>
              <div className="space-y-3">
                {cubit.authors.models.map((author) => (
                  <div
                    className="mt-1 flex flex-row items-center gap-2"
                    key={author.id}
                  >
                    {author.data.authorImageURL && (
                      <div>
                        <GoogleStorageImage
                          src={author.data.authorImageURL}
                          className="aspect-square max-h-[30px] max-w-[30px] rounded-full border-1 border-surface"
                          namespace="instructor-library"
                          alt={author.data.authorName}
                        />
                      </div>
                    )}
                    <div>
                      <div className="text-title-medium line-clamp-1 text-on-surface">
                        {author.data.authorName}
                      </div>
                      <div className="text-body-medium line-clamp-1 text-on-surface-var">
                        {author.data.authorTitle}—
                        {author.data.authorInstitution}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {cubit.slideDeck.data.slideDeckLearningObjectives.length > 0 && (
            <div className="mt-3">
              <h3 className="text-title-large text-on-surface">
                {t('instructor_library.learning_objectives')}
              </h3>
              <ul className="list-disc pl-5 marker:text-on-surface-var">
                {cubit.slideDeck.data.slideDeckLearningObjectives.map(
                  (objective, index) => (
                    <li
                      key={objective}
                      className={classNames(index !== 0 && '-mt-1.5')}
                    >
                      <div className="mt-1 flex flex-row items-center gap-2">
                        <div className="text-body-large text-on-surface-var">
                          {objective}
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          )}

          {cubit.rubrics.length > 0 && (
            <div className="mt-3">
              <h3 className="text-title-large text-on-surface">
                {t('instructor_library.rubrics')}
              </h3>
              <ul className="list-disc pl-5 marker:text-on-surface-var">
                {cubit.rubrics.models.map((rubric, index) => (
                  <li
                    key={rubric.id}
                    className={classNames(index !== 0 && '-mt-1.5')}
                  >
                    <div className="mt-1 flex flex-row items-center gap-2">
                      <div className="text-body-large text-on-surface-var">
                        {rubric.data.rubricDescription}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {instructorMaterials.length > 0 && (
            <div className="mt-3">
              <h3 className="text-title-large text-on-surface">
                {t('instructor_library.instructor_materials')}
              </h3>
              <div className="mr-1 mt-2 grid grid-cols-2 gap-x-3 gap-y-2">
                {instructorMaterials.map((material) => (
                  <div
                    key={material.id}
                    className="flex h-[52px] items-center justify-between rounded-2xl bg-surface px-4"
                  >
                    <p className="text-label-medium line-clamp-1 text-on-surface">
                      {material.data.materialName}
                    </p>
                    <CourseMaterialButton
                      material={material}
                      slideDeckId={cubit.slideDeckId}
                      slideDeckName={cubit.slideDeck.data.slideDeckName}
                      slideDeckVersion={cubit.slideDeck.data.slideDeckVersion}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div tabIndex={0}>
          <div className="relative flex items-center py-4">
            <div className="flex-grow border-t-1 border-fixed-accent-color"></div>
            <strong className="text-title-medium mx-4 flex-shrink text-on-surface">
              {t('instructor_library.student_experience')}
            </strong>
            <div className="flex-grow border-t-1 border-fixed-accent-color"></div>
          </div>

          {(preWorkMaterials.length > 0 || otherMaterials.length > 0) && (
            <div className="rounded-[20px] border border-outline-variant px-8 py-5">
              <h1 className="text-headline-medium text-on-surface">
                {t('instructor_library.materials')}
              </h1>
              <div
                className={classNames(
                  'mt-3 grid gap-5',
                  preWorkMaterials.length === 0 || otherMaterials.length === 0
                    ? 'grid-cols-1'
                    : 'grid-cols-2'
                )}
              >
                {preWorkMaterials.length > 0 && (
                  <div>
                    <h2 className="text-title-medium text-on-surface">
                      {t('instructor_library.pre_work_materials')}
                    </h2>
                    <div className="mt-1 space-y-2">
                      {preWorkMaterials.map((material) => (
                        <div
                          key={material.id}
                          className="flex h-[52px] items-center justify-between rounded-2xl bg-surface px-4"
                        >
                          <p className="text-label-medium line-clamp-1 text-on-surface">
                            {material.data.materialName}
                          </p>
                          <CourseMaterialButton
                            material={material}
                            slideDeckVersion={
                              cubit.slideDeck.data.slideDeckVersion
                            }
                            slideDeckId={cubit.slideDeckId}
                            slideDeckName={cubit.slideDeck.data.slideDeckName}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {otherMaterials.length > 0 && (
                  <div>
                    {preWorkMaterials.length !== 0 && (
                      <h2 className="text-title-medium text-on-surface">
                        {t('instructor_library.other_materials')}
                      </h2>
                    )}

                    <div
                      className={classNames('mt-1 grid gap-2', {
                        'grid-cols-2': preWorkMaterials.length === 0,
                      })}
                    >
                      {otherMaterials.map((material) => (
                        <div
                          key={material.id}
                          className="flex h-[52px] items-center justify-between rounded-2xl bg-surface px-4"
                        >
                          <p className="text-label-medium line-clamp-1 text-on-surface">
                            {material.data.materialName}
                          </p>
                          <CourseMaterialButton
                            material={material}
                            slideDeckId={cubit.slideDeckId}
                            slideDeckName={cubit.slideDeck.data.slideDeckName}
                            slideDeckVersion={
                              cubit.slideDeck.data.slideDeckVersion
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {preQuizQuestions.length > 0 && (
            <div className="mt-3 rounded-[20px] border border-outline-variant px-8 py-5">
              <h1 className="text-headline-medium text-on-surface">
                {t('instructor_library.quiz')}
              </h1>
              <div>
                <ul className="mt-3 !space-y-3  marker:text-on-surface">
                  {preQuizQuestions.map((question, index) => (
                    <Question
                      question={question}
                      index={index}
                      key={question.id}
                    />
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className="mt-3 rounded-[20px] border border-outline-variant px-8 py-9">
            <div className="flex items-center justify-between">
              <div>
                <h1 className="text-headline-medium text-on-surface">
                  {t('instructor_library.main_experience')}
                </h1>
                <p className="text-body-large mt-1 text-on-surface-var">
                  {t('instructor_library.main_experience_subtitle')}
                </p>
              </div>
              <div>
                <BreakoutButton
                  size="large"
                  kind="secondary"
                  icon={<EyeOpen size={14} />}
                  onClick={() => {
                    showDialog(() => (
                      <ShowExperienceDialog slideDeckId={cubit.slideDeckId} />
                    ))
                  }}
                >
                  {t('instructor_library.preview_experience')}
                </BreakoutButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

const Question = observer(function Question({
  question,
  index,
}: {
  question: SlideQuestion
  index: number
}) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div
      key={question.id}
      className={classNames(
        'text-label-large cursor-pointer select-none',
        index !== 0 && '!mt-0.5'
      )}
      onClick={() => setIsExpanded((prev) => !prev)}
    >
      <div className="flex items-center justify-between">
        <div className="mr-1">{question.data.question}</div>
        <div className="shrink-0">
          <ChevronLeft
            size={20}
            className={`transition-all ${isExpanded ? 'rotate-90' : 'rotate-270'}`}
          />
        </div>
      </div>
      {isExpanded && (
        <ul className="text-body-medium mt-1">
          {question.data.answers.map((answer, index) => (
            <li key={index} className="text-body-large ml-5 list-decimal">
              {answer} {question.data.correct === index && '✅'}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
})

function CanvasRedirectDialog({ href }: { href: string }) {
  const { t } = useTranslation()
  return (
    <Dialog size="xxl" ignoreEscapeKey ignoreBackdropClick>
      <div className="lti-configuration min-h-screen w-screen">
        <div className="">
          <img
            src="/assets/images/logo.png"
            width={64}
            className="absolute right-8 top-8"
            alt={t('breakout_learning')}
          />
        </div>
        <div className="flex min-h-screen w-full flex-row items-center justify-start gap-4">
          <div className="ml-[5vw] w-[50%]">
            <div className="mb-5 max-w-[400px]">
              <h1 className="text-headline-large">
                {t('lti.configuration.youre_all_set')}
              </h1>
              <div className="text-body-large">
                <Trans
                  i18nKey="lti.configuration.youre_all_set_description"
                  components={{
                    strong: <strong />,
                  }}
                />
              </div>
            </div>
            <div>
              <div>
                <BreakoutButtonLink
                  className="flex items-center justify-center"
                  kind="accent"
                  href={href}
                >
                  {t('lti.configuration.continue')}
                </BreakoutButtonLink>
              </div>
            </div>
          </div>
          <div>
            <div className="max-w-[80%]">
              <img src="/assets/images/lti/post-choice-image.jpeg" />
              <div className="text-right">
                <img
                  className="float-right mr-7 w-[150px] rotate-45"
                  src="/assets/images/avcheck-arrow.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

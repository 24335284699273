import { zodResolver } from '@hookform/resolvers/zod'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { useInstructorSectionCubit } from 'hooks/cubits/instructorSection'
import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import { PromotionSelectionWidget } from 'pages/instructor/classes/PromotionSelectionWidget'
import { useState, useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

const getSchema = (t: ReturnType<typeof useTranslation>['t']) =>
  z.object({
    className: z
      .string()
      .min(1, { message: t('lti.configuration.section_class_name_empty') }),

    sectionName: z
      .string()
      .min(1, { message: t('lti.configuration.section_section_name_empty') }),
  })

type FormValues = z.infer<ReturnType<typeof getSchema>>

export const SectionConfiguration = observer(function SectionConfiguration({
  setWantsInvoicing,
}: {
  setWantsInvoicing: (value: boolean) => void
}) {
  const sectionCubit = useInstructorSectionCubit()
  const section = sectionCubit.section
  const user = useBreakoutUser()
  const { t } = useTranslation()

  const userPromotions = user.userPromotions.models
  const [selectedUserPromotionIds, setSelectedUserPromotionIds] = useState<
    string[]
  >([])

  const schema = useMemo(() => getSchema(t), [t])

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      className: section.data.className,
      sectionName: section.data.sectionName,
    },
  })

  const onSubmit = useCallback(
    async (data: FormValues) => {
      const { className, sectionName } = data

      await sectionCubit.updateSection({ className, sectionName })
      const promotions = userPromotions?.filter((p) =>
        selectedUserPromotionIds.includes(p.id)
      )
      if (promotions && promotions.length > 0) {
        await sectionCubit.redeemPromotions(section.id, promotions)
      }

      // we want to save the section, but we also want to make sure that the next step (invoicing) is triggered
      setWantsInvoicing(true)

      await sectionCubit.markAsNotStarted()
    },
    [
      selectedUserPromotionIds,
      userPromotions,
      sectionCubit,
      section.id,
      setWantsInvoicing,
    ]
  )

  return (
    <div className="section flex min-h-screen w-full flex-col items-start justify-center">
      <div className="ml-[5vw] max-w-[400px]">
        <div className="mb-5">
          <h2 className="text-headline-small">
            {t('lti.configuration.section_settings')}
          </h2>
          <h1 className="text-headline-large">
            {t('lti.configuration.complete_section_settings')}
          </h1>
          <div className="text-body-large">
            {t('lti.configuration.section_settings_description')}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
          <Controller
            control={control}
            name="className"
            render={({ field, fieldState }) => {
              return (
                <BreakoutTextInput
                  label={t('lti.configuration.section_class_name')}
                  {...field}
                  error={fieldState.error}
                />
              )
            }}
          />

          <Controller
            control={control}
            name="sectionName"
            render={({ field, fieldState }) => {
              return (
                <BreakoutTextInput
                  label={t('lti.configuration.section_section_name')}
                  {...field}
                  error={fieldState.error}
                />
              )
            }}
          />

          <PromotionSelectionWidget
            onChange={setSelectedUserPromotionIds}
            kind="tertiary"
          />
          <BreakoutButton
            kind="accent"
            type="submit"
            size="medium"
            className="bg-fixed-accent-color"
          >
            {t('lti.configuration.confirm')}
          </BreakoutButton>
        </form>
      </div>
    </div>
  )
})

import { Button } from 'components/Button'
import { ShowCompletedButton } from 'components/ShowCompletedButton'
import { PlusIcon } from 'components/icons/Plus'
import {
  Actions,
  Contents,
  Header,
  Headline,
  MainPane,
} from 'pages/layout/TwoColumn'
import { useRepository } from 'hooks/auth'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { SectionList } from './SectionList'
import { useDialogs } from 'hooks/dialogs'
import { InstructorClassesCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorClassesCubit'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { useCubitBuilder } from 'hooks/cubits'
import { ephemeralFlags } from 'config/ephemeral'
import { ImpersonationInfo } from 'components/ImpersonationInfo'
import { PageTitle } from 'components/PageTitle'
import { CreateOrEditSectionDialog } from './CreateOrEditSectionDialog'
import { toast } from 'react-hot-toast'
import { useCanvasMode } from 'hooks/use-canvas-mode'

export const InstructorClassesPage = observer(function InstructorClassesPage() {
  const repository = useRepository()
  const { t } = useTranslation()
  const rootStore = useRootStore()

  const user = useBreakoutUser()

  const defaultShowCompleted =
    ephemeralFlags.instructorClassPageShowCompleted || false

  const cubit = useCubitBuilder(
    () =>
      new InstructorClassesCubit(repository, {
        role: user.role || '',
        defaultShowCompleted,
        impersonationInfo: rootStore.impersonationInfo,
      }),
    [repository, user.role, rootStore.impersonationInfo]
  )

  return (
    <MainPane className="z-20">
      <PageTitle title={t('instructor_classes.page_title')} />
      <Header>
        <Headline>{t('instructor_classes.headline')}</Headline>
        <ImpersonationInfo />
        <ClassesActions cubit={cubit} />
      </Header>
      <Contents className="h-full md:overflow-auto">
        <SectionList cubit={cubit} />
      </Contents>
    </MainPane>
  )
})

const ClassesActions = observer(function ClassesActions({
  cubit,
}: {
  cubit: InstructorClassesCubit
}) {
  const { t } = useTranslation()
  const user = useBreakoutUser()
  const rootStore = useRootStore()
  const { showDialog, popDialog } = useDialogs()
  const { canvasMode } = useCanvasMode()
  return (
    <Actions>
      <ShowCompletedButton
        onClick={() => {
          ephemeralFlags.instructorClassPageShowCompleted = !cubit.showCompleted
          cubit.toggleShowCompleted()
        }}
        showCompleted={cubit.showCompleted}
      />
      {(user.isInternal || user.isInstructor) && !canvasMode && (
        <Button
          onClick={() => {
            showDialog(() => (
              <CreateOrEditSectionDialog
                createSectionInvoiceRequest={cubit.createSectionInvoiceRequest}
                saveSection={cubit.createSection}
                onSave={({ invoiceCreated }) => {
                  popDialog()
                  toast.success(
                    invoiceCreated
                      ? t('instructor_class.section_created_invoice_created')
                      : t('instructor_class.class_created')
                  )
                }}
                impersonationInfo={rootStore.impersonationInfo}
                redeemPromotions={cubit.redeemPromotions}
                allowSectionPassPaymentMethod={
                  cubit.allowSectionPassPaymentMethod
                }
              />
            ))
          }}
          overrideClassName="bg-core-primary text-core-on-primary h-[54px] w-[182px] rounded-2xl text-label-large"
        >
          <PlusIcon className="mr-1 inline" size={14} />
          {t('instructor_library.add_class')}
        </Button>
      )}
    </Actions>
  )
})

import { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { zodResolver } from '@hookform/resolvers/zod'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutDateTimeInput } from 'components/design-system/BreakoutDateTimeInput'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { FullPageSpinner } from 'components/Spinner'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { useInstructorSectionCubit } from 'hooks/cubits/instructorSection'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { dateSchema } from 'util/schema-date'
import { z } from 'zod'

const getSchema = (t: ReturnType<typeof useTranslation>['t']) =>
  z.object({
    groupingType: z.nativeEnum(AssignmentGroupingType),
    groupingSize: z.coerce
      .number()
      .min(2, {
        message: t('instructor_library.grouping_size_invalid'),
      })
      .max(RoomState.maxAllowedUsers, {
        message: t('instructor_library.grouping_size_invalid'),
      })
      .optional(),
  })

const getSchemaWithExpiration = (
  t: ReturnType<typeof useTranslation>['t'],
  minExpiration: DateTime
) => {
  return z.object({
    groupingType: z.nativeEnum(AssignmentGroupingType),
    groupingSize: z.coerce
      .number()
      .min(2, {
        message: t('instructor_library.grouping_size_invalid'),
      })
      .max(RoomState.maxAllowedUsers, {
        message: t('instructor_library.grouping_size_invalid'),
      })
      .optional(),
    expiresAt: dateSchema({
      required: t('instructor_library.deadline_required'),
      min: {
        date: minExpiration,
        message: t(
          'instructor_assignment.deadline_must_be_one_day_after_start_date'
        ),
      },
    }),
  })
}

type FormValues = z.infer<ReturnType<typeof getSchema>>
type FormValuesWithExpiresAt = z.infer<
  ReturnType<typeof getSchemaWithExpiration>
>
type Mode = 'simple' | 'with-expired-at'

export const AssignmentConfiguration = observer(
  function AssignmentConfiguration() {
    const assignmentCubit = useInstructorAssignmentCubit()
    const [lockedMode, setLockedMode] = useState<Mode | undefined>()
    const expiresAt = assignmentCubit.assignment?.data.expiresAt
    const assignedAt = assignmentCubit.assignment?.data.assignedAt
    const mode = useMemo(() => {
      if (lockedMode) return lockedMode

      // if expires at is 24 hours after assigned at, we should show the simple form
      if (
        expiresAt &&
        assignedAt &&
        expiresAt.getTime() - assignedAt.getTime() > 24 * 60 * 60 * 1000
      ) {
        return 'simple'
      }

      return 'with-expired-at'
      // return expiresAt ? 'simple' : 'with-expired-at'
    }, [expiresAt, assignedAt, lockedMode])

    if (!assignmentCubit.assignment) {
      return <FullPageSpinner />
    }

    if (mode === 'with-expired-at') {
      return (
        <AssignmentConfigurationWithExpiredAt
          onSaving={(saving) => {
            setLockedMode(saving ? 'with-expired-at' : undefined)
          }}
        />
      )
    } else {
      return (
        <AssignmentConfigurationSimple
          onSaving={(saving) => {
            setLockedMode(saving ? 'simple' : undefined)
          }}
        />
      )
    }
  }
)

// Simpler form, does not impact dates at all
const AssignmentConfigurationSimple = observer(
  function AssignmentConfigurationSimple({
    onSaving,
  }: {
    onSaving?: (saving: boolean) => void
  }) {
    const { t } = useTranslation()
    const sectionCubit = useInstructorSectionCubit()
    const assignmentCubit = useInstructorAssignmentCubit()
    const slideDeck = assignmentCubit.slideDeck
    const [isSaving, setIsSaving] = useState(false)

    const schema = useMemo(() => {
      return getSchema(t)
    }, [t])

    const { control, handleSubmit, setValue, watch } = useForm<FormValues>({
      resolver: zodResolver(schema),
      mode: 'onChange',
      defaultValues: {
        groupingType: AssignmentGroupingType.manual,
        groupingSize: 4,
      },
    })

    const onSubmit = useCallback(
      async (data: FormValues) => {
        setIsSaving(true)
        onSaving?.(true)
        try {
          await assignmentCubit.updateAssignmentGrouping({
            groupingType: data.groupingType,
            groupingSize:
              data.groupingType === AssignmentGroupingType.automaticRandom
                ? data.groupingSize
                : undefined,
          })
          await assignmentCubit.markAsActive()
          await sectionCubit.markAsInProgress()
        } finally {
          setIsSaving(false)
          onSaving?.(false)
        }
      },
      [assignmentCubit, sectionCubit, onSaving]
    )

    const { groupingType } = watch()

    return (
      <div className="assignment flex min-h-screen w-full flex-col items-start justify-center">
        <div className="ml-[5vw] max-w-[400px]">
          <div className="mb-5">
            <h2 className="text-headline-small">
              {t('lti.configuration.assigment_settings')}
            </h2>
            <h1 className="text-headline-large">
              {t('lti.configuration.complete_assignment_configuration')}
            </h1>
            <div className="text-body-large">
              {t('lti.configuration.complete_assignment_description')}
            </div>
          </div>
          <div className="mb-3 flex flex-row items-center gap-3 rounded-2xl bg-core-secondary px-2 py-3">
            <div className="pl-3">
              <img
                className="max-h-5 max-w-5"
                src={slideDeck.data.slideDeckImageURL}
                alt={slideDeck.data.slideDeckName}
              />
            </div>
            <div>
              <div className="text-body-medium text-on-surface-var">
                {slideDeck.data.slideDeckName}
              </div>
              <div className="text-label-large">
                {slideDeck.data.slideDeckTeaser}
              </div>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            <Controller
              control={control}
              name="groupingType"
              render={({ field, fieldState }) => {
                return (
                  <BreakoutSelect
                    {...field}
                    onChange={(value) => {
                      // if we switch to manual, we should clear the grouping size
                      if (value === AssignmentGroupingType.manual) {
                        setValue('groupingSize', undefined, {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }
                      setValue('groupingType', value, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }}
                    label={t('lti.configuration.grouping_method')}
                    kind="secondary"
                    options={[
                      {
                        value: AssignmentGroupingType.manual,
                        label: t(
                          'lti.configuration.grouping_method_self_grouping'
                        ),
                      },
                      {
                        value: AssignmentGroupingType.automaticRandom,
                        label: t(
                          'lti.configuration.grouping_method_automatic_randomized_grouping'
                        ),
                      },
                    ]}
                    error={fieldState.error}
                  />
                )
              }}
            />

            {groupingType === AssignmentGroupingType.automaticRandom && (
              <Controller
                control={control}
                name="groupingSize"
                render={({ field, fieldState }) => {
                  return (
                    <BreakoutTextInput
                      {...field}
                      error={fieldState.error}
                      label={t('lti.configuration.grouping_size')}
                      type="number"
                    />
                  )
                }}
              />
            )}
            <div className="text-body-medium px-4 py-2 text-on-surface-var">
              {t('lti.configuration.grouping_method_description')}
            </div>

            <BreakoutButton
              kind="accent"
              type="submit"
              size="medium"
              className="bg-fixed-accent-color"
              loading={isSaving}
            >
              {t('lti.configuration.confirm')}
            </BreakoutButton>
          </form>
        </div>
      </div>
    )
  }
)

const AssignmentConfigurationWithExpiredAt = observer(
  function AssignmentConfigurationWithExpiredAt({
    onSaving,
  }: {
    onSaving?: (saving: boolean) => void
  }) {
    const { t } = useTranslation()
    const assignmentCubit = useInstructorAssignmentCubit()
    const sectionCubit = useInstructorSectionCubit()
    const slideDeck = assignmentCubit.slideDeck
    const [isSaving, setIsSaving] = useState(false)

    const assignedAt = DateTime.fromJSDate(
      assignmentCubit.assignment?.data.assignedAt ?? new Date()
    )
    const expiresAt = assignmentCubit.assignment?.data.expiresAt

    const minScheduleDate = useMemo(() => {
      const now = DateTime.now()

      // set min date to start of tomorrow
      const min = now.plus({ days: 1 }).startOf('day')

      return min
    }, [])

    const schema = useMemo(() => {
      return getSchemaWithExpiration(t, minScheduleDate)
    }, [t, minScheduleDate])

    const { control, handleSubmit, setValue, watch, setError } =
      useForm<FormValuesWithExpiresAt>({
        resolver: zodResolver(schema),
        mode: 'onChange',
        defaultValues: {
          groupingType: AssignmentGroupingType.manual,
          groupingSize: 4,
          expiresAt: expiresAt ? DateTime.fromJSDate(expiresAt) : undefined,
        },
      })

    const onSubmit = useCallback(
      async (data: FormValuesWithExpiresAt) => {
        if (!expiresAt && !data.expiresAt) {
          setError('expiresAt', {
            type: 'required',
            message: t('instructor_library.deadline_required'),
          })
          return
        }

        // if both are set, and expiresAt is less than 24 hours from assignedAt, show error
        if (
          assignedAt &&
          data.expiresAt &&
          data.expiresAt.diff(assignedAt, 'hours').hours < 24
        ) {
          const min = assignedAt
            .plus({ days: 1 })
            .toLocaleString(DateTime.DATETIME_MED)
          setError('expiresAt', {
            type: 'min',
            message: t('instructor_assignment.deadline_must_be_after', { min }),
          })
          return
        }

        setIsSaving(true)
        onSaving?.(true)
        try {
          await assignmentCubit.updateAssignment({
            expiresAt: data.expiresAt,
            assignedAt: assignedAt,
            groupingType: data.groupingType,
            groupingSize:
              data.groupingType === AssignmentGroupingType.automaticRandom
                ? data.groupingSize
                : 4,
          })
          await assignmentCubit.markAsActive()
          await sectionCubit.markAsInProgress()
        } finally {
          setIsSaving(false)
          onSaving?.(false)
        }
      },
      [
        assignmentCubit,
        sectionCubit,
        expiresAt,
        setError,
        t,
        assignedAt,
        onSaving,
      ]
    )

    const { groupingType } = watch()

    return (
      <div className="assignment flex min-h-screen w-full flex-col items-start justify-center">
        <div className="ml-[5vw] max-w-[400px]">
          <div className="mb-5">
            <h2 className="text-headline-small">
              {t('lti.configuration.assigment_settings')}
            </h2>
            <h1 className="text-headline-large">
              {t('lti.configuration.complete_assignment_configuration')}
            </h1>
            <div className="text-body-large">
              {t('lti.configuration.complete_assignment_description')}
            </div>
          </div>
          <div className="mb-3 flex flex-row items-center gap-3 rounded-2xl bg-core-secondary px-2 py-3">
            <div className="pl-3">
              <img
                className="max-h-5 max-w-5"
                src={slideDeck.data.slideDeckImageURL}
                alt={slideDeck.data.slideDeckName}
              />
            </div>
            <div>
              <div className="text-body-medium text-on-surface-var">
                {slideDeck.data.slideDeckName}
              </div>
              <div className="text-label-large">
                {slideDeck.data.slideDeckTeaser}
              </div>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            <Controller
              control={control}
              name="expiresAt"
              render={({ field, fieldState }) => {
                return (
                  <BreakoutDateTimeInput
                    {...field}
                    error={fieldState.error}
                    type="datetime-local"
                    label={t('instructor_assignment.assignment_deadline')}
                    kind="secondary"
                    id="meeting-time"
                    hideNowButton
                    min={assignedAt?.plus({ days: 1, minutes: 1 })}
                    max={assignedAt?.plus({ days: 365 })}
                    // Set initial view to one day after assignedAt
                    initialView={assignedAt?.plus({ days: 1, minutes: 1 })}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )
              }}
            />

            <Controller
              control={control}
              name="groupingType"
              render={({ field, fieldState }) => {
                return (
                  <BreakoutSelect
                    {...field}
                    onChange={(value) => {
                      // if we switch to manual, we should clear the grouping size
                      if (value === AssignmentGroupingType.manual) {
                        setValue('groupingSize', undefined, {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }
                      setValue('groupingType', value, {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }}
                    label={t('lti.configuration.grouping_method')}
                    kind="secondary"
                    options={[
                      {
                        value: AssignmentGroupingType.manual,
                        label: t(
                          'lti.configuration.grouping_method_self_grouping'
                        ),
                      },
                      {
                        value: AssignmentGroupingType.automaticRandom,
                        label: t(
                          'lti.configuration.grouping_method_automatic_randomized_grouping'
                        ),
                      },
                    ]}
                    error={fieldState.error}
                  />
                )
              }}
            />

            {groupingType === AssignmentGroupingType.automaticRandom && (
              <Controller
                control={control}
                name="groupingSize"
                render={({ field, fieldState }) => {
                  return (
                    <BreakoutTextInput
                      {...field}
                      error={fieldState.error}
                      label={t('lti.configuration.grouping_size')}
                      type="number"
                    />
                  )
                }}
              />
            )}
            <div className="text-body-medium px-4 py-2 text-on-surface-var">
              {t('lti.configuration.grouping_method_description')}
            </div>

            <BreakoutButton
              kind="accent"
              type="submit"
              size="medium"
              className="bg-fixed-accent-color"
              loading={isSaving}
            >
              {t('lti.configuration.confirm')}
            </BreakoutButton>
          </form>
        </div>
      </div>
    )
  }
)

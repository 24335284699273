import { type InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
import { observer } from 'mobx-react-lite'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { BreakoutAsyncButton } from 'components/design-system/BreakoutButton'
import { useDialogs } from 'hooks/dialogs'
import { useCallback, useMemo, useState } from 'react'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { useTranslation } from 'react-i18next'
import { useRepository } from 'hooks/auth'
import { RoomStateVideoMethod } from '@breakoutlearning/firebase-repository/types'
import { toast } from 'react-hot-toast'

export const SectionVideoMethodDialog = observer(
  function SectionVideoMethodDialog({
    cubit,
  }: {
    cubit: InstructorSectionCubit
  }) {
    const { popDialog } = useDialogs()
    const { t } = useTranslation()
    const { featureFlags } = useRepository()

    const tScoped = useCallback(
      (k: string) => t(`instructor_class.section_video_method_dialog.${k}`),
      [t]
    )
    const initialValue = useMemo(
      () => cubit.section.data.videoMethod ?? null,
      [cubit]
    )
    const [value, setValue] = useState(initialValue)

    return (
      <Dialog
        size="sm"
        innerClassName="flex h-full flex-col gap-5"
        ignoreBackdropClick
      >
        <DialogCloseButton />
        <div className="text-headline-large">{tScoped('title')}</div>
        <div className="text-body-medium text-on-surface-var">
          {tScoped('subtitle')}
        </div>
        <div className="flex h-full w-full flex-grow flex-row items-center justify-center">
          <BreakoutSelect
            label={tScoped('video_method')}
            kind="secondary"
            className="flex-grow"
            options={[
              {
                value: null,
                label:
                  featureFlags.data.videoMethod ===
                  RoomStateVideoMethod.broadcast
                    ? tScoped('default_broadcast')
                    : tScoped('default_streaming'),
              },
              {
                value: RoomStateVideoMethod.broadcast,
                label: t('broadcast'),
              },
              {
                value: RoomStateVideoMethod.streaming,
                label: t('streaming'),
              },
            ]}
            onChange={(v) => setValue(v)}
            value={value}
          />
        </div>
        <BreakoutAsyncButton
          size="large"
          disabled={value === initialValue}
          fullWidth
          onClick={async () => {
            try {
              await cubit.updateSectionVideoMethod(value)
              popDialog()
            } catch (e) {
              toast.error(
                `Video Method Update Error: ${e instanceof Error ? e.message : e}`
              )
            }
          }}
        >
          {tScoped('save')}
        </BreakoutAsyncButton>
      </Dialog>
    )
  }
)

import { Spinner } from 'components/Spinner'
import { Suspense, lazy } from 'react'
import type { AdminPage } from './AdminEntrypoint'

const AdminEntrypoint = lazy(() => import('./AdminEntrypoint'))

// This file is the boundary which allows vite to code split the admin pages
function AdminLoader({ page }: { page: AdminPage }) {
  return (
    <Suspense fallback={<Spinner className="m-8" />}>
      <AdminEntrypoint page={page} />
    </Suspense>
  )
}

export function AdminDemosLoader() {
  return <AdminLoader page="adminDemos" />
}

export function AdminLibraryLoader() {
  return <AdminLoader page="adminLibrary" />
}

export function AdminTeachingPlanLoader() {
  return <AdminLoader page="adminTeachingPlan" />
}

export function AdminDashboardLoader() {
  return <AdminLoader page="adminDashboard" />
}

export function AdminCatalogsLoader() {
  return <AdminLoader page="adminCatalogs" />
}

export function AdminUsersLoader() {
  return <AdminLoader page="adminUsers" />
}

export function AdminInstitutionsLoader() {
  return <AdminLoader page="adminInstitutions" />
}

export function AdminInspectorLoader() {
  return <AdminLoader page="adminInspector" />
}

import type { QueryParams, Store } from 'mobx-router'
import { RouterStore } from 'mobx-router'
import { routes, buildUrl } from '../config/routes'
import { makeObservable, observable, runInAction } from 'mobx'
import type { ImpersonationInfo } from '@breakoutlearning/firebase-repository/types'

export class RootStore {
  public router: RouterStore<Store>

  @observable
  impersonationInfo: ImpersonationInfo | undefined

  constructor() {
    this.router = new RouterStore<Store>(this)
    this.impersonationInfo = undefined
    makeObservable(this)
  }

  navigateTo = (
    routeName: string,
    paramsObj?: QueryParams,
    queryParams?: QueryParams
  ) => {
    const route = routes[routeName]
    if (!route) {
      throw new Error(`Route with name ${routeName} not found`)
    }

    this.router.goTo(route, paramsObj, queryParams)
  }

  navigateToWithHook(
    routeName: string,
    {
      hook,
      params,
    }: {
      hook: () => void
      params?: QueryParams
    }
  ) {
    const route = routes[routeName]
    if (!route) {
      throw new Error(`Route with name ${routeName} not found`)
    }

    this.router.goTo(route, params)
    window.requestAnimationFrame(hook)
  }

  navigateToAdminSlideDeck(slideDeckId: string, queryParams?: QueryParams) {
    this.navigateTo('adminSlideDeck', { slideDeckId }, queryParams)
  }

  navigateToMeeting(roomId: string, queryParams?: QueryParams) {
    this.navigateTo('meeting', { roomId }, queryParams)
  }

  navigateToDemoWaitingRoom(roomId: string, queryParams?: QueryParams) {
    this.navigateTo('demoWaitingRoom', { roomId }, queryParams)
  }

  navigateToInstructorAssignmentPage(
    sectionId: string,
    assignmentId: string,
    queryParams?: QueryParams
  ) {
    this.navigateTo(
      'instructorClassAssignment',
      {
        id: sectionId,
        assignmentId,
      },
      queryParams
    )
  }

  navigateToAdminTeachingPlan(
    teachingPlanId: string,
    catalogId: string,
    queryParams?: QueryParams
  ) {
    this.navigateTo(
      'adminTeachingPlan',
      { catalogId, teachingPlanId },
      queryParams
    )
  }

  navigateToAdminCatalog(catalogId: string) {
    this.navigateTo('adminCatalog', { catalogId })
  }

  navigateToAdminCatalogs() {
    this.navigateTo('adminCatalogs')
  }

  /**
   * Update the query params of the current route.
   *
   * This is useful for updating the query params of the current route without changing the route.
   *
   * It also means you don't have to know anything about the current route, you just the query params you want to update.
   *
   * The update merges the new query params with the existing query params.
   *
   * This can only be done on routes that have a title (titles are specified in routes list)
   *
   */
  updateQueryParams(queryParams: QueryParams) {
    const route = this.router.currentRoute
    // this should generally not be possible, but in type-land it is
    if (!route) return
    if (!route.title) {
      console.error('updateQueryParams can only be called on a named route')

      return
    }

    this.navigateTo(route.title, this.router.params, {
      ...this.router.queryParams,
      ...queryParams,
    })
  }

  navigateToSlideDeckPage(slideDeckId: string, queryParams?: QueryParams) {
    this.navigateTo(
      'instructorSlideDeck',
      {
        slideDeckId,
      },
      { ...queryParams }
    )
  }

  getSlideDeckPageUrl(
    slideDeckId: string,
    queryParams?: Record<string, string>
  ) {
    return buildUrl(
      'instructorSlideDeck',
      {
        slideDeckId,
      },
      queryParams
    )
  }

  navigateToTeachingPlanPage(
    catalogId: string,
    teachingPlanId: string,
    queryParams?: QueryParams
  ) {
    return this.navigateTo(
      'instructorTeachingPlan',
      {
        catalogId: catalogId,
        teachingPlanId: teachingPlanId,
      },
      queryParams
    )
  }

  navigateToAdminDashboard(tab: string) {
    this.navigateTo('adminDashboard', undefined, {
      tab,
    })
  }

  navigateToAdminInspectorRoom(roomId: string, tab?: string) {
    this.navigateTo(
      'adminInspectorRoom',
      { roomId },
      {
        tab,
      }
    )
  }

  impersonateUser({
    userId,
    organizationId,
  }: {
    userId: string
    organizationId?: string
  }) {
    if (!userId) return // don't allow empty userId
    runInAction(() => {
      this.impersonationInfo = { userId, organizationId }
    })
  }

  stopImpersonation() {
    runInAction(() => {
      this.impersonationInfo = undefined
    })
  }

  navigateToPodcastsPage(queryParams?: QueryParams) {
    this.navigateTo('profile', undefined, {
      ...queryParams,
      tab: 'podcast',
    })
  }
}

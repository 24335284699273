import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { RoomStateForm } from './RoomStateFormDialog'
import { Spinner } from 'components/Spinner'
import { useDialogs } from 'hooks/dialogs'
import { useTranslation } from 'react-i18next'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { Dialog } from 'components/dialogs/Dialog'
import { ShadowBox } from 'components/ShadowBox'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import {
  RoomStateStatus,
  type RoomState,
} from '@breakoutlearning/firebase-repository/models/RoomState'
import { useRepository } from 'hooks/auth'
import {
  BreakoutUserAvatar,
  BreakoutUserAvatarStack,
} from 'components/breakout/BreakoutUserAvatar'
import { LockOn } from 'components/icons/LockOn'
import { DateTime } from 'luxon'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Search } from 'components/icons/Search'
import { PeopleGroup } from 'components/icons/PeopleGroup'
import { MultiLineText } from 'components/breakout/MultiLineText'
import { type SectionAssignment } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { type SlideDeck } from '@breakoutlearning/firebase-repository/models/SlideDeck'
import { type Section } from '@breakoutlearning/firebase-repository/models/Section'

const JoinRoomDialog = observer(function JoinRoomDialog({
  cubit,
  room,
  onClick,
}: {
  cubit: StudentAssignmentCubit
  room: RoomState
  onClick: () => void
}) {
  const { t } = useTranslation()
  // Format the date (e.g., 12 Dec 23')
  const formattedDate = room.scheduledAtDate?.toFormat('dd LLL yy')
  // Format the time with time zone (e.g., 10:00 PM (PDT))
  const formattedTime = room.scheduledAtDate?.toFormat('h:mm a (ZZZZ)')

  useEffect(() => {
    cubit.logEvent('show_join_group')
  }, [cubit])

  return (
    <Dialog size="xs">
      <DialogCloseButton className="p-5 pb-0" />
      <div>
        <h1 className="text-headline-large text-center">
          {t('student_assignment.join_group_confirm')}
        </h1>
        <ShadowBox className="mb-10 mt-5">
          <div className="flex h-full flex-1 flex-col items-center justify-center px-5 ">
            <div className="text-headline-medium">{room.roomStateName}</div>
            {room.userIds.length === 0 && (
              <div className="text-body-medium mt-1 text-center">
                {t('student_assignment.no_students')}
              </div>
            )}
            {room.userIds.length > 0 && (
              <BreakoutUserAvatarStack users={room.users} radius={12} />
            )}
            <div className="text-label-large mt-4 text-center">
              {formattedDate && <div>{formattedDate}&apos;</div>}
              {formattedTime && <div>{formattedTime}</div>}
            </div>
          </div>
        </ShadowBox>
        <BreakoutButton
          data-testid="join-room-dialog-confirm"
          onClick={onClick}
          size="large"
          fullWidth
        >
          {t('student_assignment.confirm')}
        </BreakoutButton>
      </div>
    </Dialog>
  )
})

const RoomRow = observer(function RoomRow({
  assignment,
  joinRoom,
  room,
  section,
  slideDeck,
}: {
  assignment: SectionAssignment
  joinRoom: (roomId: string) => void
  room: RoomState
  section: Section
  slideDeck: SlideDeck
}) {
  const repository = useRepository()
  const users = room.data.userIds.map((id) => repository.userStore.getUser(id))
  const { t } = useTranslation()

  const formattedDate = useMemo(() => {
    const { scheduledAt } = room.data
    if (!scheduledAt) return null

    const dayStr = new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(scheduledAt)
    const timeStr =
      DateTime.fromJSDate(scheduledAt, { zone: 'local' })
        .setZone(DateTime.local().zoneName)
        .toLocaleString(DateTime.TIME_SIMPLE) +
      ' ' +
      DateTime.local().toFormat('ZZZZ')
    return {
      dayStr,
      timeStr,
    }
  }, [room.data])

  const roomStatus = room.getRoomStateStatus(
    assignment.expiresAt,
    section.data.sectionState,
    slideDeck.data.slideDeckSlideCount,
    assignment.data.assignmentState
  )

  const isLocked =
    room.hasMaxUsers ||
    [
      RoomStateStatus.expired,
      RoomStateStatus.completed,
      RoomStateStatus.abandoned,
    ].includes(roomStatus)

  return (
    <tr>
      <td className="!px-0">
        <div className="flex h-full w-full items-center px-3">
          <strong className="text-label-large">{room.roomStateName}</strong>
        </div>
      </td>
      <td className="!px-0">
        <div className="flex flex-col justify-center gap-2 px-3">
          {users.map((user, i) => {
            return (
              <div
                key={`${user.id || i}_${room.id}`}
                className="flex flex-row items-center gap-1"
              >
                <BreakoutUserAvatar user={user} radius={12.5} />
                <strong className="text-body-large line-clamp-1">
                  {user.fullName}
                </strong>
              </div>
            )
          })}
        </div>
      </td>
      <td className="!px-0">
        {formattedDate && (
          <div className="flex flex-col px-3">
            <strong className="text-label-medium">
              {formattedDate.dayStr}
            </strong>
            <strong className="text-body-medium text-on-surface-var">
              {formattedDate.timeStr}
            </strong>
          </div>
        )}
      </td>
      <td className="!px-0">
        <div className="px-3">
          {isLocked && (
            <BreakoutButton
              size="small"
              kind="secondary"
              className="text-body-small md:px-12"
              icon={<LockOn size={12} />}
              tooltip={t('dart.group_locked_description')}
            >
              {t('dart.group_locked')}
            </BreakoutButton>
          )}
          {!isLocked && (
            <BreakoutButton
              size="small"
              kind="secondary"
              className="text-body-small md:px-12"
              onClick={() => joinRoom(room.id)}
            >
              {t('student_assignment.join_group')}
            </BreakoutButton>
          )}
        </div>
      </td>
    </tr>
  )
})

export const NewRoomState = observer(function NewRoomState({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const { t } = useTranslation()
  const { popDialog, showDialog } = useDialogs()

  const {
    roomStates: { isLoading, models },
  } = cubit

  const rooms = useMemo(() => {
    const roomsWithUsers = models.filter((room) => room.userCount > 0)
    const emptyRooms = models.filter((room) => room.userCount === 0)

    // Sort the rooms first by active slide, then by scheduledAt
    roomsWithUsers.sort((a, b) => {
      // Put active slides below inactive slides
      if (a.data.activeSlide !== b.data.activeSlide) {
        return a.data.activeSlide ? -1 : 1
      }

      if (!a.data.scheduledAt || !b.data.scheduledAt) return 0
      return a.data.scheduledAt.getTime() - b.data.scheduledAt.getTime()
    })

    emptyRooms.sort((a, b) => {
      // First, sort by scheduledAt
      if (a.data.scheduledAt && b.data.scheduledAt) {
        return a.data.scheduledAt.getTime() - b.data.scheduledAt.getTime()
      }
      // If one of them doesn't have scheduledAt, treat the one with scheduledAt as greater
      if (!a.data.scheduledAt && b.data.scheduledAt) return 1
      if (!b.data.scheduledAt && a.data.scheduledAt) return -1

      const roomA = a.roomStateName || ''
      const roomB = b.roomStateName || ''

      return roomA.localeCompare(roomB, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    })

    // concatenate the two arrays
    return roomsWithUsers.concat(emptyRooms)
  }, [models])

  const joinRoom = useCallback(
    async (room: RoomState) => {
      const roomIsEmpty = room.userCount === 0
      await cubit.joinRoom(room.id, { isGroupLeader: roomIsEmpty })
      popDialog?.()
      popDialog?.()
    },
    [cubit, popDialog]
  )

  const showJoinRoomDialog = useCallback(
    (id: string) => {
      const room = rooms.find((r) => r.id === id)
      if (!room) return

      showDialog(() => (
        <JoinRoomDialog
          cubit={cubit}
          room={room}
          onClick={() => joinRoom(room)}
        />
      ))
    },
    [cubit, joinRoom, rooms, showDialog]
  )

  // i put this here so we don't have to deal with resetting this on dialog
  // open / close and muddying the cubit, i like the idea of it not persisting
  // but I can move it to cubit if need be
  const [searchFilter, setSearchFilter] = useState('')
  const filteredRooms = useMemo(() => {
    return rooms.filter((room) => {
      const searchTerm = searchFilter.toLowerCase().trim()
      const userNamesWithRoomName =
        room.users.map((user) => user.fullName).join(' ') +
        (room.data.roomStateName || '')
      return userNamesWithRoomName.toLowerCase().includes(searchTerm)
    })
  }, [rooms, searchFilter])

  const showNoRooms = !isLoading && !rooms.length
  const showNoResults = !isLoading && !filteredRooms.length && rooms.length > 0

  return (
    <div className="flex w-full flex-col gap-3 text-core-on-tertiary">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col">
          <h1 className="text-headline-small md:text-2xl">
            {t('student_assignment.join_or_create_group')}
          </h1>
          <h2 className="text-body-large">
            {t('student_assignment.join_or_create_group_dialog_sub')}
          </h2>
        </div>
        <BreakoutButton
          size="medium"
          data-testid="room-dialog-new-room"
          onClick={() => {
            showDialog(() => (
              <RoomStateForm
                isCreate
                onSave={() => {
                  // pop, pop! - there are two dialogs to pop
                  popDialog()
                  popDialog()
                }}
                cubit={cubit}
              />
            ))
          }}
          className="py-[14.25px] text-xs font-semibold text-core-on-primary"
        >
          {t('student_assignment.create_new_group')}
        </BreakoutButton>
      </div>

      {!isLoading && rooms.length > 0 && (
        <BreakoutTextInput
          autoFocus
          name="rooms-filter"
          onChange={(value) => setSearchFilter(value.target.value)}
          LeadingIcon={Search}
          inputClassName="text-body-medium border font-medium placeholder-surface-on-surface-disabled"
          kind="tertiary"
          placeholder={t(
            'student_assignment.join_or_create_group_search_placeholder'
          )}
        />
      )}
      <div className="flex-grow">
        {filteredRooms.length > 0 && (
          <table className="breakout-table table-fixed">
            <thead>
              <tr>
                <th className="!px-3">{t('student_assignment.group_name')} </th>
                <th className="!px-3">
                  {t('student_assignment.students_in_group')}{' '}
                </th>
                <th className="!px-3">
                  {t('student_assignment.session_date')}{' '}
                </th>
                <th className="!px-3">{t('student_assignment.action')} </th>
              </tr>
            </thead>
            <tbody>
              {filteredRooms.map((room) => {
                return (
                  <RoomRow
                    assignment={cubit.assignment}
                    slideDeck={cubit.slideDeck}
                    key={room.id}
                    room={room}
                    section={cubit.section}
                    joinRoom={showJoinRoomDialog}
                  />
                )
              })}
            </tbody>
          </table>
        )}
        {isLoading && (
          <div className="flex h-full w-full items-center justify-center">
            <Spinner />
          </div>
        )}
        {showNoResults && <NoResults />}
        {showNoRooms && <NoRooms />}
      </div>
    </div>
  )
})

const NoRooms = observer(function NoRooms() {
  const { t } = useTranslation()

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-1">
      <PeopleGroup size={30} className="stroke-fixed-accent-color" />
      <h2 className="text-title-large text-on-surface">
        {t('student_assignment.join_or_create_group_first_one_here')}
      </h2>
      <p className="text-center text-on-surface-var">
        <MultiLineText
          string={t(
            'student_assignment.join_or_create_group_first_one_here_description'
          )}
        />
      </p>
    </div>
  )
})

const NoResults = observer(function NoResults() {
  const { t } = useTranslation()

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-1">
      <Search size={50} className="stroke-fixed-accent-color" />
      <h2 className="text-title-large text-on-surface">
        {t('student_assignment.join_or_create_group_no_results')}
      </h2>
      <p className="text-center text-on-surface-var">
        <MultiLineText
          string={t(
            'student_assignment.join_or_create_group_no_results_description'
          )}
        />
      </p>
    </div>
  )
})

import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import type { SlideRubric } from '@breakoutlearning/firebase-repository/models/SlideRubric'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { AssignmentRubricDialog } from '../dialogs/AssignmentRubricDialog'
import { PassFailLabel } from './PassFailLabel'
import { RubricCount } from './RubricCount'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
  InstructorAssignmentCubitProvider,
  useInstructorAssignmentCubit,
} from 'hooks/cubits/instructorAssignment'

export const PassFailRubricChart = observer(function PassFailRubric({
  extraClasses,
  results,
  rubric,
  roomStates,
  section,
  usersMap,
  tabIndex,
}: {
  extraClasses?: string
  results: Array<RoomStateRubricResult & { user: PublicUser }>
  rubric: SlideRubric
  roomStates: RoomState[]
  section: Section
  usersMap: Map<string, PublicUser>
  tabIndex?: number
}) {
  const cubit = useInstructorAssignmentCubit()
  const { t } = useTranslation()
  const tScoped = (s: string) => t(`instructor_assignment.${s}`)
  const { showDialog } = useDialogs()

  const roomStateRubricResults = (() => {
    const roomStateRubricResults = new Map<string, RoomStateRubricResult>()
    for (const result of results) {
      const currentResult = roomStateRubricResults.get(result.data.roomId)
      if (!currentResult) {
        roomStateRubricResults.set(result.data.roomId, result)
      } else {
        if (result.data.score > currentResult.data.score) {
          roomStateRubricResults.set(result.data.roomId, result)
        }
      }
    }
    return roomStateRubricResults
  })()

  const scoreCount = (() => {
    const scoreCount = [0, 0]
    for (const result of roomStateRubricResults.values()) {
      const score = result.data.score
      if (score === 1 || score === 0) {
        scoreCount[score]++
      }
    }
    return scoreCount
  })()

  return (
    <div
      className={classNames(
        'flex cursor-pointer flex-row justify-between gap-[40px]',
        extraClasses
      )}
      tabIndex={tabIndex ?? 0}
    >
      <div
        className="flex flex-col justify-center"
        onClick={() => {
          showDialog(() => (
            <InstructorAssignmentCubitProvider value={cubit}>
              <AssignmentRubricDialog
                rubric={rubric}
                roomStates={roomStates}
                section={section}
                results={results}
                usersMap={usersMap}
              />
            </InstructorAssignmentCubitProvider>
          ))
        }}
      >
        <p className="text-title-medium line-clamp-3">{rubric.data.rubric}</p>
        <p className="text-body-medium line-clamp-3 text-grey-text">
          {rubric.data.rubricDescription}
        </p>
      </div>
      <div className="flex h-[56px] w-[470px] flex-col gap-0.5">
        <div className="flex flex-row justify-between">
          <PassFailLabel
            label={rubric.data.rubricLabel0 || tScoped('pass_fail_0')}
            level={0}
            className="text-body-small"
          />
          <RubricCount
            counts={scoreCount}
            index={0}
            rubric={rubric}
            roomStates={roomStates}
            section={section}
            usersMap={usersMap}
            rubricResults={results}
          />
        </div>
        <div className="flex flex-row justify-between">
          <PassFailLabel
            label={rubric.data.rubricLabel1 || tScoped('pass_fail_1')}
            level={1}
            className="text-body-small"
          />
          <RubricCount
            counts={scoreCount}
            index={1}
            rubric={rubric}
            roomStates={roomStates}
            section={section}
            usersMap={usersMap}
            rubricResults={results}
          />
        </div>
      </div>
    </div>
  )
})

import { RoomStateCubit } from '@breakoutlearning/firebase-repository/cubits/RoomStateCubit'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { ClockIcon } from 'components/icons/Clock'
import { Person } from 'components/icons/Person'
import { useRepository } from 'hooks/auth'
import { Spinner } from 'components/Spinner'
import { useMediaPermissions } from 'hooks/av-test'
import { useCubitBuilder } from 'hooks/cubits'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { PermissionsErrorBanner } from 'pages/av-test/PermissionErrorBanner'
import { lazy, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DemoMeetingEndedPage } from './DemoMeetingEndedPage'
import { NotFoundPage } from 'pages/application/NotFoundPage'

const NewAvTest = lazy(() => import('pages/av-test/NewAvTest'))

type FormValues = {
  firstName: string
  lastName: string
}

export const DemoWaitingRoomPage = observer(function DemoWaitingRoomPage() {
  const repository = useRepository()
  const rootStore = useRootStore()
  const router = rootStore.router
  const breakoutUser = repository.breakoutUser
  const roomId = router.params?.roomId as string | undefined
  const meetingCompleted =
    router.queryParams &&
    'meetingCompleted' in router.queryParams &&
    router.queryParams.meetingCompleted

  const roomStateCubit = useCubitBuilder(
    () => new RoomStateCubit(repository, roomId),
    [repository, roomId]
  )

  if (roomStateCubit.roomState.isLoading) {
    return null
  }

  if (roomStateCubit.roomIsStale || meetingCompleted) {
    return <DemoMeetingEndedPage />
  }

  if (
    !roomId ||
    !roomStateCubit.roomState.userIds.includes(breakoutUser?.user.id || '')
  ) {
    return <NotFoundPage />
  }

  return (
    <DemoWaitingRoomWithAvTest
      roomStateCubit={roomStateCubit}
      roomId={roomId}
    />
  )
})

const DemoWaitingRoomWithAvTest = observer(function DemoWaitingRoomContent({
  roomId,
  roomStateCubit,
}: {
  roomId: string
  roomStateCubit: RoomStateCubit
}) {
  const repository = useRepository()
  const rootStore = useRootStore()
  const { t } = useTranslation()
  const breakoutUser = repository.breakoutUser
  const [showPermissionsBanner, setShowPermissionsBanner] = useState(false)
  const [joining, setJoining] = useState(false)

  const [permissionState] = useMediaPermissions({
    onDenied: () => setShowPermissionsBanner(true),
  })

  const { control, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues:
      breakoutUser && !breakoutUser.isAnonymousDefaultNames
        ? {
            firstName: breakoutUser.firstName,
            lastName: breakoutUser.lastName,
          }
        : {
            firstName: '',
            lastName: '',
          },
  })

  const firstName = useWatch({ control, name: 'firstName' })
  const lastName = useWatch({ control, name: 'lastName' })

  const onJoinMeeting = async (data: {
    firstName: string
    lastName: string
  }) => {
    if (!roomId || !breakoutUser) {
      return
    }

    setJoining(true)

    const user = {
      firstName: data.firstName,
      lastName: data.lastName,
    }

    if (!breakoutUser) {
      return
    }

    await breakoutUser.updateName(user.firstName, user.lastName)

    rootStore.navigateToMeeting(roomId)
  }

  if (permissionState === 'waiting') {
    return (
      <div className="flex h-full w-full flex-col md:items-center md:justify-center">
        <Spinner />
      </div>
    )
  }

  const canJoinMeeting =
    typeof roomStateCubit.roomState.data.activeSlide === 'number' &&
    !roomStateCubit.roomIsStale &&
    firstName.length > 0 &&
    lastName.length > 0

  return (
    <form
      onSubmit={handleSubmit(onJoinMeeting)}
      className="flex h-full w-full flex-col overflow-hidden bg-surface-dim lg:items-center lg:justify-center"
    >
      {showPermissionsBanner && (
        <PermissionsErrorBanner
          onDismiss={() => setShowPermissionsBanner(false)}
        />
      )}
      <div className="m-auto block h-full  w-full grid-cols-2 gap-3 space-y-4 overflow-y-auto p-7 lg:grid lg:space-y-0">
        <div className="flex flex-col justify-between gap-5 rounded-3xl bg-surface-bright px-14 py-10">
          <div>
            <div>
              <img src="/assets/images/logo_large_trimmed.png" width={160} />
            </div>
          </div>
          <div>
            <div className="text-headline-large text-on-surface">
              {t('demo.welcome_text')}
            </div>

            <div className="text-body-large mt-2 text-on-surface">
              {t('demo.welcome_description')}
            </div>
            <div className="mt-3 space-y-2">
              <Controller
                control={control}
                name="firstName"
                rules={{
                  required: t('demo.first_required'),
                }}
                render={({ field, fieldState }) => (
                  <BreakoutTextInput
                    {...field}
                    className="w-full"
                    kind="secondary"
                    error={fieldState.error}
                    required
                    placeholder={t('demo.first_name')}
                  />
                )}
              />
              <Controller
                control={control}
                name="lastName"
                rules={{
                  required: t('demo.last_required'),
                }}
                render={({ field, fieldState }) => (
                  <BreakoutTextInput
                    {...field}
                    className="w-full"
                    kind="secondary"
                    error={fieldState.error}
                    required
                    placeholder={t('demo.last_name')}
                  />
                )}
              />
            </div>
            <div className="mt-3 flex gap-2">
              <div className="text-body-medium flex w-full flex-wrap items-center justify-center rounded-2xl bg-surface px-2 py-4 text-on-surface">
                <ClockIcon
                  size={20}
                  className="mr-1 stroke-fixed-accent-color"
                />{' '}
                {t('demo.start_time')}
                <strong className="text-label-medium pl-1">
                  {roomStateCubit.formattedDate}
                </strong>
              </div>
              <div className="text-body-medium flex w-full flex-wrap items-center justify-center rounded-2xl bg-surface px-2 py-4 text-on-surface">
                <Person size={20} className="mr-1 stroke-fixed-accent-color" />{' '}
                {t('demo.meeting_with')}
                <strong className="text-label-medium pl-1">
                  {roomStateCubit.groupLeaderUser.data.firstName}{' '}
                  {roomStateCubit.groupLeaderUser.data.lastName}
                </strong>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="text-body-large flex-grow pr-4 text-on-surface">
              {t('demo.waiting_for_host')}
            </div>
            <div className="flex-shrink-0">
              <BreakoutButton
                data-testid="join-meeting-button"
                disabled={!canJoinMeeting || joining}
                loading={joining}
                kind="primary"
                size="large"
                type="submit"
              >
                {t('demo.join_meeting')}
              </BreakoutButton>
            </div>
          </div>
        </div>
        <div className="h-full rounded-3xl bg-surface-bright p-10">
          <NewAvTest permissionState={permissionState} />
        </div>
      </div>
    </form>
  )
})

import type { NavigationCubit } from '@breakoutlearning/firebase-repository/cubits/NavigationCubit'
import { createContext, useContext } from 'react'

const NavigationCubitContext = createContext<NavigationCubit | undefined>(
  undefined
)

export const NavigationCubitProvider = NavigationCubitContext.Provider

export function useNavigationCubit() {
  const cubit = useContext(NavigationCubitContext)

  if (cubit === undefined) {
    throw new Error('useNavigationCubit must be used within a CubitProvider')
  }

  return cubit
}

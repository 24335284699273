import { memo, useRef, useState } from 'react'
import {
  arrow,
  shift,
  useFloating,
  offset,
  flip,
  FloatingPortal,
} from '@floating-ui/react'
import type { EventDisplayProps } from './types'
import { useBreakoutUser } from 'hooks/profile'

export const EventDisplay = memo(function EventDisplay({
  event,
  eventNumber,
  eventMatch,
}: EventDisplayProps) {
  const [isHovered, setIsHovered] = useState(false)
  const arrowRef = useRef(null)
  const { isAdmin } = useBreakoutUser()

  const { refs, floatingStyles } = useFloating({
    placement: 'top',
    middleware: [
      offset(5),
      flip({
        fallbackPlacements: ['bottom', 'left', 'right'],
        padding: 10,
      }),
      shift({
        padding: 10,
        crossAxis: true,
      }),
      arrow({
        element: arrowRef,
        padding: 16,
      }),
    ],
  })

  const formatTime = (date: Date) => {
    return date.toLocaleTimeString('en-US', {
      hour12: true,
      hour: 'numeric',
      minute: '2-digit',
    })
  }

  return (
    <span className="relative ml-1 inline-block">
      <span
        ref={refs.setReference}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="inline-flex h-[15px] w-[15px] cursor-pointer items-center justify-center rounded-full bg-surface-dim pt-[1px] text-[8px] leading-none text-on-surface-var hover:bg-fixed-accent-color hover:text-on-surface"
      >
        {eventNumber}
      </span>

      {isHovered && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="z-[999999] w-[250px] rounded-2xl border border-outline-variant bg-core-tertiary px-6 py-4 shadow-lg sm:w-[250px] md:w-[300px] lg:w-[400px]"
          >
            <div className="text-body-medium">{`"${event.transcript}"`}</div>
            <div className="mt-1 flex items-end justify-between">
              <div className="inline-flex h-[15px] w-[15px] cursor-pointer items-center justify-center rounded-full bg-surface-dim pt-[1px] text-[8px] leading-none text-on-surface-var">
                {eventNumber}
              </div>
              {typeof eventMatch === 'number' && isAdmin && (
                <div className="text-body-small pb-0.5 text-right text-on-surface-var">
                  Match: {eventMatch.toFixed(2)}
                </div>
              )}
              {event.eventTime instanceof Date && (
                <div className="text-body-small pb-0.5 text-right text-on-surface-var">
                  {formatTime(event.eventTime)}
                </div>
              )}
            </div>
          </div>
        </FloatingPortal>
      )}
    </span>
  )
})

import type { AssignmentGroupData } from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import type { SectionUserData } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
import type { FirebaseRepository } from '@breakoutlearning/firebase-repository/models/FirebaseRepository'
import { RoomStateStatus } from '@breakoutlearning/firebase-repository/models/RoomState'
import { RoomStateEngagement } from '@breakoutlearning/firebase-repository/models/RoomStateEngagement'
import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
import { type SectionPassCoupon } from '@breakoutlearning/firebase-repository/models/SectionPassCoupon'
import type { SlideDeck } from '@breakoutlearning/firebase-repository/models/SlideDeck'
import type { SlideRubric } from '@breakoutlearning/firebase-repository/models/SlideRubric'
import { createArrayCsvStringifier } from 'csv-writer'
import { formatFilename } from './strings'

export function downloadAssignmentGroupDataAsCSV(
  repository: FirebaseRepository,
  fileName: string,
  groupDataList: AssignmentGroupData[]
) {
  const res = generateAssignmentGroupDataAsCSV(repository, groupDataList)

  // convert to Blob
  const blob = new Blob([res], { type: 'text/csv' })

  // prompt download in browser through hidden anchor element
  const anchor = document.createElement('a')
  anchor.href = URL.createObjectURL(blob)
  anchor.download = fileName
  anchor.click()
}

function generateAssignmentGroupDataAsCSV(
  repository: FirebaseRepository,
  groupDataList: AssignmentGroupData[]
) {
  if (groupDataList.length === 0) {
    throw new Error('No group data')
  }

  // questions as maps
  const groupDataMap = assignmentGroupDataToSimpleMaps(
    repository,
    groupDataList
  )

  // define headers and make first entry in list
  const headerRow = getKeysFromCSVMapList(groupDataMap)

  // make sure every row has all keys
  for (const groupData of groupDataMap) {
    for (const key of headerRow) {
      if (!(key in groupData)) {
        groupData[key] = ''
      }
      if (groupData[key] === null) {
        groupData[key] = ''
      }
      if (groupData[key] === undefined) {
        groupData[key] = ''
      }
    }
  }

  // starting row is headers
  const csvConvertible = []
  for (const groupData of groupDataMap) {
    const values = Object.values(groupData)
    const valuesStringified = values.map((value) => {
      if (typeof value === 'boolean') {
        return value ? 'true' : 'false'
      }

      if (typeof value === 'number') {
        return value.toString()
      }

      if (value === undefined) {
        return ''
      }

      if (value === null) {
        return ''
      }
      return value
    })
    csvConvertible.push(valuesStringified)
  }

  const csvStringifier = createArrayCsvStringifier({
    header: headerRow,
  })

  const csv = [
    csvStringifier.getHeaderString(),
    csvStringifier.stringifyRecords(csvConvertible),
  ].join('\n')

  // convert to csv string
  return csv
}

function getKeysFromCSVMapList(csvMapList: Record<string, unknown>[]) {
  const keys = new Set<string>()
  for (const csvMap of csvMapList) {
    for (const key in csvMap) {
      keys.add(key)
    }
  }
  return Array.from(keys)
}

function assignmentGroupDataToSimpleMaps(
  repository: FirebaseRepository,
  groupDataList: AssignmentGroupData[]
) {
  const csvMapList: Record<string, string | number | undefined | boolean>[] = []

  // place all rubrics in a map with an incrementing index
  const rubricMap = new Map<SlideRubric, number>()
  // place all sorting questions in a map with an incrementing index
  const sortingQuestionMap = new Map<string, number>()
  for (const groupData of groupDataList) {
    // loop over all rubricResults data for all users
    // put the SlideRubric in map with incrementing key
    for (const rubricData of groupData.rubricResults.values()) {
      for (const rubric of rubricData.keys()) {
        // if rubric is not in map, add it with the next index
        if (!rubricMap.has(rubric)) {
          rubricMap.set(rubric, rubricMap.size)
        }
      }
    }
    // loop over all sortingQuestions data for all users
    // put the SlideQuestion in map with incrementing key
    for (const sortingQuestion of groupData.sortingQuestions.values()) {
      // if sortingQuestion is not in map, add it with the next index
      if (!sortingQuestionMap.has(sortingQuestion.id)) {
        sortingQuestionMap.set(sortingQuestion.id, sortingQuestionMap.size)
      }
    }
  }

  for (const groupData of groupDataList) {
    for (const userId of groupData.groupMembers) {
      if (groupData.roomState?.data.absentUserIds?.includes(userId)) continue

      const user = repository.userStore.getUser(userId)
      const isGroupLeader =
        groupData.roomState?.data.groupLeaderUserIds.includes(userId)
      const engagementData =
        groupData.engagementData.get(userId) ||
        RoomStateEngagement.empty(repository)

      const completedAt =
        groupData.roomState &&
        groupData.roomStateStatus === RoomStateStatus.completed
          ? groupData.roomState.data.activeSlideChangedAt
          : null

      const csvMap: Record<string, string | number | undefined | boolean> = {
        groupName: groupData.groupName,
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        quizResults: groupData.quizScore.get(userId),
        groupLeader: isGroupLeader,
        talkTime: engagementData.data.userTalkTime,
        totalGroupTalkTime: engagementData.data.totalTalkTime,
        cameraOffTime: engagementData.data.cameraOffTime,
        durationPresentDuringSession: engagementData.data.durationPresent,
        completedAt: completedAt?.toISOString() || '',
        totalSessionTime: engagementData.data.totalTime,
      }

      const sortingQuizResults =
        groupData.sortingQuizScores.get(userId) || new Map<string, number>()

      for (const value of groupData.sortingQuestions.values()) {
        // loop over sortingQuestionMap and get the index for the question
        const questionIndex = sortingQuestionMap.get(value.id)

        const question = value

        if (question.data.slideId) {
          csvMap[`sorting_${questionIndex}`] = sortingQuizResults.get(
            question.id
          )
        }

        if (question.data.groupSlideId) {
          csvMap[`group_sorting_${questionIndex}`] =
            groupData.groupSortingAnswerScores.get(question.id)
        }
      }

      // loop over rubric results and add to csvMap with the key rubric_n
      const rubricResults =
        groupData.rubricResults.get(userId) ||
        new Map<SlideRubric, RoomStateRubricResult[]>()
      const results = Array.from(rubricResults.values()).flat()

      for (const rubricResult of results) {
        // loop over rubricMap and find the rubric that matches the rubricId in
        // results add the rubric index to the csvMap
        const rubric = Array.from(rubricMap.keys()).find(
          (element) => element.id === rubricResult.rubricId
        )
        if (rubric) {
          const rubricIndex = rubricMap.get(rubric)
          csvMap[`rubric_${rubricIndex}_score`] = rubricResult.data.score
          csvMap[`rubric_${rubricIndex}_justification`] =
            rubricResult.data.justification
        }
      }

      csvMapList.push(csvMap)
    }
  }

  if (groupDataList.length > 0 && groupDataList[0].sortingQuestions.size > 0) {
    // add 2 empty rows
    csvMapList.push({})
    csvMapList.push({})

    // now add legend
    for (const [key, value] of groupDataList[0].sortingQuestions.entries()) {
      const questionIndex = sortingQuestionMap.get(key)
      csvMapList.push({
        groupName: `sorting_${questionIndex}`,
        firstName: value.data.question,
      })
    }
  }

  if (rubricMap.size > 0) {
    // add 2 empty rows
    csvMapList.push({})
    csvMapList.push({})

    // loop over groupData.rubricResults keys and add the legend for rubrics
    for (const [rubric, rubricIndex] of rubricMap.entries()) {
      csvMapList.push({
        groupName: `rubric_${rubricIndex}`,
        firstName: rubric.data.rubric,
      })
    }
  }

  return csvMapList
}

function sectionUserDataToSimpleMaps(
  slideDeckMap: Map<string, SlideDeck>,
  studentData: Map<string, SectionUserData>
) {
  const csvMapList: Array<Record<string, string | number | undefined | null>> =
    []
  for (const studentDataRecord of studentData.entries()) {
    const user = studentDataRecord[1].user
    for (const assignment of studentDataRecord[1].assignment.entries()) {
      const roomState = assignment[0]
      const { engagementData, quizScore, rubricResults } = studentDataRecord[1]
      const engagementForRoom = engagementData.get(roomState)?.data
      const csvMap: Record<string, string | number | undefined | null> = {
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        assignment:
          slideDeckMap.get(assignment[1].data.slideDeckId)?.data
            .slideDeckName ?? 'Unknown',
        quizResults: quizScore.get(roomState),
        talkTime: engagementForRoom?.userTalkTime,
        totalGroupTalkTime: engagementForRoom?.totalTalkTime,
        cameraOffTime: engagementForRoom?.cameraOffTime,
        durationPresentDuringSession: engagementForRoom?.durationPresent,
        totalSessionTime: engagementForRoom?.totalTime,
      }

      // loop over rubric results and add to csvMap with the key rubric_n
      const results = Array.from(
        rubricResults.get(roomState)?.values() || []
      ).flat()
      results.forEach((rubricResult, rubricIndex) => {
        csvMap[`rubric_${rubricIndex}`] = rubricResult.data.score
      })
      csvMapList.push(csvMap)
    }
  }
  return csvMapList
}

function sectionPassCouponDataToSimpleMaps(
  sectionPassCoupons: SectionPassCoupon[],
  consumed: boolean
) {
  const csvMapList: Array<Record<string, string | number | undefined | null>> =
    []
  for (const sectionPassCoupon of sectionPassCoupons) {
    if (consumed !== sectionPassCoupon.consumed) continue //safety
    const csvMap: Record<string, string | number | undefined | null> = consumed
      ? {
          couponCode: sectionPassCoupon.id,
          consumedBy: sectionPassCoupon.data.userId || '',
          consumedAt: sectionPassCoupon.data.updatedAt?.toISOString() || '',
        }
      : {
          couponCode: sectionPassCoupon.id,
          createdAt: sectionPassCoupon.data.updatedAt?.toISOString() || '',
        }

    csvMapList.push(csvMap)
  }
  return csvMapList
}

export function downloadSectionUserDataAsCSV(
  slideDeckMap: Map<string, SlideDeck>,
  studentData: Map<string, SectionUserData>,
  fileName: string
) {
  if (studentData.size === 0) {
    throw Error('No student data')
  }

  // questions as maps
  const studentDataMap = sectionUserDataToSimpleMaps(slideDeckMap, studentData)

  // define headers and make first entry in list
  const headerRow = getKeysFromCSVMapList(studentDataMap)

  // make sure every row has all keys
  for (const studentDataRecord of studentDataMap) {
    for (const key of headerRow) {
      if (!(key in studentDataRecord)) {
        studentDataRecord[key] = null
      }
    }
  }

  // starting row is headers
  type CSVValue = string | number | undefined | null
  const csvConvertible: CSVValue[][] = [headerRow]
  for (const studentDataRecord of studentDataMap) {
    csvConvertible.push(Object.values(studentDataRecord))
  }

  const csvStringifier = createArrayCsvStringifier({
    header: headerRow,
  })

  const csv = [
    //csvStringifier.getHeaderString(),
    csvStringifier.stringifyRecords(csvConvertible),
  ].join('\n')

  // convert to Blob
  const blob = new Blob([csv], { type: 'text/csv' })

  const anchor = document.createElement('a')
  anchor.href = URL.createObjectURL(blob)
  anchor.download = fileName
  anchor.click()
}

export function downloadSectionPassCouponsAsCSV({
  sectionPassCoupons,
  sectionName,
  className,
  consumed,
}: {
  sectionPassCoupons: SectionPassCoupon[]
  sectionName: string
  className: string
  consumed: boolean
}) {
  if (sectionPassCoupons.length === 0) {
    throw Error('No coupon data')
  }

  // questions as maps
  const studentDataMap = sectionPassCouponDataToSimpleMaps(
    sectionPassCoupons,
    consumed
  )

  // define headers and make first entry in list
  const headerRow = getKeysFromCSVMapList(studentDataMap)

  // make sure every row has all keys
  for (const studentDataRecord of studentDataMap) {
    for (const key of headerRow) {
      if (!(key in studentDataRecord)) {
        studentDataRecord[key] = null
      }
    }
  }

  // starting row is headers
  type CSVValue = string | number | undefined | null
  const csvConvertible: CSVValue[][] = [headerRow]
  for (const studentDataRecord of studentDataMap) {
    csvConvertible.push(Object.values(studentDataRecord))
  }

  const csvStringifier = createArrayCsvStringifier({
    header: headerRow,
  })

  const csv = [
    //csvStringifier.getHeaderString(),
    csvStringifier.stringifyRecords(csvConvertible),
  ].join('\n')

  // convert to Blob
  const blob = new Blob([csv], { type: 'text/csv' })

  const fileName = formatFilename({
    nameParts: [
      sectionName,
      className,
      consumed ? 'consumed' : 'active',
      'coupons',
    ],
    extension: 'csv',
  })

  const anchor = document.createElement('a')
  anchor.href = URL.createObjectURL(blob)
  anchor.download = fileName
  anchor.click()
}

// String generateAssignmentGroupDataAsCSV(
//   List<AssignmentGroupData> groupDataList,
// ) {
//   if (groupDataList.isEmpty) {
//     throw Exception('No group data');
//   }

//   // questions as maps
//   final groupDataMap = assignmentGroupDataToSimpleMaps(groupDataList);

//   // define headers and make first entry in list
//   final headerRow = getKeysFromCSVMapList(groupDataMap);

//   // make sure every row has all keys
//   for (final groupData in groupDataMap) {
//     for (final key in headerRow) {
//       if (!groupData.containsKey(key)) {
//         groupData[key] = '';
//       }
//       if (groupData[key] == null) {
//         groupData[key] = '';
//       }
//     }
//   }

//   // starting row is headers
//   final csvConvertible = <List<dynamic>>[headerRow];
//   for (final groupData in groupDataMap) {
//     csvConvertible.add(groupData.values.toList());
//   }

//   // convert to csv string
//   final res = CsvCodec().encoder.convert(csvConvertible);

//   return res;
// }

// void downloadAssignmentGroupDataAsCSV(
//   List<AssignmentGroupData> groupDataList,
//   String? fileName,
// ) {
//   final res = generateAssignmentGroupDataAsCSV(groupDataList);

//   // convert to Blob
//   final blob = html.Blob([res], 'text/csv');

//   // prompt download in browser through hidden anchor element
//   html.AnchorElement()
//     ..href = html.Url.createObjectUrl(blob)
//     ..download = fileName != null ? '$fileName.csv' : 'group_data.csv'
//     ..click();
// }

// List<Map<String, dynamic>> assignmentGroupDataToSimpleMaps(
//   List<AssignmentGroupData> groupDataList,
// ) {
//   final csvMapList = <Map<String, dynamic>>[];

//   // place all rubrics in a map with an incrementing index
//   final rubricMap = <SlideRubric, int>{};
//   // place all sorting questions in a map with an incrementing index
//   final sortingQuestionMap = <String, int>{};
//   for (final groupData in groupDataList) {
//     // loop over all rubricResults data for all users
//     // put the SlideRubric in map with incrementing key
//     for (final rubricData in groupData.rubricResults.values) {
//       for (final rubric in rubricData.keys) {
//         // if rubric is not in map, add it with the next index
//         if (!rubricMap.containsKey(rubric)) {
//           rubricMap[rubric] = rubricMap.length;
//         }
//       }
//     }
//     // loop over all sortingQuestions data for all users
//     // put the SlideQuestion in map with incrementing key
//     for (final sortingQuestion in groupData.sortingQuestions.values) {
//       // if sortingQuestion is not in map, add it with the next index
//       if (!sortingQuestionMap.containsKey(sortingQuestion.id)) {
//         sortingQuestionMap[sortingQuestion.id] = sortingQuestionMap.length;
//       }
//     }
//   }

//   for (final groupData in groupDataList) {
//     for (final user in groupData.groupMembers) {
//       final isGroupLeader = groupData.roomState.isGroupLeader(user.id);
//       final csvMap = <String, Object?>{
//         'groupName': groupData.groupName,
//         'firstName': user.firstName,
//         'lastName': user.lastName,
//         'quizResults': groupData.quizScore[user],
//         'groupLeader': isGroupLeader,
//         'talkTime': groupData.engagementData[user]?.userTalkTime,
//         'totalGroupTalkTime': groupData.engagementData[user]?.totalTalkTime,
//         'cameraOffTime': groupData.engagementData[user]?.cameraOffTime,
//         'durationPresentDuringSession':
//             groupData.engagementData[user]?.durationPresent,
//         'totalSessionTime': groupData.engagementData[user]?.totalTime,
//       };

//       final sortingQuizResults =
//           groupData.sortingQuizScores[user] ?? <String, int>{};

//       for (final questionEntry in groupData.sortingQuestions.entries) {
//         // loop over sortingQuestionMap and get the index for the question
//         final questionIndex = sortingQuestionMap[questionEntry.value.id];
//         final question = questionEntry.value;
//         if (question.hasSlideId) {
//           csvMap['sorting_$questionIndex'] = sortingQuizResults[question.id];
//         }
//         if (question.hasGroupSlideId) {
//           csvMap['group_sorting_$questionIndex'] =
//               groupData.groupSortingAnswerScores[question.id];
//         }
//       }

//       // loop over rubric results and add to csvMap with the key rubric_n
//       final results = groupData.rubricResults[user]?.values
//           .expand((element) => element)
//           .toList();
//       for (final rubricResult in results ?? <RoomStateRubricResult>[]) {
//         // loop over rubricMap and find the rubric that matches the rubricId in
//         // results add the rubric index to the csvMap
//         final rubric = rubricMap.keys
//             .firstWhereOrNull((element) => element.id == rubricResult.rubricId);
//         if (rubric != null) {
//           final rubricIndex = rubricMap[rubric];
//           csvMap['rubric_${rubricIndex}_score'] = rubricResult.score.index;
//           csvMap['rubric_${rubricIndex}_justification'] =
//               rubricResult.justification;
//         }
//       }
//       csvMapList.add(csvMap);
//     }
//   }

//   if (groupDataList.isNotEmpty &&
//       groupDataList.first.sortingQuestions.isNotEmpty) {
//     // add 2 empty rows
//     csvMapList
//       ..add(<String, dynamic>{})
//       ..add(<String, dynamic>{});

//     // now add legend

//     for (final sortingQuestion
//         in groupDataList.first.sortingQuestions.entries) {
//       final questionIndex = sortingQuestionMap[sortingQuestion.key];
//       csvMapList.add(<String, dynamic>{
//         'groupName': 'sorting_$questionIndex',
//         'firstName': sortingQuestion.value.question,
//       });
//     }
//   }

//   if (rubricMap.isNotEmpty) {
//     // add 2 empty rows
//     csvMapList
//       ..add(<String, dynamic>{})
//       ..add(<String, dynamic>{});

//     // loop over groupData.rubricResults keys and add the legend for rubrics
//     for (final rubricData in rubricMap.entries) {
//       csvMapList.add(<String, dynamic>{
//         'groupName': 'rubric_${rubricData.value}',
//         'firstName': rubricData.key.rubric,
//       });
//     }
//   }

//   return csvMapList;
// }

import { Dialog } from 'components/dialogs/Dialog'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PurchaseDialogBody } from './PurchaseDialogBody'
import { EnrollDialogBody } from './EnrollDialogBody'
import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { useBreakoutUser } from 'hooks/profile'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useRootStore } from 'hooks/rootStore'
import { Spinner } from 'components/Spinner'
import { useTranslation } from 'react-i18next'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { useDialogs } from 'hooks/dialogs'
import { toast } from 'react-hot-toast'

function ProcessingView() {
  const { t } = useTranslation()
  return (
    <>
      <div className="flex h-full flex-1 flex-col items-center justify-center gap-5 text-center">
        <div className="text-title-large">
          {t('student_assignment.processing_payment')}
        </div>
        <Spinner />
      </div>
    </>
  )
}

export const PurchaseOrEnrollDialog = observer(function PurchaseOrEnrollDialog({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const rootStore = useRootStore()
  const user = useBreakoutUser()
  const [showProcessing, setShowProcessing] = useState(false)

  useEffect(() => {
    cubit.onDialogMountUnmount('mount', 'enroll')
    return () => {
      cubit.onDialogMountUnmount('unmount', 'enroll')
    }
  })

  const queryParams = rootStore.router.queryParams
  const isPostSuccess = queryParams?.payment === 'success'

  const slideDeckPrice = cubit.slideDeck.data.slideDeckPrice
  const tokensRequired = slideDeckPrice - user.availableTokens

  const DialogContents = useMemo(() => {
    if (showProcessing) return ProcessingView
    if (tokensRequired > 0 || cubit.section.usesSectionPass)
      return PurchaseDialogBody
    return EnrollDialogBody
  }, [cubit.section.usesSectionPass, showProcessing, tokensRequired])

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined
    // when we have a successful payment, but the tokens are not yet updated
    // show a processing view, but time it out after 10 seconds
    if (isPostSuccess && tokensRequired > 0) {
      setShowProcessing(true)
      timeout = setTimeout(() => {
        setShowProcessing(false)
      }, 10000)
    } else {
      setShowProcessing(false)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [isPostSuccess, tokensRequired])

  return (
    <Dialog
      size="md"
      innerClassName="flex flex-col items-center justify-between gap-2"
      ignoreBackdropClick
      ignoreEscapeKey
    >
      <DialogCloseButton />
      <DialogContents cubit={cubit} />
    </Dialog>
  )
})

export const RedeemCouponDialog = observer(function RedeemCouponDialog({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const [formError, setFormError] = useState<string>()
  const [isRedeeming, setIsRedeeming] = useState(false)
  const { popDialog } = useDialogs()

  useEffect(() => {
    cubit.onDialogMountUnmount('mount', 'enroll')
    return () => cubit.onDialogMountUnmount('unmount', 'enroll')
  })

  const redeemCoupon = useCallback(
    async (couponCode: string) => {
      setFormError(undefined)
      if (!couponCode) return setFormError('coupon code required')
      setIsRedeeming(true)
      let redeemed = false
      try {
        const result = await cubit.redeemSectionPassCoupon(couponCode)
        if (typeof result === 'string') setFormError(result)
        redeemed = result === true
        if (!redeemed && typeof result !== 'string')
          setFormError('invalid coupon code')
      } catch (e) {
        setFormError('invalid coupon code')
      } finally {
        setIsRedeeming(false)
        if (redeemed) {
          toast.success('Coupon Redeemed')
          popDialog()
        }
      }
    },
    [cubit, popDialog]
  )

  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Dialog
      size="md"
      innerClassName="flex flex-col items-center justify-between gap-2"
      ignoreBackdropClick
      ignoreEscapeKey
    >
      <DialogCloseButton />
      <h1 className="text-headline-large mb-4px text-center">
        Redeem Coupon Code
      </h1>
      <div className="flex flex-1 items-center justify-center">
        <p className="text-body-medium text-center">
          Redeeming a valid coupon code grants you a section pass which unlocks
          access to this assignment as well as all future assignments in the
          section.
        </p>
      </div>
      <div className="flex w-full flex-1 flex-col justify-end gap-2">
        <BreakoutTextInput
          kind="secondary"
          ref={inputRef}
          disabled={isRedeeming}
          placeholder="Enter Coupon Code"
          error={
            formError ? { message: formError, type: 'validate' } : undefined
          }
          onChange={() => setFormError(undefined)}
        />
        <BreakoutButton
          fullWidth
          size="large"
          loading={isRedeeming}
          disabled={isRedeeming}
          onClick={() => redeemCoupon(inputRef.current?.value || '')}
        >
          Apply
        </BreakoutButton>
      </div>
    </Dialog>
  )
})

export const SelectCouponOrStripeDialog = observer(
  function SelectCouponOrStripeDialog({
    cubit,
    showStripeDialog,
    showCouponDialog,
  }: {
    cubit: StudentAssignmentCubit
    showStripeDialog: VoidFunction
    showCouponDialog: VoidFunction
  }) {
    const { popDialog } = useDialogs()
    useEffect(() => {
      cubit.onDialogMountUnmount('mount', 'enroll')
      return () => cubit.onDialogMountUnmount('unmount', 'enroll')
    })
    return (
      <Dialog
        size="sm"
        innerClassName="flex flex-col items-center justify-evenly gap-2"
        ignoreBackdropClick
        ignoreEscapeKey
      >
        <DialogCloseButton />
        <h1 className="text-headline-large mb-4px text-center">
          Select Purchase Method
        </h1>
        <div className="flex flex-col gap-3">
          <BreakoutButton
            fullWidth
            size="medium"
            onClick={() => {
              popDialog()
              setTimeout(showStripeDialog, 0)
            }}
          >
            Pay With Stripe
          </BreakoutButton>
          <BreakoutButton
            fullWidth
            size="medium"
            onClick={() => {
              popDialog()
              setTimeout(showCouponDialog, 0)
            }}
          >
            Redeem Coupon Code
          </BreakoutButton>
        </div>
      </Dialog>
    )
  }
)

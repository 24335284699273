import { InstructorLibraryWithTeachingPlansCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorLibraryWithTeachingPlansCubit'
import type { Catalog } from '@breakoutlearning/firebase-repository/models/Catalog'
import type { SlideDeck } from '@breakoutlearning/firebase-repository/models/SlideDeck'
import type { TeachingPlan } from '@breakoutlearning/firebase-repository/models/TeachingPlan'
import { groupSlideDecksByTypeAndSortByUpdateDate } from '@breakoutlearning/firebase-repository/util'
import { Breadcrumb } from 'components/Breadcrumb'
import { ImpersonationInfo } from 'components/ImpersonationInfo'
import { Spinner } from 'components/Spinner'
import { FloatingIconActionButton } from 'components/breakout/FloatingIconActionButton'
import { BreakoutCard } from 'components/design-system/BreakoutCard'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { AdminPanelSettingsIcon } from 'components/icons/AdminPanelSettings'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { GraduationCapIcon } from 'components/icons/GraduationCap'
import { Search } from 'components/icons/Search'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { SlideDeckGroupDialog } from 'pages/admin/library/SlideDeckGroupDialog'
import { Actions, Contents, Header, MainPane } from 'pages/layout/TwoColumn'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RevampedSlideDeckCard } from '../shared/RevampedSlideDeckCard'
import { TeachingPlanCard } from '../shared/TeachingPlanCard'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { TeachingPlansSearchResultsEmptyView } from '../shared/TeachingPlansSearchResultsEmptyView'
import {
  InstructorLibraryWithTeachingPlansCubitProvider,
  useInstructorLibraryWithTeachingPlansCubit,
} from 'hooks/cubits/instructorLibraryWithTeachingPlans'
import { PageTitle } from 'components/PageTitle'

const CatalogSelect = observer(function CatalogSelect() {
  const cubit = useInstructorLibraryWithTeachingPlansCubit()
  const { t } = useTranslation()
  const rootStore = useRootStore()
  const queryParams = rootStore.router.queryParams
  const sectionId = queryParams?.sectionId?.toString()
  const catalogId = cubit.currentCatalogId
  const options = useMemo(() => {
    return cubit.catalogs.models
      .map((catalog) => ({
        value: catalog.id,
        label: catalog.data.catalogName,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [cubit.catalogs.models])
  const hasMoreThanOneCatalog = cubit.catalogs.models.length > 1

  if (!hasMoreThanOneCatalog) return null

  return (
    <BreakoutSelect
      options={options}
      value={catalogId}
      kind="secondary"
      aria-label={t('instructor_library.select_catalog')}
      onChange={(value) => {
        cubit.setSelectedCatalogId(value)
        rootStore.navigateTo('instructorLibrary', undefined, {
          ...queryParams,
          catalogId: value,
          sectionId: sectionId,
          // never copy over teaching plan id - teaching plans are catalog-dependent
          // so when you move from catalog to catalog, we need to reset the teaching plan id
          teachingPlanId: undefined,
        })
      }}
      placeholder={t('instructor_library.select_catalog')}
      inputClassName="lg:min-w-[220px]"
    />
  )
})

const SearchBox = observer(function SearchBox() {
  const { t } = useTranslation()
  const cubit = useInstructorLibraryWithTeachingPlansCubit()
  return (
    <BreakoutTextInput
      type="text"
      clearIcon
      onChange={(e) => {
        const value = e.currentTarget.value
        cubit.setSearchFilter(value)
      }}
      value={cubit.searchFilter}
      LeadingIcon={Search}
      iconSize={15}
      iconClassName="stroke-on-surface-disabled ml-0.5"
      kind="secondary"
      placeholder={t('instructor_library.search_experiences')}
      className="text-on-surface lg:w-[220px]"
    />
  )
})

export function InstructorLibraryPageWithTeachingPlans() {
  const repository = useRepository()
  const params = useRootStore().router.queryParams
  const catalogId = params?.catalogId?.toString()
  const sectionId = params?.sectionId?.toString()

  const cubit = useCubitBuilder(
    () => new InstructorLibraryWithTeachingPlansCubit(repository, catalogId),
    [repository]
  )
  const { t } = useTranslation()
  const rootStore = useRootStore()

  const [adminView, setAdminView] = useState(false)
  const isInternal = cubit.repository.breakoutUser?.isInternal || false

  return (
    <InstructorLibraryWithTeachingPlansCubitProvider value={cubit}>
      <MainPane className="mt-[9px]">
        <PageTitle title={t('instructor_library.page_title')} />
        <Header>
          <Breadcrumb
            onClick={
              sectionId
                ? () => {
                    return rootStore.navigateTo('instructorClass', {
                      id: sectionId,
                    })
                  }
                : undefined
            }
          >
            {t('instructor_library.headline')}
          </Breadcrumb>
          <ImpersonationInfo />
          <Actions className="!gap-5">
            {!adminView && <CatalogSelect />}
            <SearchBox />
            {isInternal && (
              <FloatingIconActionButton
                aria-label={t('buttons.admin_actions')}
                kind="secondary"
                className="z-10 flex justify-end"
                menuBottom={true}
                Icon={AdminPanelSettingsIcon}
                actions={[
                  {
                    text: t(
                      `instructor_library.${adminView ? 'instructor' : 'admin'}_view`
                    ),
                    Icon: adminView
                      ? GraduationCapIcon
                      : AdminPanelSettingsIcon,
                    onClick: () => setAdminView(!adminView),
                  },
                ]}
              />
            )}
          </Actions>
        </Header>
        <Contents className="h-full w-full overflow-auto pr-1">
          <PageContent cubit={cubit} adminView={adminView} />
        </Contents>
      </MainPane>
    </InstructorLibraryWithTeachingPlansCubitProvider>
  )
}

const PageContent = observer(function PageContent({
  cubit,
  adminView,
}: {
  cubit: InstructorLibraryWithTeachingPlansCubit
  adminView: boolean
}) {
  const rootStore = useRootStore()
  const params = rootStore.router.queryParams
  const catalogId =
    params?.catalogId?.toString() || cubit.catalogs.models[0]?.id
  const catalog = cubit.catalogs.models.find((c) => c.id === catalogId)

  if (cubit.isLoading) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (adminView) {
    return <AdminCasesView cubit={cubit} />
  }

  if (!catalog) return null

  return (
    <div className="flex min-h-full flex-1 flex-col gap-5">
      <CatalogView key={catalog.id} catalog={catalog} cubit={cubit} />
    </div>
  )
})

const AdminCasesView = observer(function AdminCasesView({
  cubit,
}: {
  cubit: InstructorLibraryWithTeachingPlansCubit
}) {
  const { t } = useTranslation()
  const { showDialog } = useDialogs()
  const slideDeckGroupsByType = useMemo(
    () =>
      groupSlideDecksByTypeAndSortByUpdateDate(cubit.adminSlideDecks.models),
    [cubit.adminSlideDecks.models]
  )
  const store = useRootStore()

  return (
    <BreakoutCard.Grid>
      {Object.entries(slideDeckGroupsByType).map(([typeId, group]) => {
        const { imageUrl, name, teaser } = group
        return (
          <BreakoutCard
            key={typeId}
            onClick={() => {
              showDialog(() => (
                <SlideDeckGroupDialog
                  slideDeckGroup={group}
                  catalogs={cubit.catalogs.models}
                  onSlideDeckClick={(slideDeckId) => {
                    store.navigateTo(
                      'instructorSlideDeck',
                      { slideDeckId },
                      store.router.queryParams
                    )
                  }}
                />
              ))
            }}
          >
            <BreakoutCard.Body className="gap-2">
              <div className="flex rounded-lg">
                <div className="min-h-[35px] min-w-[35px]">
                  <img
                    src={imageUrl || undefined}
                    className="inline max-h-[35px] max-w-[35px] object-cover"
                  />
                </div>
              </div>
              <BreakoutTooltip content={name}>
                <h2 className="text-body-medium line-clamp-1 text-on-surface-var">
                  {name}
                </h2>
              </BreakoutTooltip>

              <BreakoutTooltip content={teaser || ''}>
                <h2 className="text-title-medium line-clamp-2 h-[42px]">
                  {teaser || ''}
                </h2>
              </BreakoutTooltip>
            </BreakoutCard.Body>
            <BreakoutCard.Footer>
              <div>
                <strong className="text-body-medium text-on-surface-var">
                  {t('admin_library.versions', {
                    count: group.slideDecks.length,
                  })}
                </strong>
              </div>
            </BreakoutCard.Footer>
          </BreakoutCard>
        )
      })}
    </BreakoutCard.Grid>
  )
})

const CatalogView = observer(function CatalogView({
  catalog,
  cubit,
}: {
  catalog: Catalog
  cubit: InstructorLibraryWithTeachingPlansCubit
}) {
  const slideDecks = cubit.slideDecksForCurrentCatalog
  const teachingPlans = cubit.teachingPlansForCurrentCatalog
  const isLoading = cubit.catalogIsLoadingMap.get(catalog.id)

  if (cubit.hasSearchFilter) {
    return <SearchResultsView catalog={catalog} slideDecks={slideDecks} />
  }

  if (isLoading) {
    return (
      <div className="flex h-full w-full flex-1 flex-col items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (slideDecks.length === 0 && teachingPlans.length === 0) {
    return <TeachingPlansSearchResultsEmptyView />
  }

  return (
    <div>
      <>
        <div className="flex flex-col gap-5">
          {!cubit.hasSearchFilter && slideDecks.length > 0 && (
            <>
              <CatalogTeachingPlansView teachingPlans={teachingPlans} />
              <AllExperiencesView catalog={catalog} slideDecks={slideDecks} />
            </>
          )}
        </div>
      </>
    </div>
  )
})

const CatalogTeachingPlansView = observer(function CatalogTeachingPlansView({
  teachingPlans,
}: {
  teachingPlans: TeachingPlan[]
}) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(true)
  const rootStore = useRootStore()

  const params = useRootStore().router.queryParams
  const sectionId = params?.sectionId?.toString()

  if (teachingPlans.length === 0) return null

  return (
    <div className="w-full rounded-2xl bg-surface p-7">
      <div
        aria-live="polite"
        className="flex cursor-pointer flex-row justify-between"
      >
        <div tabIndex={0} className="text-headline-medium text-core-primary">
          {t('instructor_library.teaching_plans')}
        </div>
        <div>
          <ChevronLeft
            onClick={() => setIsOpen(!isOpen)}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault()
                setIsOpen(!isOpen)
              }
            }}
            aria-label={
              isOpen
                ? t('instructor_library.expanded')
                : t('instructor_library.collapsed')
            }
            className={isOpen ? 'rotate-270' : 'rotate-90'}
          />
        </div>
      </div>
      <div tabIndex={0} className="text-body-medium mt-1 text-on-surface-var">
        {t('instructor_library.teaching_plans_description')}
      </div>
      {isOpen && (
        <div className="mt-2">
          <BreakoutCard.Grid>
            {teachingPlans.map((teachingPlan) => (
              <TeachingPlanCard
                onClick={() => {
                  rootStore.navigateToTeachingPlanPage(
                    teachingPlan.data.catalogId,
                    teachingPlan.id,
                    {
                      ...params,
                      sectionId,
                    }
                  )
                }}
                key={teachingPlan.id}
                teachingPlan={teachingPlan}
              />
            ))}
          </BreakoutCard.Grid>
        </div>
      )}
    </div>
  )
})

const AllExperiencesView = observer(function CatalogTeachingPlansView({
  catalog,
  slideDecks,
}: {
  catalog: Catalog
  slideDecks: SlideDeck[]
}) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(true)
  const rootStore = useRootStore()
  const queryParams = rootStore.router.queryParams
  const cubit = useInstructorLibraryWithTeachingPlansCubit()
  return (
    <div className="w-full rounded-2xl bg-surface p-7">
      <div
        aria-live="polite"
        className="flex cursor-pointer flex-row justify-between"
      >
        <div tabIndex={0} className="text-headline-medium text-core-primary">
          {t('instructor_library.all_experiences')}
        </div>
        <div>
          <ChevronLeft
            onClick={() => setIsOpen(!isOpen)}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault()
                setIsOpen(!isOpen)
              }
            }}
            aria-label={
              isOpen
                ? t('instructor_library.expanded')
                : t('instructor_library.collapsed')
            }
            className={isOpen ? 'rotate-270' : 'rotate-90'}
          />
        </div>
      </div>
      <div tabIndex={0} className="text-body-medium mt-1 text-on-surface-var">
        {t('instructor_library.all_experiences_description')}
      </div>
      {isOpen && (
        <div className="mt-4 flex flex-col gap-4">
          {slideDecks.map((slideDeck) => {
            const materials = cubit.materialsBySlideDeckId.get(slideDeck.id)
            const authors = cubit.slideDeckAuthorsBySlideDeckId.get(
              slideDeck.id
            )
            const references = cubit.referencesBySlideId.get(slideDeck.id)

            return (
              <RevampedSlideDeckCard
                href={rootStore.getSlideDeckPageUrl(slideDeck.id, {
                  ...queryParams,
                  catalogId: catalog.id,
                })}
                disableThumbnailKeyboardNavigation
                onClick={() => {
                  rootStore.navigateToSlideDeckPage(slideDeck.id, {
                    ...queryParams,
                    catalogId: catalog.id,
                  })
                }}
                key={slideDeck.id}
                slideDeck={slideDeck}
                slideDeckAuthors={authors?.models || []}
                slideDeckMaterials={materials?.models || []}
                references={references?.models || []}
              />
            )
          })}
        </div>
      )}
    </div>
  )
})

const SearchResultsView = observer(function CatalogTeachingPlansView({
  catalog,
  slideDecks,
}: {
  catalog: Catalog
  slideDecks: SlideDeck[]
}) {
  const cubit = useInstructorLibraryWithTeachingPlansCubit()
  const rootStore = useRootStore()
  const queryParams = rootStore.router.queryParams

  if (slideDecks.length === 0) {
    return (
      <TeachingPlansSearchResultsEmptyView
        onClearClick={() => {
          cubit.setSearchFilter('')
        }}
      />
    )
  }

  return (
    <div className="min-h-full w-full rounded-2xl bg-surface p-7">
      {
        <div className="flex flex-col gap-4">
          {slideDecks.map((slideDeck) => {
            const materials = cubit.materialsBySlideDeckId.get(slideDeck.id)
            const authors = cubit.slideDeckAuthorsBySlideDeckId.get(
              slideDeck.id
            )
            const references = cubit.referencesBySlideId.get(slideDeck.id)

            return (
              <RevampedSlideDeckCard
                href={rootStore.getSlideDeckPageUrl(slideDeck.id, {
                  ...queryParams,
                  catalogId: catalog.id,
                })}
                disableThumbnailKeyboardNavigation
                onClick={() => {
                  rootStore.navigateToSlideDeckPage(slideDeck.id, {
                    ...queryParams,
                    catalogId: catalog.id,
                  })
                }}
                key={slideDeck.id}
                slideDeckAuthors={authors?.models || []}
                slideDeck={slideDeck}
                slideDeckMaterials={materials?.models || []}
                references={references?.models || []}
              />
            )
          })}
        </div>
      }
    </div>
  )
})

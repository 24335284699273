import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import {
  RubricType,
  type SlideRubric,
} from '@breakoutlearning/firebase-repository/models/SlideRubric'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { useDialogs } from 'hooks/dialogs'
import { useTranslation } from 'react-i18next'
import { AssignmentRubricDialog } from '../dialogs/AssignmentRubricDialog'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import {
  InstructorAssignmentCubitProvider,
  useInstructorAssignmentCubit,
} from 'hooks/cubits/instructorAssignment'

export const RubricCount = observer(function RubricCount({
  counts,
  index,
  rubric,
  roomStates,
  section,
  rubricResults,
  usersMap,
}: {
  counts: number[]
  index: number
  rubric: SlideRubric
  roomStates: RoomState[]
  section: Section
  rubricResults: Array<RoomStateRubricResult & { user: PublicUser }>
  usersMap: Map<string, PublicUser>
}) {
  const cubit = useInstructorAssignmentCubit()

  const { t } = useTranslation()
  const tScoped = (s: string) => t(`instructor_assignment.${s}`)
  const { showDialog } = useDialogs()
  const tooltip = (() => {
    if (rubric.rubricType === RubricType.blooms) {
      return [
        tScoped('bloom_0'),
        tScoped('bloom_low'),
        tScoped('bloom_medium'),
        tScoped('bloom_high'),
      ][index]
    }
    // for pass fail
    return [
      rubric.data.rubricLabel0 || tScoped('pass_fail_0'),
      rubric.data.rubricLabel1 || tScoped('pass_fail_1'),
    ][index]
  })()

  const denominator = Math.max(...counts, 1)
  const isPassFail = rubric.rubricType === RubricType.passFail

  return (
    <BreakoutTooltip content={tooltip} portal={false}>
      <div
        className="relative cursor-pointer"
        onClick={() => {
          showDialog(() => (
            <InstructorAssignmentCubitProvider value={cubit}>
              <AssignmentRubricDialog
                rubric={rubric}
                roomStates={roomStates}
                section={section}
                results={rubricResults}
                usersMap={usersMap}
                defaultQualityFilter={index}
              />
            </InstructorAssignmentCubitProvider>
          ))
        }}
      >
        <div className="flex h-[27px] w-[300px] flex-row items-center rounded-xl bg-light-grey py-[4px] pl-1 pr-2">
          <div
            className={classNames('rounded-full', {
              'bg-breakout-red': isPassFail && index === 0,
              'bg-breakout-dark-green': isPassFail && index === 1,
              'bg-breakout-color-scale-condensed-0': !isPassFail && index === 0,
              'bg-breakout-color-scale-condensed-1': !isPassFail && index === 1,
              'bg-breakout-color-scale-condensed-2': !isPassFail && index === 2,
              'bg-breakout-color-scale-condensed-3': !isPassFail && index === 3,
            })}
            style={{ width: (215 * counts[index]) / denominator, height: 19 }}
          />
          <div className="mr-3 flex-grow" />
          <div className="text-label-small mr-1">{counts[index]}</div>
          <strong className="text-body-small text-grey-text">
            {tScoped('groups')}
          </strong>
        </div>
      </div>
    </BreakoutTooltip>
  )
})

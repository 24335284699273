import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
import {
  RubricType,
  type SlideRubric,
} from '@breakoutlearning/firebase-repository/models/SlideRubric'
import type {
  BloomScore,
  PassFailScore,
} from '@breakoutlearning/firebase-repository/types'
import classNames from 'classnames'
import { Dialog, InlineDialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useTranslation } from 'react-i18next'
import {
  getRubricBgColorByScore,
  getRubricScoreDescription,
  getRubricScoreLabel,
} from 'util/rubric'
import { Justification, RubricScoreDisplay } from './IndividualEvaluation'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import type { MeetingResultsCubit } from '@breakoutlearning/firebase-repository/cubits/MeetingResultsCubit'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { useBreakoutUser } from 'hooks/profile'
import { RubricFeedbackFormDialog } from './RubricFeedbackFormDialog'
import { JustificationDetails } from 'components/RubricResults/JustificationDetails'
import { useMemo } from 'react'
import { useFilteredRubricResultDetails } from 'components/RubricResults/useFilteredRubricResultDetails'

export const RubricResultMoreDetailsDialog = observer(
  function RubricResultMoreDetailsDialog({
    cubit,
    rubric,
    rubricResult,
    onSubmit,
    inline,
    closeDialog,
  }: {
    cubit: MeetingResultsCubit
    rubric: SlideRubric
    rubricResult: RoomStateRubricResult
    onSubmit: (props: { feedback: string }) => Promise<void>
    inline: boolean
    closeDialog?: () => void
  }) {
    const user = useBreakoutUser()
    const { t } = useTranslation()
    const { showDialog } = useDialogs()
    const DialogToUse = inline ? InlineDialog : Dialog
    const showFeedbackForm =
      !cubit.meetingCubit.isPreview && !cubit.meetingCubit.useMockResults

    const details = useMemo(
      () => cubit.rubricResultDetails._models.map((m) => m.dataToJs),
      [cubit.rubricResultDetails._models]
    )

    const rubricResultDetails = useFilteredRubricResultDetails(
      details,
      rubricResult.id,
      cubit.userId
    )

    return (
      <DialogToUse
        size="xl"
        className="relative flex h-[95vh] max-h-[700px] flex-col justify-start overflow-hidden"
        innerClassName="h-full !p-0"
      >
        <DialogCloseButton
          onClick={
            closeDialog
              ? () => {
                  closeDialog()
                  return false
                }
              : undefined
          }
        />
        <div className="h-full overflow-hidden rounded-2xl p-16">
          <div className="grid h-full flex-grow grid-cols-12 overflow-hidden">
            <div className="col-span-5 flex h-full flex-col overflow-hidden pr-8">
              <div>
                <div className="text-headline-small text-on-surface">
                  {t('meeting.evaluation_method')}
                </div>
                <div className="text-body-medium mb-3">
                  <span className="font-bold">
                    {t('meeting.blooms_taxonomy')}{' '}
                  </span>
                  {t('meeting.evaluation_description')}
                </div>
              </div>

              <div className="flex flex-col space-y-1 overflow-y-auto">
                {rubric.rubricType === RubricType.blooms && (
                  <>
                    <ScoreDetails type={RubricType.blooms} score={6} />
                    <ScoreDetails type={RubricType.blooms} score={5} />
                    <ScoreDetails type={RubricType.blooms} score={4} />
                    <ScoreDetails type={RubricType.blooms} score={3} />
                    <ScoreDetails type={RubricType.blooms} score={2} />
                    <ScoreDetails type={RubricType.blooms} score={1} />
                  </>
                )}
                {rubric.rubricType === RubricType.passFail && (
                  <>
                    <ScoreDetails type={RubricType.passFail} score={1} />
                    <ScoreDetails type={RubricType.passFail} score={0} />
                  </>
                )}
              </div>
            </div>
            <div className="col-span-7 flex h-full flex-col overflow-hidden">
              <div className="flex h-full flex-grow flex-col overflow-hidden overflow-y-auto rounded-2xl bg-surface p-6">
                <div className="flex">
                  <div className="flex flex-grow flex-col pr-2">
                    <div className="text-body-medium text-on-surface">
                      {t('meeting.rubric')}
                    </div>
                    <div className="text-title-large text-on-surface">
                      {rubric.data.rubric}
                    </div>
                    <div className="text-body-medium mb-2 text-on-surface">
                      {rubric.data.rubricDescription}
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <RubricScoreDisplay
                      className="px-3 py-2"
                      rubricType={rubric.rubricType!}
                      score={rubricResult.data.score}
                    />
                  </div>
                </div>
                <div className="w-full flex-grow">
                  {rubricResult.data.justification && (
                    <Justification
                      justification={rubricResult.justificationForRubricType(
                        rubric.data.rubricType,
                        t
                      )}
                    />
                  )}
                  <div className="ml-6 mt-2">
                    {rubricResult.data.arguments.length > 0 && (
                      <JustificationDetails
                        roomId={cubit.roomStateId}
                        rubricResultDetails={rubricResultDetails}
                        rubric={rubric}
                      />
                    )}
                  </div>
                </div>
              </div>
              {showFeedbackForm &&
                !cubit.hideFeedbackForm &&
                rubricResult.data.userId === user.user.id && (
                  <div className="mt-2 flex items-center rounded-2xl bg-surface px-6 py-4">
                    <div className="flex-grow">
                      <div className="text-title-small text-on-surface">
                        {t('meeting.submit_feedback')}
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <BreakoutButton
                        kind="primary"
                        onClick={() => {
                          cubit.logEvent('rubric_result_more_details_clicked', {
                            rubric_id: rubric.id,
                            rubric_score: rubricResult.data.score,
                            rubric_type: rubric.rubricType,
                          })

                          showDialog(({ remove }) => (
                            <RubricFeedbackFormDialog
                              onSubmit={async (props) => {
                                remove()
                                onSubmit(props)
                              }}
                            />
                          ))
                        }}
                      >
                        {t('meeting.share_feedback')}
                      </BreakoutButton>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </DialogToUse>
    )
  }
)

const ScoreDetails = ({
  score,
  type,
}: {
  type: RubricType
  score: BloomScore | PassFailScore
}) => {
  const { t } = useTranslation()
  const label = getRubricScoreLabel(t, type, score)
  const bgColor = getRubricBgColorByScore({ type, score })
  const description = getRubricScoreDescription(t, type, score)

  return (
    <div className="flex h-full items-center gap-2">
      <div className="h-full w-2.5">
        <div
          className={classNames(
            'h-full w-2.5 flex-grow-0 bg-opacity-30',
            bgColor
          )}
        />
      </div>

      <div className="py-2">
        <div className="text-label-medium">{label}</div>
        <div className="text-body-medium text-on-surface-var">
          {description}
        </div>
      </div>
    </div>
  )
}

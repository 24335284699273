import {
  BreakoutAsyncButton,
  BreakoutButton,
} from 'components/design-system/BreakoutButton'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { Header, Headline } from 'pages/layout/TwoColumn'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { DesktopDevice } from 'components/icons/DesktopDevice'
import { Dialog } from 'components/dialogs/Dialog'
import { Mail } from 'components/icons/Mail'
import { useDialogs } from 'hooks/dialogs'
import { GoogleSlidesDialog } from '../dialogs/GoogleSlidesDialog'
import { DateTime } from 'luxon'
import { EditAssignmentDialog } from '../dialogs/ScheduleAssignmentDialog'
import classNames from 'classnames'
import { useBreakoutUser } from 'hooks/profile'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { FloatingIconActionButton } from 'components/breakout/FloatingIconActionButton'
import { AdminPanelSettingsIcon } from 'components/icons/AdminPanelSettings'
import { GearIcon } from 'components/icons/Gear'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { useMemo } from 'react'
import {
  AssignmentState,
  SectionState,
} from '@breakoutlearning/firebase-repository/types'
import { toast } from 'react-hot-toast'

export const PageHeader = observer(function PageHeader() {
  const cubit = useInstructorAssignmentCubit()
  const rootStore = useRootStore()
  const user = useBreakoutUser()
  const { t } = useTranslation()
  const percentageCompleted = cubit.assignmentProgress.percentageCompleted
  const showSlidesButton =
    percentageCompleted === 100 &&
    cubit.sectionAssignmentSlidesURL !== undefined

  return (
    <Header data-testid="instructor-assignment-header">
      <div className="flex h-full w-full flex-row justify-between">
        <Headline
          className="text-display-medium font-display min-w-[35%] cursor-pointer truncate"
          aria-label={[
            cubit.assignment.slideDeck.data.slideDeckName,
            t('instructor_assignment.back_to_class_page'),
          ].join(', ')}
          onClick={() =>
            rootStore.navigateTo('instructorClass', { id: cubit.sectionId })
          }
        >
          <div className="flex flex-row items-center">
            <div className="pr-1">
              <ChevronLeft aria-hidden size={24} />
            </div>
            {cubit.assignment.slideDeck.data.slideDeckName}
          </div>
        </Headline>
        <div className="flex flex-row items-center gap-5">
          {showSlidesButton ? (
            <GoogleSlidesDialogButton url={cubit.sectionAssignmentSlidesURL!} />
          ) : (
            <AssignmentCompletionProgressIndicator
              percentageCompleted={percentageCompleted}
            />
          )}
          {!cubit.isReadOnly && <ScheduleAssignmentButton />}
          {!cubit.isReadOnly && <PublishAssignmentButton />}
          {user.isCorre && <AdminOnlyActions />}
        </div>
      </div>
    </Header>
  )
})

const GoogleSlidesDialogButton = observer(function GoogleSlidesDialogButton({
  url,
}: {
  url: string
}) {
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  return (
    <BreakoutButton
      kind="primary"
      size="large"
      onClick={() => showDialog(() => <GoogleSlidesDialog url={url} />)}
      icon={<DesktopDevice size={14} />}
    >
      <span>{t('instructor_assignment.view_teaching_slides')}</span>
    </BreakoutButton>
  )
})

const AssignmentCompletionProgressIndicator = observer(
  function AssignmentCompletionProgressIndicator({
    percentageCompleted,
  }: {
    percentageCompleted: number
  }) {
    const { t } = useTranslation()
    return (
      <div className="flex h-9 w-40 flex-col justify-between">
        <div className="flex flex-row justify-between">
          <strong className="text-body-medium text-grey-text">
            {t('instructor_assignment.completion')}
          </strong>
          <strong className="text-title-small">
            {percentageCompleted.toFixed(0)}%
          </strong>
        </div>
        <div className="h-1.5 w-full rounded-full bg-grey-text">
          <div
            className={classNames(`h-full rounded-full`, {
              'bg-breakout-red': percentageCompleted < 50,
              'bg-breakout-yellow':
                percentageCompleted >= 50 && percentageCompleted < 80,
              'bg-breakout-dark-green': percentageCompleted >= 80,
            })}
            style={{ width: `${percentageCompleted}%` }}
          />
        </div>
      </div>
    )
  }
)

const ScheduleAssignmentButton = observer(function ScheduleAssignmentButton() {
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  const rootStore = useRootStore()
  const cubit = useInstructorAssignmentCubit()

  const sectionHasStudents = useMemo(
    () => cubit.section.data.userIds.length > 0,
    [cubit.section.data.userIds.length]
  )

  const { assignmentState } = cubit.assignment.data

  const [isCancelled, sectionCompleted] = [
    assignmentState === AssignmentState.canceled,
    cubit.section.data.sectionState === SectionState.completed,
  ]

  const disabledReason = isCancelled
    ? t('instructor_assignment.disabled_because_assignment_canceled')
    : t('instructor_assignment.disabled_because_section_completed')

  return (
    <BreakoutTooltip
      enabled={isCancelled || sectionCompleted}
      content={disabledReason}
    >
      <div>
        <BreakoutButton
          disabled={isCancelled || sectionCompleted}
          kind="secondary"
          size="large"
          icon={<GearIcon size={15} />}
          onClick={() =>
            showDialog(() => (
              <EditAssignmentDialog
                assignmentState={cubit.assignment.data.assignmentState}
                sectionHasStudents={sectionHasStudents}
                hasBeenStarted={cubit.hasBeenStarted}
                onSave={async (...args) => {
                  await cubit.updateAssignment(...args)
                }}
                onCancel={async (...args) => {
                  await cubit.cancelSectionAssignment(...args)
                  cubit.cancelSectionAssignment().then(() => {
                    toast.success('Assignment Canceled')
                  })
                  rootStore.navigateTo('instructorClass', {
                    id: cubit.sectionId,
                  })
                }}
                slideDeckName={cubit.slideDeck.data.slideDeckName}
                sectionName={cubit.section.data.sectionName}
                sectionClassName={cubit.section.data.className}
                groupingSize={cubit.assignment.data.groupingSize}
                groupingType={cubit.assignment.data.groupingType}
                expiresAt={
                  cubit.assignment.expiresAt
                    ? DateTime.fromJSDate(cubit.assignment.expiresAt)
                    : undefined
                }
                assignedAt={
                  cubit.assignment.assignedAt
                    ? DateTime.fromJSDate(cubit.assignment.assignedAt)
                    : undefined
                }
                deleteAssignment={async () => {
                  // Shut down the cubit to prevent any further updates before we delete.
                  // This prevents us from attempting to get the assignment data after it's been deleted.
                  cubit.dispose()
                  cubit.deleteSectionAssignment().then(() => {
                    toast.success(t('instructor_assignment.assignment_deleted'))
                  })
                  rootStore.navigateTo('instructorClass', {
                    id: cubit.sectionId,
                  })
                }}
              />
            ))
          }
        >
          {t('instructor_assignment.settings')}
        </BreakoutButton>
      </div>
    </BreakoutTooltip>
  )
})

const PublishAssignmentButton = observer(function PublishAssignmentButton() {
  const cubit = useInstructorAssignmentCubit()
  const { t } = useTranslation()
  const { showDialog, popDialog } = useDialogs()

  if (cubit.assignment.data.assignmentState >= AssignmentState.active)
    return null

  const assignmentHasRequiredFields =
    cubit.assignment.assignedAt && cubit.assignment.expiresAt

  const expiresAtAtLeastOneDayInFuture =
    cubit.assignment.expiresAt &&
    DateTime.fromJSDate(cubit.assignment.expiresAt) >
      DateTime.now().plus({ days: 1 })

  const canPublish =
    assignmentHasRequiredFields && expiresAtAtLeastOneDayInFuture

  return (
    <BreakoutTooltip
      enabled={!canPublish}
      content={
        assignmentHasRequiredFields
          ? t('instructor_assignment.cant_publish_expired')
          : t('instructor_assignment.cant_publish_unconfigured')
      }
    >
      <div>
        <BreakoutButton
          disabled={!canPublish}
          size="large"
          kind="primary"
          onClick={() => {
            showDialog(() => (
              <Dialog size="xs" innerClassName="flex flex-col gap-5">
                <div className="flex flex-grow flex-col items-center justify-center gap-3 text-center">
                  <h2 className="text-headline-large">
                    {t('instructor_assignment.publish_dialog_title')}
                  </h2>
                  <p className="text-body-medium">
                    {t('instructor_assignment.publish_dialog_subtitle')}
                  </p>
                </div>
                <div>
                  <BreakoutAsyncButton
                    kind="primary"
                    size="large"
                    onClick={async () => {
                      try {
                        await cubit.publishAssignment()
                      } catch (e) {
                        toast.error(t('instructor_assignment.publish_error'))
                      } finally {
                        popDialog()
                      }
                    }}
                    fullWidth
                  >
                    {t('instructor_assignment.publish')}
                  </BreakoutAsyncButton>
                  <BreakoutButton
                    kind="tertiary"
                    size="medium"
                    onClick={() => {
                      popDialog()
                    }}
                    fullWidth
                  >
                    {t('instructor_assignment.publish_dialog_keep_as_draft')}
                  </BreakoutButton>
                </div>
              </Dialog>
            ))
          }}
        >
          {t('instructor_assignment.publish')}
        </BreakoutButton>
      </div>
    </BreakoutTooltip>
  )
})

const AdminOnlyActions = observer(function AdminOnlyActions() {
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  const cubit = useInstructorAssignmentCubit()
  const { percentageCompleted } = cubit.assignmentProgress

  return (
    <FloatingIconActionButton
      kind="secondary"
      className="z-10 flex justify-end"
      aria-label={t('buttons.admin_actions')}
      menuBottom={true}
      Icon={AdminPanelSettingsIcon}
      actions={
        percentageCompleted > 0
          ? [
              {
                text: t('instructor_assignment.send_test_email'),
                Icon: ({ size }: { size: number }) => (
                  <Mail
                    size={size}
                    className="fill-core-primary stroke-core-primary"
                  />
                ),
                onClick: () => {
                  showDialog(({ remove }) => (
                    <Dialog size="xs" className="h-[400px]">
                      <div className="flex h-full flex-col gap-3">
                        <DialogCloseButton className="absolute right-0 top-0 p-5 pb-0" />
                        <h2 className="text-headline-large mb-3">
                          {t('instructor_assignment.send_test_email')}
                        </h2>
                        <p className="text-body-large">
                          {t(
                            'instructor_assignment.send_test_email_confirmation'
                          )}
                        </p>
                        <div className="mt-4 flex h-full flex-col justify-end gap-3">
                          <BreakoutButton
                            kind="success"
                            onClick={() => {
                              cubit.sendTestEmail()
                              remove()
                            }}
                            fullWidth={true}
                            size="large"
                          >
                            {t('instructor_assignment.yes')}
                          </BreakoutButton>
                          <BreakoutButton
                            kind="secondary"
                            onClick={() => {
                              remove()
                            }}
                            size="large"
                            fullWidth={true}
                          >
                            {t('instructor_assignment.no')}
                          </BreakoutButton>
                        </div>
                      </div>
                    </Dialog>
                  ))
                },
              },
            ]
          : []
      }
    />
  )
})

import { type AssignmentGroupData } from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
import type { SlideRubric } from '@breakoutlearning/firebase-repository/models/SlideRubric'
import classNames from 'classnames'
import {
  BreakoutUserAvatar,
  BreakoutUserAvatarStack,
} from 'components/breakout/BreakoutUserAvatar'
import { RubricScoreWidget } from 'components/breakout/RubricScoreWidget'
import { BreakoutIconButton } from 'components/design-system/BreakoutButton'
import { BreakoutTable } from 'components/design-system/BreakoutTable'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { ArrowRightIcon } from 'components/icons/ArrowRight'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { Shield } from 'components/icons/Shield'
import { JustificationDetails } from 'components/RubricResults/JustificationDetails'
import { useFilteredRubricResultDetails } from 'components/RubricResults/useFilteredRubricResultDetails'
import { useRepository } from 'hooks/auth'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { t } from 'i18next'
import { useMemo, useState } from 'react'

export function AssignmentRubricGroupDialog({
  groupData,
  userId,
  groupResults,
  singleUserMode = false,
}: {
  groupData: AssignmentGroupData
  groupResults?: Map<SlideRubric, Array<RoomStateRubricResult>>
  userId?: string
  singleUserMode?: boolean
}) {
  const [rubric, setRubric] = useState<SlideRubric | null>(null)

  return (
    <Dialog
      size="xl"
      className="h-[88vh] !overflow-hidden !bg-core-tertiary"
      innerClassName="flex flex-col h-full"
    >
      <DialogCloseButton className="absolute right-3 top-3" />

      {rubric && (
        <RubricView
          groupData={groupData}
          rubric={rubric}
          setRubric={setRubric}
          userId={userId}
        />
      )}
      {!rubric && (
        <GroupView
          groupData={groupData}
          userId={userId}
          setRubric={setRubric}
          groupResults={groupResults}
          singleUserMode={singleUserMode}
        />
      )}
    </Dialog>
  )
}

function RubricView({
  groupData,
  rubric,
  setRubric,
  userId,
}: {
  groupData: AssignmentGroupData
  rubric: SlideRubric
  setRubric: (rubric: SlideRubric | null) => void
  userId?: string
}) {
  const repository = useRepository()

  const users = groupData.groupMembers.map((userId) =>
    repository.userStore.getUser(userId)
  )
  const userToShow = userId ? users.find((user) => user.id === userId) : null
  const rubricResultsPerUser = useMemo(
    () => groupData.getResultsByUserIdForRubric(rubric),
    [groupData, rubric]
  )
  const groupLeader = groupData.roomState?.groupLeader

  // sort by highest rubric score
  const sortedUsers = useMemo(() => {
    return users.sort((a, b) => {
      const aScore = rubricResultsPerUser.get(a.id)?.data.score ?? 0
      const bScore = rubricResultsPerUser.get(b.id)?.data.score ?? 0
      return bScore - aScore
    })
  }, [rubricResultsPerUser, users])

  const initialUserId = (userToShow ? userToShow.id : sortedUsers[0].id) ?? null
  const [selectedUserId, setSelectedUserId] = useState<string | null>(
    initialUserId
  )

  const chosenUserResult = selectedUserId
    ? rubricResultsPerUser.get(selectedUserId)
    : undefined

  const cubit = useInstructorAssignmentCubit()
  const rubricResultDetails = useMemo(
    () => cubit.roomStateRubricResultDetails._models.map((m) => m.dataToJs),
    [cubit.roomStateRubricResultDetails]
  )
  const filteredRubricResultDetailsByUser = useFilteredRubricResultDetails(
    rubricResultDetails,
    rubric.id,
    selectedUserId ?? ''
  )

  return (
    <div className="flex h-full flex-col">
      <BreakoutIconButton
        kind="tertiary"
        className="mb-2 ml-3 aspect-square !h-6 !w-6 rotate-180"
        icon={<ArrowRightIcon />}
        title={t('instructor_assignment.back_to_group_view')}
        onClick={() => setRubric(null)}
      />
      <div className="flex w-full flex-row justify-between gap-3 px-3">
        <div>
          <div className="text-headline-large mb-3">{rubric.data.rubric}</div>
          <div className="text-body-large text-grey-text">
            {rubric.data.rubricDescription}
          </div>
        </div>
        <div>
          <div className="flex flex-row items-center gap-4">
            <BreakoutTooltip
              content={t('instructor_assignment.group_leader_name', {
                name: groupLeader?.fullName,
              })}
            >
              <div className="text-label-medium">
                {groupData.roomState?.roomStateName}
              </div>
            </BreakoutTooltip>
            <BreakoutUserAvatarStack users={users} radius={15} />
          </div>
        </div>
      </div>
      <div className="mt-5 flex flex-grow flex-row gap-3 overflow-y-auto">
        <div className="flex w-[225px] flex-col gap-2">
          {(userToShow ? [userToShow] : sortedUsers).map((user) => (
            <div
              onClick={
                rubricResultsPerUser.get(user.id)?.data.arguments.length
                  ? () => setSelectedUserId(user.id)
                  : undefined
              }
              key={user.id}
              className={classNames(
                'flex flex-row items-center gap-2 rounded-xl p-3',
                {
                  'bg-surface': selectedUserId === user.id,
                  'cursor-pointer': rubricResultsPerUser.get(user.id)?.data
                    .arguments.length,
                }
              )}
            >
              <div>
                <BreakoutUserAvatar user={user} radius={15} />
              </div>
              <div className="text-label-medium flex-1 truncate">
                {user.fullName}
              </div>
              {groupData.roomState?.data.groupLeaderUserIds.includes(
                user.id
              ) && <Shield size={14} className="text-breakout-yellow" />}
              <div>
                {rubricResultsPerUser.has(user.id) && (
                  <RubricScoreWidget
                    rubric={rubric}
                    rubricResult={rubricResultsPerUser.get(user.id)!}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="h-full flex-1 rounded-2xl bg-surface">
          {chosenUserResult && (
            <div className="flex h-full flex-col overflow-y-auto p-5">
              <div className="text-label-medium mb-2 text-grey-text">
                {t('instructor.evaluation')}
              </div>
              <div className="text-body-medium">
                {chosenUserResult.justificationForRubricType(
                  rubric.data.rubricType,
                  t
                )}
              </div>
              {rubricResultDetails.length > 0 && (
                <>
                  {' '}
                  <div className="text-label-medium  my-4 text-grey-text">
                    {t('instructor.arguments')}
                  </div>
                  <JustificationDetails
                    roomId={groupData.roomState!.id}
                    rubricResultDetails={filteredRubricResultDetailsByUser}
                    rubric={rubric}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function GroupView({
  groupData,
  userId,
  setRubric,
  groupResults,
  singleUserMode,
}: {
  groupData: AssignmentGroupData
  userId?: string
  setRubric: (rubric: SlideRubric) => void
  groupResults: Map<SlideRubric, Array<RoomStateRubricResult>> | undefined
  singleUserMode: boolean
}) {
  const repository = useRepository()
  const users = groupData.groupMembers.map((userId) => {
    return repository.userStore.getUser(userId)
  })
  const rubricResults: {
    user: PublicUser
    rubric: SlideRubric
    result: RoomStateRubricResult
  }[] = []

  const groupLeader = groupData.roomState?.groupLeader

  if (userId) {
    const userRubrics = groupData.rubricResults.get(userId)

    if (userRubrics) {
      const list = Array.from(userRubrics.entries())
      for (const [rubric, userRubricResults] of list) {
        for (const rubricResult of userRubricResults) {
          rubricResults.push({
            user: repository.userStore.getUser(rubricResult.data.userId),
            rubric,
            result: rubricResult,
          })
        }
      }
    }
  } else {
    if (!groupResults) {
      const list = Array.from(groupData.rubricResults.entries())
      for (const [userId, userRubrics] of list) {
        const user = repository.userStore.getUser(userId)
        for (const [rubric, userRubricResults] of userRubrics.entries()) {
          for (const rubricResult of userRubricResults) {
            rubricResults.push({
              user,
              rubric,
              result: rubricResult,
            })
          }
        }
      }
    } else {
      groupResults.forEach((rubricResultsItem, rubric) => {
        // get entry with highest score
        const result = rubricResultsItem.reduce((prev, current) => {
          return prev.data.score > current.data.score ? prev : current
        })
        rubricResults.push({
          user: repository.userStore.getUser(result.data.userId),
          result: result,
          rubric,
        })
      })
    }
  }

  return (
    <>
      <div className="mb-3 flex w-full flex-row items-center justify-between px-3">
        <div className="text-headline-large">
          {singleUserMode
            ? t('instructor.rubric_assessment')
            : t('instructor_assignment.group_rubric_assessment')}
        </div>
        <div className="flex flex-row items-center gap-4">
          <BreakoutTooltip
            content={t('instructor_assignment.group_leader_name', {
              name: groupLeader?.fullName,
            })}
          >
            <div className="text-label-medium">
              {groupData.roomState?.roomStateName}
            </div>
          </BreakoutTooltip>
          <BreakoutUserAvatarStack users={users} radius={15} />
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <BreakoutTable className="mb-10 max-h-full table-auto">
          {/* position sticky is the only way i could get tbody to scroll without the header*/}
          <thead className="sticky top-0 z-10">
            <tr>
              <th className="!text-xxs">{t('instructor.rubric')}</th>
              <th className="!text-xxs">{t('instructor.score')}</th>
              <th className="!text-xxs">{t('instructor.evaluation')}</th>
            </tr>
          </thead>
          <tbody>
            {rubricResults.map(({ rubric, result, user }) => {
              return (
                <UserRow
                  onClick={() => setRubric(rubric)}
                  key={rubric.id}
                  rubric={rubric}
                  result={result}
                  user={user}
                  groupData={groupData}
                />
              )
            })}
          </tbody>
        </BreakoutTable>
      </div>
    </>
  )
}

function UserRow({
  rubric,
  result,
  user,
  groupData,
  onClick,
}: {
  rubric: SlideRubric
  result: RoomStateRubricResult
  user: PublicUser
  groupData: AssignmentGroupData
  onClick: () => void
}) {
  const cubit = useInstructorAssignmentCubit()
  const rubricResultDetails = useMemo(
    () => cubit.roomStateRubricResultDetails._models.map((m) => m.dataToJs),
    [cubit.roomStateRubricResultDetails]
  )
  const filteredRubricResultDetailsByUser = useFilteredRubricResultDetails(
    rubricResultDetails,
    rubric.id,
    user.id
  )

  const [showArguments, setShowArguments] = useState(false)
  return (
    <tr onClick={onClick} className="cursor-pointer">
      <td className="w-[35%]">
        <div className="text-title-small mb-3">{rubric.data.rubric}</div>
        <div className="text-body-small">{rubric.data.rubricDescription}</div>
      </td>
      <td>
        <RubricScoreWidget
          key={rubric.id}
          rubric={rubric}
          rubricResult={result}
          showTitle
        />
      </td>
      <td className="!text-xs">
        <div className="flex flex-row gap-2">
          <div>
            <BreakoutTooltip
              content={
                showArguments
                  ? t('instructor.hide_arguments')
                  : t('instructor.show_arguments')
              }
            >
              <div
                className="cursor-pointer p-2 pl-0"
                onClick={(e) => {
                  e.stopPropagation()
                  setShowArguments(!showArguments)
                }}
              >
                <ChevronLeft
                  size={14}
                  className={showArguments ? 'rotate-90' : 'rotate-270'}
                />
              </div>
            </BreakoutTooltip>
          </div>
          <div>
            <div className="mb-2 flex flex-row items-center gap-2">
              <BreakoutUserAvatar user={user} radius={14} />
              <strong className="text-label-medium">{user.fullName}</strong>
              {groupData.roomState?.data.groupLeaderUserIds.includes(
                user.id
              ) && <Shield size={14} className="text-breakout-yellow" />}
            </div>
            <div className="text-body-medium">
              {result.justificationForRubricType(rubric.data.rubricType, t)}
            </div>
            <JustificationDetails
              roomId={groupData.roomState!.id}
              rubricResultDetails={filteredRubricResultDetailsByUser}
              rubric={rubric}
            />
          </div>
        </div>
      </td>
    </tr>
  )
}

import { type InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
import { observer } from 'mobx-react-lite'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import React, { forwardRef, useRef, useState } from 'react'
import { BreakoutAsyncButton } from 'components/design-system/BreakoutButton'
import { toast } from 'react-hot-toast'
import { useDialogs } from 'hooks/dialogs'

export const SectionPassPriceDialog = observer(function SectionPassPriceDialog({
  cubit,
}: {
  cubit: InstructorSectionCubit
}) {
  const [error, setError] = useState<string>()
  const ref = useRef<HTMLInputElement>(null)
  const { popDialog } = useDialogs()

  const onSubmit = async () => {
    if (!ref.current) return
    const val = getCentsFromUSDString(ref.current.value)
    if (val !== null && val < 100) return setError('price must be at least $1')
    setError(undefined)
    try {
      await cubit.updateSectionPassPrice(val)
      popDialog()
    } catch (e) {
      toast.error(`Error setting price: ${e instanceof Error ? e.message : e}`)
    }
  }

  return (
    <Dialog
      size="sm"
      innerClassName="flex h-full flex-col gap-5"
      ignoreBackdropClick
    >
      <DialogCloseButton />
      <div className="text-headline-large">Section Pass Price</div>
      <div className="text-body-medium text-on-surface-var">
        Modify the price of the section pass for ONLY this section. Leaving this
        blank will cause the section pass price to default to the global
        setting.
      </div>
      <div className="flex h-full w-full flex-grow flex-row items-center justify-center">
        <CurrencyInput
          ref={ref}
          error={error}
          startingCents={cubit.section.data.sectionPassPrice}
        />
      </div>
      <BreakoutAsyncButton size="large" fullWidth onClick={onSubmit}>
        Submit
      </BreakoutAsyncButton>
    </Dialog>
  )
})

interface CurrencyInputProps {
  error?: string
  startingCents?: number
}

const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  function CurrencyInput({ error, startingCents }, ref) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const formatted = formatUSD(e.target.value)
      setValue(formatted)
    }

    const [value, setValue] = useState(
      startingCents && startingCents >= 100
        ? formatUSD(startingCents.toString())
        : undefined
    )

    return (
      <BreakoutTextInput
        ref={ref}
        kind="secondary"
        label={'Price in dollars ($)'}
        className="flex-grow"
        onChange={handleChange}
        value={value}
        error={error ? { message: error, type: 'custom' } : undefined}
      />
    )
  }
)

const formatUSD = (str: string) => {
  let rawValue = str.replace(/[^0-9]/g, '') // Only allow digits
  rawValue = rawValue.replace(/^0+(?!$)/, '') // Remove leading zeros,
  if (rawValue === '' || rawValue === '0') return ''
  return '$' + (parseInt(rawValue) / 100).toFixed(2)
}

function getCentsFromUSDString(usdString: string) {
  const cleaned = usdString.replace(/[$,]/g, '').trim()
  const cents = Math.round(parseFloat(cleaned) * 100)
  return isNaN(cents) ? null : cents
}

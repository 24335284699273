import { memo } from 'react'
import type { RubricResultDetailProps } from './types'
import { EventDisplay } from './EventDisplay'

export const RubricResultDetail = memo(function RubricResultDetail({
  rubricResultDetail,
  events,
  eventNumbers,
}: RubricResultDetailProps) {
  return (
    <li
      key={rubricResultDetail.content}
      data-testid={`rubric-result-detail-${rubricResultDetail.content}`}
      className="relative h-full w-full"
    >
      {rubricResultDetail.content}
      {events.map((event) => {
        return (
          <EventDisplay
            key={event.id}
            event={event}
            eventNumber={eventNumbers[event.transcript + event.id]}
            eventMatch={rubricResultDetail.match}
          />
        )
      })}
    </li>
  )
})

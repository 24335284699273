import { OrganizationCubit } from '@breakoutlearning/firebase-repository/cubits/OrganizationCubit'
import { Breadcrumb } from 'components/Breadcrumb'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import {
  OrganizationCubitProvider,
  useOrganizationCubit,
} from 'hooks/cubits/organization'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { Actions, Contents, Header, MainPane } from 'pages/layout/TwoColumn'
import { useCallback } from 'react'
import { OrganizationDetailsView } from './OrganizationDetailsView'
import { useTranslation } from 'react-i18next'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { SendIcon } from 'components/icons/Send'
import { useDialogs } from 'hooks/dialogs'
import { LinkCopiedToClipboardDialog } from 'components/LinkCopiedToClipboard'
import { toast } from 'react-hot-toast'
import { OrganizationClassesView } from './OrganizationClassesView'
import { OrganizationInstructorsView } from './OrganizationInstructorsView'
import { OrganizationInvoiceRequestView } from './OrganizationInvoiceRequestView'
import { OrganizationIntegrationsView } from './OrganizationIntegrationsView'

export const OrganizationPage = observer(function OrganizationPage({
  organizationId,
}: {
  organizationId: string
}) {
  const repository = useRepository()

  const cubit = useCubitBuilder(
    () => new OrganizationCubit(repository, organizationId),
    [repository, organizationId]
  )

  return (
    <OrganizationCubitProvider value={cubit}>
      <OrganizationPageStructure />
    </OrganizationCubitProvider>
  )
})

const OrganizationPageStructure = observer(
  function OrganizationPageStructure() {
    const store = useRootStore()
    const { showDialog, clearAllDialogs } = useDialogs()
    const urlParams = new URLSearchParams(window.location.search)
    const cubit = useOrganizationCubit()
    const { t } = useTranslation()

    const onClick = useCallback(() => {
      store.navigateTo('organizations')
    }, [store])

    const handleInviteNewProfessor = useCallback(async () => {
      try {
        const invitationId = await cubit.createOrgInvitation({
          organizationAdmin: false,
        })
        const invitationUrl = `${window.location.origin}/invitation/${invitationId}`
        showDialog(() => (
          <LinkCopiedToClipboardDialog
            url={invitationUrl}
            warning={t('organization.invite_instructor_warning')}
            customBottomContent={
              <div className="mt-3 flex items-center justify-center">
                <a
                  onClick={async () => {
                    await cubit.addMyselfAsInstructor()
                    clearAllDialogs()
                    toast.success(t('organization.added_as_instructor'))
                  }}
                  className="text-body-medium !cursor-pointer text-on-surface-var hover:underline"
                >
                  {t('organization.add_myself_as_instructor')}
                </a>
              </div>
            }
          />
        ))
      } catch (e) {
        clearAllDialogs()
        toast.error('Invitation creation failure')
        throw e
      }
    }, [cubit, t, showDialog, clearAllDialogs])

    return (
      <MainPane>
        <Header>
          <Breadcrumb
            onClick={urlParams.get('singleOrg') ? undefined : onClick}
            className="h-[52px]"
          >
            {cubit.organization.data.organizationName}
          </Breadcrumb>
          <Actions>
            <BreakoutButton
              size="large"
              kind="secondary"
              icon={<SendIcon size={15} />}
              onClick={handleInviteNewProfessor}
            >
              {t('organization.invite_new_instructor')}
            </BreakoutButton>
          </Actions>
        </Header>
        <Contents className="h-full overflow-hidden">
          <div className="flex h-full flex-col rounded-2xl">
            <OrganizationViewTabButtons />
            <OrganizationViewTab />
          </div>
        </Contents>
      </MainPane>
    )
  }
)

const OrganizationViewTab = observer(function OrganizationViewTab() {
  const cubit = useOrganizationCubit()

  switch (cubit.selectedTab) {
    case 'settings':
      return <OrganizationDetailsView />
    case 'instructors':
      return <OrganizationInstructorsView />
    case 'classes':
      return <OrganizationClassesView />
    case 'integrations':
      return <OrganizationIntegrationsView />
    case 'invoice_requests':
      return <OrganizationInvoiceRequestView />
  }
})

const OrganizationViewTabButtons = observer(function SlideDeckView() {
  const { t } = useTranslation()
  const cubit = useOrganizationCubit()

  const tabs: Array<typeof cubit.selectedTab> = [
    'settings',
    'instructors',
    'classes',
    'invoice_requests',
  ]

  return (
    <div className="flex flex-row flex-wrap gap-2 pb-5">
      {tabs.map((tab) => (
        <BreakoutButton
          key={tab}
          size="medium"
          kind={cubit.selectedTab === tab ? 'secondary' : 'tertiary'}
          onClick={() => cubit.setSelectedTab(tab)}
        >
          {t(`organization.${tab}`)}
        </BreakoutButton>
      ))}
    </div>
  )
})

import { FullPageSpinner } from 'components/Spinner'
import { signInWithCustomToken } from 'firebase/auth'
import { useRootStore } from 'hooks/rootStore'
import { reaction } from 'mobx'
import { useEffect } from 'react'
import { useRepository } from '../../hooks/auth'
import { useCanvasMode } from 'hooks/use-canvas-mode'
import { useTranslation } from 'react-i18next'
import { BreakoutButtonLink } from 'components/design-system/BreakoutButton'

function areWeInIframe() {
  return (
    window !== window.parent ||
    window !== window.top ||
    window.self !== window.top
  )
}

function TokenLogin() {
  // const [loggingIn, setLoggingIn] = useState(false)
  const rootStore = useRootStore()
  const repository = useRepository()

  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get('token')
  const redirectTo = queryParams.get('redirectTo')
  const landingPage = queryParams.get('landingPage')
  const canvasMode = queryParams.get('canvasMode')
  const { setCanvasMode } = useCanvasMode()

  useEffect(() => {
    return reaction(
      () => repository.breakoutUser?.isLoaded,
      (isLoaded) => {
        if (isLoaded) {
          if (canvasMode) setCanvasMode(true)
          rootStore.navigateTo('instructorLibrary')
        }
      }
    )
  }, [repository, rootStore, canvasMode, setCanvasMode])

  useEffect(() => {
    if (!token) {
      alert('bad token')
      return
    }
    if (canvasMode) setCanvasMode(true)
    signInWithCustomToken(repository.auth, token).then(() => {
      if (landingPage) {
        window.location.href = landingPage
        return
      }

      rootStore.navigateTo(
        'instructorLibrary',
        undefined,
        redirectTo
          ? {
              redirectTo: redirectTo,
            }
          : undefined
      )
    })
  }, [
    token,
    rootStore,
    repository.auth,
    redirectTo,
    landingPage,
    canvasMode,
    setCanvasMode,
  ])

  return <FullPageSpinner />
}

function IframeProtector({ children }: { children: React.ReactNode }) {
  const queryParams = new URLSearchParams(window.location.search)
  const ejectFromIframe = queryParams.get('ejectFromIframe') === 'true'
  const { t } = useTranslation()
  if (ejectFromIframe && areWeInIframe()) {
    return (
      <div className="lti-configuration min-h-screen w-screen md:-m-8">
        <div className="">
          <img
            src="/assets/images/logo.png"
            width={64}
            className="absolute right-8 top-8"
            alt={t('breakout_learning')}
          />
        </div>
        <div className="iframe-ejector flex min-h-screen w-full flex-col items-start justify-center">
          <div className="ml-[5vw] max-w-[400px]">
            <div className="mb-5">
              <h2 className="text-headline-small">
                {t('lti.configuration.eject_headline_small')}
              </h2>
              <h1 className="text-headline-large">
                {t('lti.configuration.eject_headline_large')}
              </h1>
              <div className="text-body-large">
                {t('lti.configuration.eject_headline_description')}
              </div>
            </div>
            <div>
              <div>
                <BreakoutButtonLink
                  className="flex items-center justify-center"
                  kind="accent"
                  href={window.location.href.replace(
                    'ejectFromIframe=true',
                    ''
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('login.open_application_in_new_tab')}
                </BreakoutButtonLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return <>{children}</>
}
export function LoginWithToken() {
  return (
    <IframeProtector>
      <div className="relative box-border flex h-full w-full items-center justify-center overflow-auto rounded-3xl bg-core-tertiary">
        <TokenLogin />
      </div>
    </IframeProtector>
  )
}

import {
  AssignmentState,
  type AssignmentStatus,
} from '@breakoutlearning/firebase-repository/types'
import classNames from 'classnames'
import { BreakoutCard } from 'components/design-system/BreakoutCard'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

export function InstructorAssignmentCard({
  imageUrl,
  title,
  subtitle,
  status,
  state,
  expiresAt,
  onClick,
  testId,
}: {
  imageUrl?: string
  title: string
  subtitle: string
  status: AssignmentStatus
  state: AssignmentState
  expiresAt?: DateTime
  onClick?: () => void
  testId?: string
}) {
  const { t } = useTranslation()
  return (
    <BreakoutCard data-testid={testId} onClick={onClick}>
      <BreakoutCard.Header className="">
        {state === AssignmentState.active ? (
          <BreakoutPill.AssignmentStatus kind="primary" state={status} />
        ) : (
          <BreakoutPill.AssignmentState kind="primary" state={state} />
        )}
      </BreakoutCard.Header>
      <BreakoutCard.Body>
        {imageUrl && (
          <div className="flex h-[50px] w-[50px] flex-col items-center justify-center ">
            <img
              aria-hidden
              alt={title}
              src={imageUrl}
              className="inline max-h-[30px] max-w-[30px]"
            />
          </div>
        )}
        <h2 className="text-body-medium line-clamp-1 text-on-surface-var">
          {title}
        </h2>
        <h2 className="text-title-large line-clamp-2 text-on-surface">
          {subtitle}
        </h2>
      </BreakoutCard.Body>
      <BreakoutCard.Footer>
        {/* hack to make this still take up space as other footers*/}
        <div
          className={classNames('text-body-small', {
            'text-transparent': !expiresAt,
            'text-on-surface-var': expiresAt,
          })}
        >
          {t('instructor_class.deadline')}
        </div>
        <div
          className={classNames('text-label-medium', {
            'text-transparent': !expiresAt,
            'text-on-surface': expiresAt,
          })}
        >
          {expiresAt?.toLocaleString(DateTime.DATE_FULL) || 'No Deadline'}
        </div>
      </BreakoutCard.Footer>
    </BreakoutCard>
  )
}

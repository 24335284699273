import { observer } from 'mobx-react-lite'
import { Dialog } from 'components/dialogs/Dialog'

import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import {
  RubricType,
  type SlideRubric,
} from '@breakoutlearning/firebase-repository/models/SlideRubric'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import { type PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import { useCallback, useMemo, useState } from 'react'
import { roomStateUsers } from 'util/roomStateUsers'
import { useTranslation } from 'react-i18next'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Search } from 'components/icons/Search'
import { BloomsLabel } from '../rubric_components/BloomsLabel'
import { PassFailLabel } from '../rubric_components/PassFailLabel'
import { EmptyState } from 'components/breakout/EmptyState'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { roomStateName } from 'util/roomStateName'
import { roomStateGroupLeader } from 'util/roomStateGroupLeader'
import {
  BreakoutUserAvatar,
  BreakoutUserAvatarStack,
} from 'components/breakout/BreakoutUserAvatar'
import { Shield } from 'components/icons/Shield'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { RubricScoreWidget } from 'components/breakout/RubricScoreWidget'
import { ChevronRight } from 'components/icons/ChevronRight'
import classNames from 'classnames'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { JustificationDetails } from 'components/RubricResults/JustificationDetails'
import { useFilteredRubricResultDetails } from 'components/RubricResults/useFilteredRubricResultDetails'
import type { FirestoreRoomStateRubricResultDetail } from '@breakoutlearning/firebase-repository/types'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'

export const AssignmentRubricDialog = observer(function AssignmentRubricDialog({
  results,
  roomStates,
  rubric,
  section,
  usersMap,
  defaultQualityFilter = 4,
}: {
  results: Array<RoomStateRubricResult & { user: PublicUser }>
  roomStates: RoomState[]
  rubric: SlideRubric
  section: Section
  usersMap: Map<string, PublicUser>
  defaultQualityFilter?: number
}) {
  const cubit = useInstructorAssignmentCubit()
  const rubricResultDetails = useMemo(
    () => cubit.roomStateRubricResultDetails._models.map((m) => m.dataToJs),
    [cubit.roomStateRubricResultDetails]
  )

  const { t } = useTranslation()
  const tScoped = useCallback(
    (key: string) => t(`instructor_assignment.${key}`),
    [t]
  )

  const [searchStrings, setSearchStrings] = useState<string[]>([])
  const [qualityFilter, setQualityFilter] =
    useState<number>(defaultQualityFilter)

  // viewType is either 'student' (0 in dart) or 'group' (1 in dart)
  const [viewType, setViewType] = useState<'student' | 'group'>('student')

  const filteredRooms = useMemo(
    () =>
      getFilteredRooms({
        roomStates,
        usersMap,
        searchStrings,
        viewType,
      }),
    [roomStates, usersMap, searchStrings, viewType]
  )

  const roomStateRubricResults = useMemo(
    () =>
      getRoomStateRubricResults({
        results,
      }),
    [results]
  )

  const filteredResults = useMemo(
    () =>
      getFilteredResults({
        results,
        filteredRooms,
        roomStateRubricResults,
        searchStrings,
        viewType,
        qualityFilter,
      }),
    [
      results,
      filteredRooms,
      roomStateRubricResults,
      searchStrings,
      viewType,
      qualityFilter,
    ]
  )

  const scoreHeader =
    rubric.rubricType === RubricType.blooms
      ? tScoped('blooms_score')
      : tScoped('pass_fail')

  const selectOptions = useMemo(() => {
    const options: { label: JSX.Element; value: number }[] = []

    if (rubric.rubricType === RubricType.blooms) {
      // For blooms, we have 4 levels
      for (let i = 0; i < 4; i++) {
        options.push({
          label: (
            <BloomsLabel level={i} className="text-body-small" width={150} />
          ),
          value: i,
        })
      }
    } else {
      // for pass/fail we have 2 levels
      for (let i = 0; i < 2; i++) {
        options.push({
          label: (
            <PassFailLabel
              level={i}
              className="text-body-small"
              width={150}
              label={
                i === 0
                  ? (rubric.data.rubricLabel0 ?? tScoped('pass_fail_0'))
                  : (rubric.data.rubricLabel1 ?? tScoped('pass_fail_1'))
              }
            />
          ),
          value: i,
        })
      }
    }

    // Always add the "All Results" option as last
    options.push({
      label: (
        <strong className="text-label-medium text-grey-text">
          {tScoped('all_results')}
        </strong>
      ),
      value: 4,
    })

    return options
  }, [rubric.data, rubric.rubricType, tScoped])

  return (
    <Dialog
      size="lg"
      className="h-full !max-h-[85vh] !min-h-[450px] !w-[90vw] !max-w-[1400px]"
      innerClassName="!p-0 flex h-full"
    >
      <DialogCloseButton />
      <div className="h-full w-full bg-core-tertiary px-10 py-6">
        <div className="flex h-full w-full flex-col">
          <div className="p- mb-3 flex max-h-[200px] w-full flex-row items-center justify-between gap-6 rounded-2xl bg-core-tertiary">
            <div className="flex flex-col gap-2.5">
              <h1 className="text-headline-large">
                {tScoped('rubric_details')}
              </h1>
              <h2 className="text-headline-small line-clamp-2">
                {rubric.data.rubric}
              </h2>
              <p className="text-body-large line-clamp-2 text-grey-text">
                {rubric.data.rubricDescription}
              </p>
            </div>
            <BreakoutButton
              kind="tertiary"
              size="large"
              className="!w-[200px] !min-w-[200px] bg-light-grey"
              onClick={() =>
                setViewType(viewType === 'student' ? 'group' : 'student')
              }
            >
              <span className="text-nowrap">
                {viewType === 'student'
                  ? tScoped('switch_to_group_view')
                  : tScoped('switch_to_student_view')}
              </span>
            </BreakoutButton>
          </div>
          <div className="mb-3 flex h-[50px] flex-row items-center rounded-xl">
            <div className="w-[250px] min-w-[250px]">
              <form
                onSubmit={(f) => {
                  f.preventDefault()
                  const filter = f.currentTarget.filter.value
                  if (!filter) return
                  f.currentTarget.reset()
                  setSearchStrings([...searchStrings, filter])
                }}
              >
                <BreakoutTextInput
                  name="filter"
                  autoFocus
                  LeadingIcon={Search}
                  inputClassName="text-body-medium font-medium placeholder-grey-text"
                  kind="tertiary"
                  placeholder={tScoped('search_students')}
                />
              </form>
            </div>
            {/* might need tweaks for chips positioning */}
            <div className="flex flex-grow justify-end">
              <BreakoutPill.Chips
                labels={searchStrings}
                onClick={(f) =>
                  setSearchStrings(searchStrings.filter((s) => s !== f))
                }
              />
            </div>
            <div className="w-[208px] min-w-[208px] px-1.5">
              <BreakoutSelect
                onChange={(value) => setQualityFilter(value)}
                value={qualityFilter}
                options={selectOptions}
                kind="secondary"
                aria-label={tScoped('quality_filter')}
              />
            </div>
          </div>
          <div className="mb-1 flex h-[50px] w-full flex-shrink-0 flex-row items-center rounded-2xl bg-light-grey px-5">
            {viewType === 'student' && (
              <strong className="text-label-small w-[200px]">
                {tScoped('full_name')}
              </strong>
            )}
            <strong className="text-label-small w-[175px]">
              {tScoped('group')}
            </strong>
            <strong className="text-label-small w-[130px]">
              {scoreHeader}
            </strong>
            <strong className="text-label-small flex flex-grow">
              {viewType === 'student'
                ? tScoped('evaluation')
                : tScoped('highlighted_evaluation')}
            </strong>
          </div>
          <div className="h-full flex-grow overflow-y-auto">
            {!filteredResults.length ? (
              <EmptyState Icon={Search} text={tScoped('no_results')} />
            ) : (
              filteredResults.map((result, i) => (
                <ResultsRow
                  key={i}
                  result={result}
                  roomStates={roomStates}
                  rubric={rubric}
                  section={section}
                  usersMap={usersMap}
                  viewType={viewType}
                  rubricResultDetails={rubricResultDetails}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </Dialog>
  )
})

const ResultsRow = observer(function ResultsRow({
  result,
  roomStates,
  rubric,
  rubricResultDetails,
  section,
  usersMap,
  viewType,
}: {
  result: RoomStateRubricResult & { user: PublicUser }
  roomStates: RoomState[]
  rubric: SlideRubric
  rubricResultDetails: FirestoreRoomStateRubricResultDetail[]
  section: Section
  usersMap: Map<string, PublicUser>
  viewType: 'student' | 'group'
}) {
  const { t } = useTranslation()
  const roomState = roomStates.find((rs) => rs.id === result.data.roomId)
  if (!roomState) {
    throw new Error('Room state not found')
  }
  const groupName = roomStateName({
    roomState,
    section,
    usersMap,
  })

  const isGroupLeader = roomState.groupLeaderUserIds.includes(result.user.id)
  const groupLeader = roomStateGroupLeader({
    roomState,
    usersMap,
    section,
  })

  const [isOpen, setIsOpen] = useState(false)

  const filteredRubricResultDetails = useFilteredRubricResultDetails(
    rubricResultDetails,
    rubric.id,
    result.user.id
  )

  return (
    <div className="flex flex-row border-b border-medium-grey bg-core-tertiary px-5 py-2.5">
      {viewType === 'student' && (
        <div className="w-[200px] min-w-[200px]">
          <ResultUser
            result={result}
            isGroupLeader={result.user.id === groupLeader?.id}
          />
        </div>
      )}
      <div className="w-[175px] min-w-[175px]">
        <div className="flex flex-col">
          <BreakoutTooltip
            content={t('instructor_assignment.group_leader_name', {
              name: groupName,
            })}
          >
            <strong className="text-label-medium mb-1">{groupName}</strong>
          </BreakoutTooltip>
          {/* why does stack break out of its own container ?? */}
          <BreakoutUserAvatarStack
            radius={16}
            users={roomStateUsers(roomState, usersMap)}
            className="!justify-start"
          />
        </div>
      </div>
      <div className="flex w-[130px] min-w-[130px] flex-row items-center">
        <RubricScoreWidget
          rubric={rubric}
          rubricResult={result}
          showTitle={true}
        />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-grow flex-row gap-6">
          <button
            title={t('instructor_assignment.toggle_evaluation')}
            onClick={() => setIsOpen(!isOpen)}
          >
            <ChevronRight
              size={16}
              className={classNames({
                'rotate-90': !isOpen,
                '-rotate-90': isOpen,
              })}
            />
          </button>
          {/* Justifications */}
          <div className="flex flex-col justify-center">
            {viewType === 'group' && (
              <ResultUser result={result} isGroupLeader={isGroupLeader} />
            )}
            <ResultJustification
              justification={result.justificationForRubricType(
                rubric.data.rubricType,
                t
              )}
              resultArguments={result.data.arguments}
            />
          </div>
        </div>
        {isOpen && filteredRubricResultDetails.length > 0 && (
          <div className="ml-10 mt-2.5">
            <JustificationDetails
              rubricResultDetails={filteredRubricResultDetails}
              roomId={result.data.roomId}
              rubric={rubric}
            />
          </div>
        )}
      </div>
    </div>
  )
})

const ResultUser = observer(function ResultUser({
  result,
  isGroupLeader,
}: {
  result: RoomStateRubricResult & { user: PublicUser }
  isGroupLeader: boolean
}) {
  return (
    <div className="flex flex-row items-center">
      <BreakoutUserAvatar radius={14} user={result.user} />
      <strong className="text-label-medium ml-2">{result.user.fullName}</strong>
      {isGroupLeader && (
        <Shield size={16} className="ml-1 stroke-breakout-gold" />
      )}
    </div>
  )
})

const ResultJustification = observer(function ResultJustification({
  justification,
  resultArguments,
}: {
  justification: string
  resultArguments: string[]
}) {
  const hasJustification = justification.length > 0
  const firstArgument = resultArguments.length ? resultArguments[0] : ''
  return (
    <p className="text-body-medium line-clamp-20">
      {hasJustification ? justification : firstArgument}
    </p>
  )
})

function getFilteredRooms({
  roomStates,
  usersMap,
  searchStrings,
  viewType,
}: {
  roomStates: RoomState[]
  usersMap: Map<string, PublicUser>
  searchStrings: string[]
  viewType: 'student' | 'group'
}) {
  return roomStates.filter((roomState) => {
    if (viewType === 'student') return true
    if (searchStrings.length === 0) return true

    return searchStrings.some(
      (searchString) =>
        roomState.roomStateName
          ?.toLowerCase()
          .includes(searchString.toLowerCase()) ||
        roomStateUsers(roomState, usersMap).some((user) =>
          user.fullName.toLowerCase().includes(searchString.toLowerCase())
        )
    )
  })
}

function getRoomStateRubricResults({
  results,
}: {
  results: Array<RoomStateRubricResult & { user: PublicUser }>
}) {
  const roomStateRubricResults: Record<string, number> = {}
  for (const result of results) {
    const currentResult = roomStateRubricResults[result.data.roomId]
    if (!currentResult) {
      roomStateRubricResults[result.data.roomId] = result.data.score
    } else {
      if (result.data.score > currentResult) {
        roomStateRubricResults[result.data.roomId] = result.data.score
      }
    }
  }
  return roomStateRubricResults
}

function getFilteredResults({
  results,
  filteredRooms,
  roomStateRubricResults,
  searchStrings,
  viewType,
  qualityFilter,
}: {
  results: Array<RoomStateRubricResult & { user: PublicUser }>
  filteredRooms: RoomState[]
  roomStateRubricResults: Record<string, number>
  searchStrings: string[]
  viewType: 'student' | 'group'
  qualityFilter: number
}) {
  const final = results
    .filter((result) => {
      if (viewType === 'group') {
        return filteredRooms.some(
          (roomState) => roomState.id === result.data.roomId
        )
      }
      return true
    })
    .filter((result) => {
      if (qualityFilter === 4) return true
      if (qualityFilter === 3) return result.data.score >= 5
      if (qualityFilter === 2)
        return result.data.score >= 3 && result.data.score < 5
      if (qualityFilter === 1)
        return result.data.score >= 1 && result.data.score < 3
      return result.data.score === 0
    })
    .filter((result) => {
      if (viewType === 'group') return true
      return searchStrings.every((searchString) =>
        result.user.fullName.toLowerCase().includes(searchString.toLowerCase())
      )
    })
    .filter((result) => {
      if (viewType === 'student') return true
      return roomStateRubricResults[result.data.roomId] === result.data.score
    })
    .sort((a, b) => {
      if (a.data.score === b.data.score) {
        if (viewType === 'group' && a.data.roomId !== b.data.roomId) {
          return a.data.roomId.localeCompare(b.data.roomId)
        }
        if (a.user.data.lastName === b.user.data.lastName) {
          if (a.user.data.firstName && b.user.data.firstName) {
            return a.user.data.firstName.localeCompare(b.user.data.firstName)
          }
        }
        if (a.user.data.lastName && b.user.data.lastName) {
          return a.user.data.lastName.localeCompare(b.user.data.lastName)
        }
      }
      return b.data.score - a.data.score
    })
  return final
}
